import {appStore} from '../../stores/AppStore';
import {Navigate, useParams} from 'react-router-dom';
import {observer} from 'mobx-react';
import styles from './MainPage.module.scss';
import {Wallets} from './components/Wallets';
import ConnectWalletButton from '../../components/Navigation/Header/components/ConnectWalletButton/ConnectWalletButton';
import {BrowserBg, CoinIcon, MouseIcon, RocketIcon} from '../../assets';
import {Box} from '@mui/material';
import useBreakpoints from '../../hooks/useBreakpoints';
import useHeaderSettings from '../../hooks/useHeaderSettings';
import {useEffect} from 'react';

const MainPage = () => {
  const {inviteID} = useParams();
  useEffect(() => {
    if (inviteID) {
      localStorage.setItem('inviteID', inviteID);
    }
  }, [inviteID]);

  useHeaderSettings({title: 'Welcome'});
  const {isMobile} = useBreakpoints();

  if (appStore?.authorized === undefined) {
    return null;
  }
  if (appStore?.authorized === true) {
    return <Navigate to="/make-a-call"/>;
  }

  return (
    <Box className={styles.container}>
      <div className={styles.cardsContainer}>
        <div className={styles.cardWrapper}>
          <div className={styles.card}>
            <div className={styles.cardStep}>Step 1</div>
            <div className={styles.cardHeader}>{'Install' + (isMobile ? ' ' : '\n') + 'a Crypto Wallet'}</div>
            <div className={styles.cardText}>{'We recommend using \nthe Metamask wallet'}</div>

            <div className={styles.cardContent}>
              <Wallets/>
            </div>
          </div>
        </div>

        <div className={styles.cardWrapper}>
          <div className={styles.card}>
            <div className={styles.cardStep}>Step 2</div>
            <div className={styles.cardHeader}>{'Click' + (isMobile ? ' ' : '\n') + 'the Connect Wallet'}</div>
            <div className={styles.cardText}>{'Log in to the app using\nyour crypto wallet'}</div>


            <div className={styles.cardContent}>
              <Box
                mr={2}
                ml={-3}
              >
                <BrowserBg/>
              </Box>
            </div>
            <Box
              position={'absolute'}
              bottom={88}
              left={63}
            >
              <ConnectWalletButton/>
            </Box>
            <Box
              position={'absolute'}
              bottom={23}
              right={78}
            >
              <MouseIcon/>
            </Box>
          </div>
        </div>

        <div className={styles.cardWrapper}>
          <div className={styles.card}>
            <div className={styles.cardStep}>Step 3</div>
            <div className={styles.cardHeader}>{'Get' + (isMobile ? ' ' : '\n') + 'a Welcome Bonus'}</div>
            <div className={styles.cardText}>{'Hurry up to use the bonus\nwithin 8 hours'}</div>

            <div className={styles.cardContent}>
              <Box
                mb={3}
                className={styles.block}
              >
                <div className={styles.blockIcon}>
                  <CoinIcon/>
                </div>
                <div className={styles.blockContent}>
                  <div className={styles.blockTitle}>
                    0.1$
                  </div>
                  <div className={styles.blockText}>
                    for calls
                  </div>
                </div>
              </Box>
              <div className={styles.block}>
                <div className={styles.blockIcon}>
                  <RocketIcon/>
                </div>
                <div className={styles.blockContent}>
                  <div className={styles.blockTitle}>
                    Premium
                  </div>
                  <div className={styles.blockText}>
                    subscription
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.greyText}>
            <div>{'* the bonus is only available for wallets\nwith a balance greater than 0.1 ETH'}</div>
          </div>
        </div>

      </div>


    </Box>
  );
};

export default observer(MainPage);