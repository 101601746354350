import {Box} from '@mui/material';
import styles from './ESIMGuide.module.scss';
import InfoBadge from '../../../../components/Misc/InfoBadge/InfoBadge';
import Accordeon from '../../../../components/Misc/Accordeon/Accordeon';
import {AndroidIcon, AppleIcon, EsimIcon, HuaweiIcon} from '../../../../assets';
import BoxWithHeader from '../../../../components/Misc/BoxWithHeader/BoxWithHeader';
import PointsList from '../../../../components/Misc/PointsList/PointsList';
import YellowBadge from '../../../../components/Misc/YellowBadge/YellowBadge';
import useBreakpoints from '../../../../hooks/useBreakpoints';

const ESIMGuide = () => {
  const {isMobile} = useBreakpoints();

  const qrPoints = [
    'To install an eSIM using a QR code, display it on another device or print it out.',
    <>
      Next, follow these steps:<Box mb={1}/>
      <span>On iPhone:</span> «Settings» → «Cellular» → «Add Cellular Plan» → scan the QR
      code → follow the prompts on your device.
      <Box mb={1}/>
      <span>On Android:</span> «Settings» → «Network and Internet» → «Mobile network» → «Add» → «Download SIM card» →
      scan the QR code → follow the prompts on your device.
      <Box mb={1}/>
      <span>On Huawei:</span> «Settings» → «Mobile network» → «SIM card management» → «Add eSIM card» → «+Add eSIM card»
      → scan the
      QR code → follow the prompts on your device.
    </>,
    'Wait for the eSIM to register on the network. This usually takes less than a minute.',
    'Turn on the «Data Roaming» mode on this SIM card.'
  ];

  const iphonePoints = [
    <>
      Copy these values from eSIM profile:<Box mb={1}/>
      <span>«eSIM» → «My eSIM» → select purchased eSIM and click on Actions (...) → «Profile eSIM»</span>
    </>,
    <>
      Next, follow these steps:<Box mb={1}/>
      <span>«Settings» → «Network and Internet» → «Mobile network» → «Add» → «Download SIM card» → «Need help» → «Enter manually» → paste «Activation Code» into the appropriate field.</span>
    </>,
    'Wait for the eSIM to register on the network. This usually takes less than a minute.',
    <>
      Turn on the «Data Roaming» mode on this SIM card.<Box mb={1}/>
      <span>«Settings» → «Network / Internet» → «Mobile Network» → turn on Roaming.</span>
    </>
  ];

  const androidPoints = [
    <>
      Copy these values from eSIM profile:<Box mb={1}/>
      <span>«eSIM» → «My eSIM» → select purchased eSIM and click on Actions (...) → «Profile eSIM»</span>
    </>,
    <>
      Next, follow these steps:<Box mb={1}/>
      <span>«Settings» → «Network and Internet» → «Mobile network» → «Add» → «Download SIM card» → «Need help» → «Enter manually» → paste «Activation Code» into the appropriate field.</span>
    </>,
    'Wait for the eSIM to register on the network. This usually takes less than a minute.',
    <>
      Turn on the «Data Roaming» mode on this SIM card.<Box mb={1}/>
      <span>«Settings» → «Network / Internet» → «Mobile Network» → turn on Roaming.</span>
    </>
  ];

  const huaweiPoints = [
    <>
      Copy these values from eSIM profile:<Box mb={1}/>
      <span>«eSIM» → «My eSIM» → select purchased eSIM and click on Actions (...) → «Profile eSIM»</span>
    </>,
    <>
      Next, follow these steps:<Box mb={1}/>
      <span>«Settings» → «Mobile network» → «SIM card management» → «Add eSIM card» → «+Add eSIM card» → «Enter manually» →  paste «SM-DP+ Address» and «Activation Code» into the appropriate fields.</span>
    </>,
    'Wait for the eSIM to register on the network. This usually takes less than a minute.',
    <>
      Turn on the «Data Roaming» mode on this SIM card.<Box mb={1}/>
      <span>«Settings» → «Mobile Network» → «Mobile Data» → turn on Data roaming.</span>
    </>
  ];

  return <div className={styles.container}>
    <Box mb={2} className={styles.title}>How to install an eSIM</Box>

    <InfoBadge
      width={'fit-content'}
      text={<>
        <span className={styles.blueText}>Important Note.</span>&nbsp;An eSIM profile can only be downloaded
        once.
      </>}
    />

    <Box mt={2} className={styles.greyText}>
      Please do not remove the eSIM from your device settings until you are done using it. Once a deleted eSIM profile
      cannot be restored.
    </Box>

    <Box my={isMobile ? 3 : 5} className={styles.separator}/>

    <Accordeon
      title={
        <>
          <div className={styles.boxHeader}>How to install an eSIM using{isMobile ? <br/> : ' '}a<EsimIcon
            style={{paddingBottom: isMobile ? 4 : 6}}/><span>QR code</span>?
          </div>
        </>
      }
      disabled={!isMobile}
      defaultOpen
    >
      <InfoBadge
        width={'fit-content'}
        text={
          <>
          <span
            className={styles.blueText}>Internet is required to install eSIM.</span>&nbsp;Connect to Wi-Fi or use the
            mobile Internet of the main SIM card.
          </>
        }
      />


      <Box mt={'19px'}>
        <BoxWithHeader headerBackground={'dark'}
                       headerText={<span>Please follow the activation instructions below:</span>}>
          <PointsList points={qrPoints}/>
        </BoxWithHeader>
      </Box>
    </Accordeon>

    <Box my={isMobile ? 3 : 5} className={styles.separator}/>

    <Accordeon
      title={
        <>
          <div className={styles.boxHeader}>How to install eSIM{isMobile ?
            <br/> : ' '}on<AppleIcon style={{paddingBottom: isMobile ? 6 : 9}}/><span>iPhone</span> manually?
          </div>
        </>
      }
      disabled={!isMobile}
      defaultOpen
    >
      <InfoBadge
        width={'fit-content'}
        text={
          <>
          <span
            className={styles.blueText}>Internet is required to install eSIM.</span>&nbsp;Connect to Wi-Fi or use the
            mobile Internet of the main SIM card.
          </>
        }
      />

      <Box mt={'19px'} mb={'11px'} className={styles.smallTitle}>
        To manually install eSIM on iPhone,{isMobile ? <br/> : ' '}
        you need to know:
      </Box>

      <Box mb={'19px'} display={'flex'} gap={2}>
        <YellowBadge>SM-DP+ Address</YellowBadge>
        <YellowBadge>Activation Code</YellowBadge>
      </Box>

      <Box mt={'19px'}>
        <BoxWithHeader
          headerBackground={'dark'}
          headerText={<span>Please follow the activation instructions below:</span>}
        >
          <PointsList points={iphonePoints}/>
        </BoxWithHeader>
      </Box>
    </Accordeon>


    <Box my={isMobile ? 3 : 5} className={styles.separator}/>

    <Accordeon
      title={
        <>
          <div className={styles.boxHeader}>How to install eSIM{isMobile ?
            <br/> : ' '}on<AndroidIcon style={{paddingBottom: isMobile ? 4 : 5.5}}/><span>Android</span> manually?
          </div>
        </>
      }
      disabled={!isMobile}
      defaultOpen
    >
      <InfoBadge
        width={'fit-content'}
        text={
          <>
          <span
            className={styles.blueText}>Internet is required to install eSIM.</span>&nbsp;Connect to Wi-Fi or use the
            mobile Internet of the main SIM card.
          </>
        }
      />

      <Box mt={'19px'} mb={'11px'} className={styles.smallTitle}>
        To manually install eSIM on Android,{isMobile ? <br/> : ' '}
        you need to know:
      </Box>

      <Box mb={'19px'} display={'flex'} gap={2}>
        <YellowBadge>Activation Code</YellowBadge>
      </Box>

      <Box mt={'19px'}>
        <BoxWithHeader
          headerBackground={'dark'}
          headerText={<span>Please follow the activation instructions below:</span>}
        >
          <PointsList points={androidPoints}/>
        </BoxWithHeader>
      </Box>
    </Accordeon>

    <Box my={isMobile ? 3 : 5} className={styles.separator}/>

    <Accordeon
      title={
        <>
          <div className={styles.boxHeader}>How to install eSIM{isMobile ?
            <br/> : ' '}on<HuaweiIcon style={{paddingBottom: isMobile ? 4 : 6}}/><span>Huawei</span> manually?
          </div>
        </>
      }
      disabled={!isMobile}
      defaultOpen
    >
      <InfoBadge
        width={'fit-content'}
        text={
          <>
          <span
            className={styles.blueText}>Internet is required to install eSIM.</span>&nbsp;Connect to Wi-Fi or use the
            mobile Internet of the main SIM card.
          </>
        }
      />

      <Box mt={'19px'} mb={'11px'} className={styles.smallTitle}>
        To manually install eSIM on Huawei,{isMobile ? <br/> : ' '}
        you need to know:
      </Box>

      <Box mb={'19px'} display={'flex'} gap={2}>
        <YellowBadge>SM-DP+ Address</YellowBadge>
        <YellowBadge>Activation Code</YellowBadge>
      </Box>

      <Box mt={'19px'}>
        <BoxWithHeader
          headerBackground={'dark'}
          headerText={<span>Please follow the activation instructions below:</span>}
        >
          <PointsList points={huaweiPoints}/>
        </BoxWithHeader>
      </Box>
    </Accordeon>
  </div>
}

export default ESIMGuide