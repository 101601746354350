import {Box} from '@mui/material';
import styles from './DIDCitySelectList.module.scss';
import {getFlagEmoji} from '../../../../../../utils/getFlagEmoji';
import {ReactComponent as VoiceIcon} from '../../icons/voiceIconActive.svg';
import {ReactComponent as SMSIcon} from '../../icons/smsIconActive.svg';
import {HeaderBackIcon} from '../../../../../../assets';
import Pagination from '../../../../../../components/Pagination/Pagination';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {ReactComponent as SearchIcon} from '../../../../../ESIM/Tabs/ESIMBuy/icons/search.svg';
import {Input} from '../../../../../../components/Input/Input';
import useRequest from '../../../../../../hooks/useRequest';
import useBreakpoints from '../../../../../../hooks/useBreakpoints';
import {DIDListCard} from '../DIDListCard/DIDListCard';

const DIDCitySelectList = ({setSelectedCity, type, isPremium, selectedCountry, selectedRegion}) => {
  const {isMobile} = useBreakpoints();
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState('');
  const [cities, setCities] = useState();
  const {postDataWithCallback} = useRequest();

  const loadStates = useCallback(() => {
    const body = {
      country: selectedCountry.id,
      sms: type === 'voicesms',
    }

    if (selectedRegion) {
      body.name = '';
      body.region = selectedRegion.id;
    }
    postDataWithCallback('/api/did/cities', body, response => {
      setCities(response);
    })
  }, [selectedCountry.id, type, selectedRegion, postDataWithCallback]);

  useEffect(() => {
    loadStates();
  }, [loadStates]);

  const citiesToRender = useMemo(() => {
    if (!cities) return [];
    if (!value) return cities;

    return [...cities].filter(city => (city.attributes.area_name + city.attributes.prefix).toLowerCase().includes(value.toLowerCase()))
  }, [cities, value]);

  const paginatedCities = citiesToRender.slice(page * perPage - perPage, page * perPage);

  if (cities === undefined) return null;
  if (cities === null) {
    return <div className={styles.emptyText}>
      There is nothing here yet
    </div>
  }

  return <div className={styles.container}>
    <div className={styles.searchContainer}>
      <div className={styles.searchIcon}><SearchIcon/></div>
      <Input
        value={value}
        onChange={(val) => {
          setPage(1);
          setValue(val);
          // countriesRef.current.scrollTop = 0;
        }}
        placeholder={'Search by city name'}
      />
    </div>

    <Box mt={2}>
      {!isMobile && (
        <>
          <Box className={styles.tableHeader} display={'flex'} justifyContent={'space-between'}>
            <Box width={'250px'}>City</Box>
            <Box width={'150px'}>Type number</Box>
            <Box width={'120px'}>Setup fee</Box>
            <Box width={'120px'}>Monthly</Box>
            <Box width={'24px'}></Box>
          </Box>

          {paginatedCities.map(city => (
            <Box key={city.id} className={styles.tableRow} display={'flex'} justifyContent={'space-between'}>
              <Box width={'250px'} className={styles.tableRowCountryName}>
                {getFlagEmoji(selectedCountry.attributes.iso)} {city.attributes.area_name} (+{selectedCountry.attributes.prefix}{city.attributes.prefix})
              </Box>

              <Box width={'150px'} className={styles.tableRowType}>
                {
                  type === 'voice' ? <><VoiceIcon/>Voice</> :
                    <><VoiceIcon/>Voice +&nbsp;<SMSIcon/>SMS</>
                }
              </Box>

              <Box width={'120px'}>${isPremium ? parseFloat(city.setupVipPrice) : parseFloat(city.setupPrice)}</Box>

              <Box
                width={'120px'}>${isPremium ? parseFloat(city.monthlyVipPrice) : parseFloat(city.monthlyPrice)}&nbsp;
                <span>/ mo</span>
              </Box>

              <Box width={'24px'} height={'24px'}>
                <HeaderBackIcon className={styles.icon} onClick={() => setSelectedCity(city)}/>
              </Box>
            </Box>
          ))}
        </>
      )}


      {isMobile && paginatedCities.map(city =>
        <DIDListCard
          title={<>{getFlagEmoji(selectedCountry.attributes.iso)} {city.attributes.area_name} (+{selectedCountry.attributes.prefix}{city.attributes.prefix})</>}
          type={type}
          onSelect={() => setSelectedCity(city)}
          setupFee={isPremium ? parseFloat(city.setupVipPrice) : parseFloat(city.setupPrice)}
          rentFee={isPremium ? parseFloat(city.monthlyVipPrice) : parseFloat(city.monthlyPrice)}
        />
      )}

      <Box mt={3}>
        <Pagination
          page={page}
          perPage={perPage}
          onPageChange={setPage}
          onPerPageChange={setPerPage}
          itemsCount={citiesToRender.length}
        />
      </Box>
    </Box>
  </div>
}

export default DIDCitySelectList