import {CloseIcon} from '../../../../assets';
import commonStyles from '../../ModalsRoot.module.scss';
import {Button} from '../../../Buttons';
import {useEffect, useRef, useState} from 'react';
import InputWithFrame from '../../../InputWithFrame/InputWithFrame';
import {Box} from '@mui/material';
import useRequest from '../../../../hooks/useRequest';
import {loadCurrentUser} from '../../../../lib/auth';
import {getTokenValue} from '../../../../utils/LocalStorage';
import {appStore} from '../../../../stores/AppStore';
import {observer} from 'mobx-react';
import styles from './CliChange.module.scss';
import animationData from './lf20_tfrjammd.json';
import Lottie from 'lottie-react';
import randomImg from './random.png';
import EventEmitter from '../../../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from '../../const';

const CliChange = ({onClose}) => {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [looping, setLooping] = useState(false);
  const fetching = useRef(false);
  const lottieRef = useRef();
  const {postDataWithCallback} = useRequest();

  const isPremium = appStore.currentUser?.vipType !== 'no';

  const valueOnChange = (value) => {
    if (value[0] !== '+') {
      value = '+' + (value || '');
    }
    setValue(value?.replace(/^0+/, '').replace(/[^+\d]/g, '') || '');
  };

  const setRandom = () => {
    postDataWithCallback('/api/user/cli', {number: 'random'}, async () => {
      await loadCurrentUser(getTokenValue());
      setValue('');
    });
  };

  useEffect(() => {
    if (looping && loading) {
      lottieRef.current.play();
    }
  }, [loading, looping]);

  const onChangeClick = async () => {
    if (!isPremium) {
      EventEmitter.emit(MODAL_EVENTS.openModal, MODAL_TYPES.cliBasic);
      return
    }

    if (loading || !value || value.length < 7) return;
    setLoading(true);
    setLooping(true);
    fetching.current = true;

    postDataWithCallback(
      '/api/user/cli',
      {number: value},
      async () => {
        fetching.current = false;
        await loadCurrentUser(getTokenValue());
      },
      () => {
        setLoading(false);
        setLooping(false);
        fetching.current = false;
        lottieRef.current.stop();
      }
    );
  };

  return (
    <div className={commonStyles.defaultModal}>
      <div
        onClick={onClose}
        className={commonStyles.closeButtonContainer}
      >
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <h4 className={commonStyles.heading}>Caller ID</h4>

      <div className={styles.callerIdDescription}>
        {
          'Caller ID is the phone number that will be\ndisplayed to your interlocutor.'
        }
      </div>

      <Box textAlign="center">
        <span className={styles.cliHeading}>
          Current:&nbsp;
          <span className={styles.cliNumber}>
            {appStore.currentUser?.cli ? appStore.currentUser?.cli : 'random'}
          </span>
        </span>
      </Box>

      <Box
        onClick={!isPremium ? onChangeClick : undefined}
        my={2}
      >
        <InputWithFrame
          value={value}
          onChange={valueOnChange}
          placeholder={'Enter a new number'}
          onFocus={() => valueOnChange('')}
          type="tel"
          pattern="[0-9]*"
          noValidate
          disabled={!isPremium}
        />
      </Box>

      <Button
        onClick={onChangeClick}
        fakeDisabled={!value || value.length < 7}
        style={{width: '100%', height: '59px'}}
      >
        <Lottie
          lottieRef={lottieRef}
          animationData={animationData}
          onLoopComplete={() => {
            if (fetching.current === false) {
              setLooping(false);
            }
          }}
          onEnterFrame={(frame) => {
            if (frame.currentTime > 238) {
              lottieRef.current.stop();
              setTimeout(() => {
                setLoading(false);
                setValue('');
              }, 100);
            }
          }}
          initialSegment={looping ? [0, 90] : [0, 240]}
          autoplay={loading}
          style={{
            width: loading ? 32 : 0,
            height: 32,
            marginRight: '4px',
            transition: '200ms width linear',
          }}
        />
        Change
      </Button>
      <span
        onClick={setRandom}
        className={styles.random}
      >
        <img
          src={randomImg}
          alt={'dice'}
        />
        Random
      </span>
    </div>
  );
};

export default observer(CliChange);
