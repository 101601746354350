import styles from './Wallets.module.scss';
import {Box} from '@mui/material';
import {
  BitpayIcon,
  CoinbaseIcon,
  CryptoComIcon,
  HuobiIcon,
  LedgerIcon,
  MetamaskIcon,
  OhIcon,
  OneInchIcon,
  RainbowIcon,
  TrustWalletIcon,
  TTMIcon,
  UnstoppableIcon
} from '../../../assets';
import classNames from 'classnames';

export const Wallets = () => {
  return (
    <div className={styles.container}>

      <Box ml={2} className={styles.row}>
        <div className={classNames(styles.wallet)}>
          <MetamaskIcon/>
        </div>
        <div className={classNames(styles.wallet, styles.walletDark)}>
          <CoinbaseIcon/>
        </div>
        <div className={classNames(styles.wallet, styles.walletDarker)}>
          <TrustWalletIcon/>
        </div>
      </Box>

      <Box ml={-3} className={styles.row}>
        <div className={classNames(styles.wallet, styles.walletDarker)}>
          <CryptoComIcon/>
        </div>
        <div className={classNames(styles.wallet)}>
          <LedgerIcon/>
        </div>
        <div className={classNames(styles.wallet, styles.walletDark)}>
          <OneInchIcon/>
        </div>
      </Box>

      <Box ml={4} className={styles.row}>
        <div className={classNames(styles.wallet, styles.walletDarker)}>
          <HuobiIcon/>
        </div>
        <div className={classNames(styles.wallet)}>
          <BitpayIcon/>
        </div>
        <div className={classNames(styles.wallet, styles.walletDarker)}>
          <UnstoppableIcon/>
        </div>
      </Box>

      <Box ml={2} className={styles.row}>
        <div className={classNames(styles.wallet)}>
          <RainbowIcon/>
        </div>
        <div className={classNames(styles.wallet, styles.walletDarker)}>
          <TTMIcon/>
        </div>
        <div className={classNames(styles.wallet, styles.walletDark)}>
          <OhIcon/>
        </div>
      </Box>
    </div>
  )
}