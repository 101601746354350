import styles from './DIDSMSViewModal.module.scss';
import {Box, Modal} from '@mui/material';
import commonStyles from 'components/Modals/ModalsRoot.module.scss';
import {CloseIcon, SMSIconYellow} from 'assets';
import {
  DIDSMSCardNumbers
} from 'pages/DID/Tabs/DIDSMSLogs/components/DIDSMSCard/components/DIDSMSCardNumbers/DIDSMSCardNumbers';

const DIDSMSViewModal = ({sms, onClose}) => {
  return <Modal disableAutoFocus={true} onClose={onClose} open={true}>
    <div className={styles.modalContainer}>
      <div className={styles.modalTitle}>
        <SMSIconYellow/>SMS
      </div>

      <Box mt={1} mb={1.5}>
        <DIDSMSCardNumbers sms={sms}/>
      </Box>

      <div onClick={onClose} className={commonStyles.closeButtonContainer}>
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <div className={styles.text}>
        {sms.text}
      </div>
    </div>
  </Modal>
}

export default DIDSMSViewModal