import {useEffect, useMemo, useState} from 'react';
import PhoneCall from './PhoneCall';
import {TabNavigation} from 'components/TabNavigation/TabNavigation';
import styles from './PhoneCallNavigation.module.scss';
import CallHistory from '../CallHistory/CallHistory';
import useHeaderSettings from '../../hooks/useHeaderSettings';
import {appStore} from 'stores/AppStore';
import {Navigate, useSearchParams} from 'react-router-dom';

export const PhoneCallNavigation = () => {
  useHeaderSettings({title: 'Make a call'});
  const [tabIndex, setTabIndex] = useState(0);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const params = JSON.parse(searchParams.get('state'));
    if (params && params.state?.number && params.state?.Key) {
      setTabIndex(0);
    }
    // eslint-disable-next-line
  }, [searchParams]);

  const tabs = useMemo(
    () => [
      {
        name: 'Dialpad',
        component: <PhoneCall/>,
      },
      {
        name: 'Call history',
        component: <CallHistory/>,
      },
    ],
    []
  );

  if (appStore?.authorized === false) {
    return <Navigate to="/"/>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.tabContainer}>
        <TabNavigation
          tabs={tabs}
          setTabIndex={setTabIndex}
          tabIndex={tabIndex}
        />
      </div>

      {tabs[tabIndex].component}
    </div>
  );
};
