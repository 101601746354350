import {Box} from '@mui/material';
import styles from './DIDNumbersTableRow.module.scss';
import DIDNumbersRowDescription from './components/DIDNumbersRowDescription/DIDNumbersRowDescription';
import moment from 'moment/moment';
import classNames from 'classnames';
import MenuButton
  from '../../../../../../../components/Navigation/Header/components/TopBarInfo/components/MenuButton/MenuButton';
import {useCallback, useMemo, useState} from 'react';
import {
  AutoRenewActiveIcon,
  AutoRenewIcon,
  DeleteIcon,
  DescriptionIcon,
  RenewIcon,
  SetAsCalledIdIcon,
  SMSActiveIcon,
  SMSIcon,
  VoiceActiveIcon,
  VoiceIcon
} from './icons';
import DIDDeleteModal from '../../DIDDeleteModal/DIDDeleteModal';
import useRequest from '../../../../../../../hooks/useRequest';
import {getFlagEmoji} from '../../../../../../../utils/getFlagEmoji';
import EventEmitter from '../../../../../../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from '../../../../../../../components/Modals/const';
import useBreakpoints from '../../../../../../../hooks/useBreakpoints';
import DIDRenewModal from '../../DIDRenewModal/DIDRenewModal';
import {loadCurrentUser} from '../../../../../../../lib/auth';
import {getTokenValue} from '../../../../../../../utils/LocalStorage';

const DIDNumbersTableRow = ({number, fetchNumbers, isPremium}) => {
  const {isMobile} = useBreakpoints();
  const {postDataWithCallback} = useRequest();
  const [descriptionEditOpen, setDescriptionEditOpen] = useState(false);
  const [renewOpen, setRenewOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const numberActive = useMemo(() => (
    new Date().getTime() < (number.until * 1000)
  ), [number.until])

  const enableVoice = useCallback(() => {
    postDataWithCallback('/api/did/edit/voice', {'number': number.number, 'voice': !number.enabledVoice}, () => {
      void fetchNumbers();
    })
  }, [fetchNumbers, number.enabledVoice, number.number, postDataWithCallback]);

  const enableSMS = useCallback(() => {
    postDataWithCallback('/api/did/edit/sms', {'number': number.number, 'sms': !number.enabledSms}, () => {
      void fetchNumbers();
    })
  }, [fetchNumbers, number.enabledSms, number.number, postDataWithCallback]);

  const enableAutoRenew = useCallback(() => {
    postDataWithCallback('/api/did/edit/renew', {'number': number.number, 'renew': !number.renew}, () => {
      void fetchNumbers();
    })
  }, [fetchNumbers, number.number, number.renew, postDataWithCallback]);

  const onDelete = useCallback(() => {
    postDataWithCallback('/api/did/delete', {'number': number.number}, () => {
      EventEmitter.emit(MODAL_EVENTS.openModal, MODAL_TYPES.defaultNotification,
        {title: 'Notification', text: 'Your DID has been' + (isMobile ? '\n' : ' ') + 'permanently deleted.'});
      setDeleteOpen(false);
      void fetchNumbers();
    })
  }, [fetchNumbers, isMobile, number.number, postDataWithCallback]);

  const onCliChanged = useCallback(() => {
    postDataWithCallback(
      '/api/did/cli',
      {number: number.number},
      async () => {
        EventEmitter.emit(MODAL_EVENTS.openModal, MODAL_TYPES.defaultNotification,
          {title: 'Notification', text: <>{`Your current Caller ID has been changed.`}</>});
          void loadCurrentUser(getTokenValue());
      },
      (e) => {
        console.error(e);
      }
    );
  }, [number.number, postDataWithCallback])

  const menuItems = useMemo(() => {
      const items = [
        {
          title: number.enabledVoice ? 'Disable Voice' : 'Enable Voice',
          onClick: () => enableVoice(),
          icon: !number.enabledVoice ? <VoiceActiveIcon/> : <VoiceIcon/>,
        },
      ]

      if (number.type === 'voicesms') {
        items.push({
          title: number.enabledSms ? 'Disable SMS' : 'Enable SMS',
          onClick: () => enableSMS(),
          icon: !number.enabledSms ? <SMSActiveIcon/> : <SMSIcon/>,
        })
      }

      items.push({
          title: number.renew ? 'Disable renew' : 'Enable renew',
          onClick: () => enableAutoRenew(),
          icon: !number.renew ? <AutoRenewActiveIcon/> : <AutoRenewIcon/>,
        },
        ...(numberActive ? [{
          title: 'Set as Caller ID',
          onClick: onCliChanged,
          icon: <SetAsCalledIdIcon/>
        }] : []),
        {
          title: 'Set Description',
          onClick: () => setDescriptionEditOpen(true),
          icon: <DescriptionIcon/>
        },
        {
          title: 'Renew DID',
          onClick: () => setRenewOpen(true),
          icon: <RenewIcon/>,
        },
        {
          title: 'Delete DID',
          onClick: () => setDeleteOpen(true),
          icon: <DeleteIcon/>,
        },
      );
      return items;
    }, [enableAutoRenew, enableSMS, enableVoice, number.enabledSms, number.enabledVoice, number.renew, number.type, numberActive, onCliChanged]
  );

  const rentFee = (isPremium ? parseFloat(number.monthlyVipPrice) : parseFloat(number.monthlyPrice));

  return (
    <Box
      className={styles.tableRow}
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
    >
      <Box width={250}>
        {getFlagEmoji(number.countryISO)}&nbsp;{number.country}
        {number.region && ', ' + number.region}
        {number.city && ', ' + number.city}
      </Box>

      <Box width={150}>+{number.number}</Box>

      <Box width={150}>
        <DIDNumbersRowDescription
          open={descriptionEditOpen}
          setOpen={setDescriptionEditOpen}
          description={number.alias}
          number={number}
          fetchNumbers={fetchNumbers}
        />
      </Box>

      <Box width={100}>
        <div>
          {moment(number.until * 1000).format('DD.MM.YYYY')}
          <br/>
          <span className={classNames(styles.greyText, styles.smallText)}>
            {moment(number.until * 1000).format('(HH:mm:ss)')}
          </span>
        </div>
      </Box>

      <Box
        boxSizing={'border-box'}
        py={0.75}
        px={1.5}
        width={75}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <div className={classNames(styles.dot, number.enabledVoice ? styles.dotActive : '')}/>
        {number.type === 'voicesms' &&
          <div className={classNames(styles.dot, number.enabledSms ? styles.dotActive : '')}/>
        }
      </Box>

      <Box width={80}>
        <Box
          margin={'0 auto'}
          className={classNames(styles.dot, number.renew ? styles.dotActive : '')}
        />
      </Box>

      <Box width={100}>
        ${rentFee}&nbsp;<span className={styles.greyText}>/ mo</span>
      </Box>

      <Box width={70}>
        <MenuButton items={menuItems}/>
      </Box>

      {deleteOpen && <DIDDeleteModal
        number={number}
        onClose={() => setDeleteOpen(false)}
        onDelete={onDelete}
      />}

      {renewOpen && <DIDRenewModal
        number={number}
        onClose={() => setRenewOpen(false)}
        fetchNumbers={fetchNumbers}
      />}
    </Box>
  )
}

export default DIDNumbersTableRow;