import {TabNavigation} from '../../components/TabNavigation/TabNavigation';
import useHeaderSettings from '../../hooks/useHeaderSettings';
import {useEffect, useMemo, useState} from 'react';
import {appStore} from '../../stores/AppStore';
import {Navigate, useNavigate} from 'react-router-dom';
import ESIMDescription from './Tabs/ESIMDescription/ESIMDescription';
import styles from './ESIMNavigation.module.scss';
import ESIMBuy from './Tabs/ESIMBuy/ESIMBuy';
import ESIMMyNumbers from './Tabs/ESIMMyNumbers/ESIMMyNumbers';
import {useQuery} from '../../utils/useQuery';
import ESIMGuide from './Tabs/ESIMGuide/ESIMGuide';
import {observer} from 'mobx-react';

const ESIMNavigation = () => {
  useHeaderSettings({title: 'eSIM'});
  const [tabIndex, setTabIndex] = useState(0);
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (query.get('tab')) {
      setTabIndex(parseInt(query.get('tab')))
      navigate('/esim', {replace: true});
    }
  }, [navigate, query]);

  const tabs = useMemo(
    () => [
      {
        name: 'Description',
        component: <ESIMDescription setTabIndex={setTabIndex}/>,
      },
      {
        name: 'Buy eSIM',
        component: <ESIMBuy onCheckout={() => setTabIndex(2)}/>,
      },
      {
        name: 'My eSIM',
        component: <ESIMMyNumbers/>,
      },
      {
        name: 'Guide',
        component: <ESIMGuide/>,
      },
    ],
    []
  );

  if (appStore?.authorized === false) {
    return <Navigate to="/"/>;
  }


  return (
    <div className={styles.container}>
      <TabNavigation tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={tabs}/>

      {tabs[tabIndex].component}
    </div>
  )
}

export default observer(ESIMNavigation)