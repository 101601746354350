import {ArrowRightBlueIcon, CloseIcon, DiscordIcon} from '../../../../assets';
import commonStyles from '../../ModalsRoot.module.scss';
import {Box} from '@mui/material';
import styles from './BuyNFT.module.scss';
import classNames from 'classnames';
import useBreakpoints from '../../../../hooks/useBreakpoints';

const BuyNFT = ({onClose}) => {
  const {isMobile} = useBreakpoints();
  const text = isMobile ? 'A limited edition collection of 10,000\nunique NFTs designed for the special\nmembers, with lifetime access\nto Premium subscription.' :
    'A limited edition collection\n' +
    'of 10,000 unique NFTs designed \n' +
    'for the special members, with lifetime\naccess to Premium subscription.';

  const smalltext = isMobile ? 'To sign up for the NFT purchase waiting list, connect\nto our discord server and follow the news:' : 'To sign up for the NFT purchase waiting list, connect to\nour discord server and follow the news:';

  return (
    <div
      style={{paddingLeft: 24, paddingRight: 24}}
      className={commonStyles.defaultModal}
    >
      <div
        onClick={onClose}
        className={commonStyles.closeButtonContainer}
      >
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <h4 className={classNames(commonStyles.heading, styles.headingYellow)}>
        NFT PASS
      </h4>

      <Box
        mt={1}
        mb={isMobile ? 2 : 3}
      >
        <span className={styles.text}>
          {text}
        </span>
      </Box>

      <Box
        mb={2}
      >
        <span className={styles.smallGreyText}>
         {smalltext}
        </span>
      </Box>

      <a
        href={'https://discord.gg/aDwvYD9Pzw'}
        className={styles.button}
        target={'_blank'}
        rel="noopener noreferrer"
      >
        <Box className={styles.discord}>
          <DiscordIcon/>
        </Box>
        Discord
        <Box className={styles.arrow}>
          <ArrowRightBlueIcon/>
        </Box>
      </a>
    </div>
  );
};

export default BuyNFT;
