import {observer} from 'mobx-react';
import styles from './TopBarInfo.module.scss';
import {CopyIcon, TickIcon, WalletIcon} from '../../../../../assets';
import {appStore} from '../../../../../stores/AppStore';
import MenuButton from './components/MenuButton/MenuButton';
import useBreakpoints from '../../../../../hooks/useBreakpoints';
import {CopyToClipboard} from 'react-copy-to-clipboard/lib/Component';
import classNames from 'classnames';
import {useRef, useState} from 'react';
import {Box, Fade, Popper} from '@mui/material';

const TopBarInfo = () => {
  const {isMobile} = useBreakpoints();
  const [active, setActive] = useState(false);
  const ref = useRef();
  const [open, setOpen] = useState(false);

  const usdBalance = ((appStore.currentUser.centsBalance || 0) / 100).toFixed(
    2
  );
  let shortWallet = appStore.currentUser.shortWallet;

  if (isMobile) {
    shortWallet = shortWallet.split('');
    shortWallet.splice(3, 3);
    shortWallet = shortWallet.join('');
  }
  const onCopy = () => {
    setActive(true);
    setOpen(true);
    setTimeout(() => {
      setActive(false);
      setOpen(false);
    }, 1500);
  };

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <div className={styles.balance}>$ {usdBalance}</div>
        <CopyToClipboard onCopy={onCopy} text={appStore.currentUser?.wallet}>
          <div className={styles.wallet}>
            {!isMobile && <WalletIcon className={styles.icon}/>}

            <span>{shortWallet}</span>

            <CopyIcon
              ref={ref}
              className={classNames(styles.copyIcon, active && styles.active)}
            />
          </div>
        </CopyToClipboard>
      </div>

      <MenuButton/>

      <Popper
        open={open}
        anchorEl={ref.current}
        placement={'bottom-end'}
        transition
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box className={styles.copiedContainer}>
              <TickIcon/>
              &nbsp;<span className={styles.copied}>Copied!</span>
            </Box>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default observer(TopBarInfo);
