import styles from './Earn.module.scss';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Dashboard from './Tabs/Dashboard/Dashboard';
import History from './Tabs/History/History';
import {appStore} from '../../stores/AppStore';
import {Navigate} from 'react-router-dom';
import useHeaderSettings from '../../hooks/useHeaderSettings';
import useRequest from '../../hooks/useRequest';
import Rules from './Tabs/Rules/Rules';
import InviteFriendsPage from './Tabs/InviteFriendsPage/InviteFriendsPage';
import useBreakpoints from '../../hooks/useBreakpoints';
import {loadCurrentUser} from '../../lib/auth';
import {getTokenValue} from '../../utils/LocalStorage';
import {TabNavigation} from '../../components/TabNavigation/TabNavigation';
import {observer} from 'mobx-react';

const Earn = () => {
  const {isMobile} = useBreakpoints();
  useHeaderSettings({title: 'Earn', showBackButton: true});
  const [data, setData] = useState();
  const {getDataWithCallback} = useRequest();
  const [tabIndex, setTabIndex] = useState(0);
  const intervalRef = useRef();

  const loadData = useCallback(() => {
    getDataWithCallback('/api/user/earn', undefined, (data) => {
      setData(data);
    });
    void loadCurrentUser(getTokenValue());
  }, [getDataWithCallback]);

  useEffect(() => {
    loadData();
    intervalRef.current = setInterval(() => void loadData(), 10000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [loadData]);

  const tabs = useMemo(
    () => [
      {
        name: 'Dashboard',
        component: <Dashboard data={data}/>,
      },
      {
        name: isMobile ? 'History' : 'History of Rewards',
        component: <History history={data?.history}/>,
        notification: appStore.currentUser?.unreadEarn
      },
      {
        name: isMobile ? 'Rules' : 'Detailed Rules',
        component: <Rules/>,
      },
      {
        name: isMobile ? 'Link' : 'Get the Link',
        component: <InviteFriendsPage/>,
      },
    ],
    [data, isMobile]
  );

  if (appStore?.authorized === false) {
    return <Navigate to="/"/>;
  }

  return (
    <div className={styles.container}>
      <TabNavigation
        tabs={tabs}
        setTabIndex={setTabIndex}
        tabIndex={tabIndex}
      />

      <div>{tabs[tabIndex].component}</div>
    </div>
  );
};

export default observer(Earn);
