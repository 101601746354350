import styles from './BasicPlan.module.scss';
import classNames from 'classnames';
import {BasicPlanIcon} from '../../../../assets';

const BasicPlan = ({active, onClick}) => {
  return (
    <div
      className={classNames(styles.container, active && styles.containerActive)}
      onClick={onClick || undefined}
    >
      <div className={styles.header}>
        <div className={styles.headerIcon}>
          <BasicPlanIcon/>
        </div>

        <div className={styles.headerContent}>
          <div className={styles.headerContentRow}>
            <div className={styles.priceContainerContent}>
              <span className={styles.headerTitle}>
                {active ? 'Current plan:' : 'Plan:'}
              </span>
              <span className={styles.headerPlan}>Basic</span>
            </div>

            <div className={styles.priceContainerContent}>
              <span className={styles.priceContainerContentTitle}>Price:</span>
              <span className={styles.priceContainerContentText}>$0/mo</span>
            </div>

            {active && (
              <div className={styles.priceContainerContent}>
            <span className={styles.priceContainerContentTitle}>
              Valid until:
            </span>
                <span className={styles.priceContainerContentText}>∞</span>
              </div>
            )}
          </div>

          <div className={styles.headerContentRow}>
            <div className={classNames(styles.footer, active && styles.footerActive)}>
              <div>
                Billing:<strong>per second</strong>
              </div>
              <div className={styles.divider}>|</div>
              <div>
                Discount on calls:<strong>0%</strong>
              </div>
              <div className={styles.divider}>|</div>
              <div>
                Discount on rent DID:<strong>0%</strong>
              </div>

              <div>
                Changing the outgoing number:<strong>No</strong>
              </div>
              <div className={styles.divider}>|</div>
              <div>
                Changing the voice:<strong>No</strong>
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>
  );
};

export default BasicPlan;
