import styles from './TopUpBalanceStepOne.module.scss';
import {Box, ClickAwayListener, Fade, Popper} from '@mui/material';
import InputWithFrame from '../../components/InputWithFrame/InputWithFrame';
import classNames from 'classnames';
import {Button} from '../../components/Buttons';
import {useState} from 'react';
import EventEmitter from '../../utils/events/EventEmitter';
import {currencies} from './currencies';
import useBreakpoints from '../../hooks/useBreakpoints';

export const TopUpBalanceStepOne = (
  {
    value,
    onStepTwo,
    selectedCurrency,
    setSelectedCurrency,
    valueOnChange,
    disabled
  }
) => {
  const {isMobile} = useBreakpoints();

  const [el, setEl] = useState();
  const [width, setWidth] = useState(250);
  const [open, setOpen] = useState(false);

  const onPayClick = async (event) => {
    if (disabled) {
      setEl(event.target);
      setOpen(true);
      return;
    }

    onStepTwo();
    EventEmitter.emit('start_user_polling');
  };

  const defaultOptions = [
    {title: '$50', value: '50'},
    {title: '$100', value: '100'},
    {title: '$250', value: '250'},
    {title: '$500', value: '500'},
    {title: '$1000', value: '1000'},
  ];


  return (
    <>
      <div className={styles.container}>
        <div className={styles.inputBox}>
          <h4 className={styles.heading}>
            Type or select the amount
            <br/>
            you’d like to top-up balance:
          </h4>

          <Box position={'relative'}>
            <InputWithFrame
              value={value}
              onChange={valueOnChange}
              prefix="$"
              placeholder="$0"
              type="tel"
              pattern="[0-9]*"
              noValidate
            />

            {isMobile && (
              <Box position={'absolute'} top={0} right={24} height={'100%'} alignItems={'center'} display={'flex'}>
                <span className={styles.greyText}>Min. $50</span>
              </Box>
            )}
          </Box>

          <div className={styles.optionsContainer}>
            {defaultOptions.map((option, index) => {
              return (
                <div
                  key={index}
                  className={classNames(
                    styles.option,
                    value === option.value ? styles.optionActive : ''
                  )}
                  onClick={() => {
                    valueOnChange(option.value);
                  }}
                >
                  {option.title}
                </div>
              );
            })}
          </div>

          {!isMobile && (
            <span className={styles.greyText}>Min. $50</span>
          )}
        </div>

        <div className={styles.currencyContainer}>
          <h4 className={styles.heading}>
            Select the payment method:
          </h4>

          <div className={styles.currencyGrid}>
            {(isMobile ? currencies.slice(0, currencies.length) : currencies).map((currency, currencyIndex) => {
              return (
                <div
                  key={currencyIndex}
                  onClick={() => setSelectedCurrency(currency.code)}
                  className={classNames(styles.currencyButton, selectedCurrency === currency.code ? styles.currencyButtonActive : '')}>
                  {currency.icon}
                  <span className={styles.currencyButtonText}>
                                        {currency.name}
                                    </span>
                </div>
              )
            })}

          </div>
        </div>

        <Button
          onClick={onPayClick}
          fakeDisabled={disabled}
          style={{width: '100%', maxWidth: '200px', cursor: 'pointer', marginBottom: '66px'}}
          colorScheme={disabled ? 'dark' : undefined}
          onMouseEnter={(event) => {
            if (disabled) {
              setEl(event.target);
              setWidth(event.target.offsetWidth);
              setOpen(true);
            }
          }}
        >
          Pay
        </Button>

        <Popper
          style={{zIndex: 9999}}
          open={open}
          anchorEl={el}
          placement={'bottom-end'}
          transition
        >
          {({TransitionProps}) => (
            <Fade {...TransitionProps} timeout={350}>
              <div
                className={styles.notEnoughFundsContainer}
                onMouseLeave={() => {
                  setOpen(false);
                }}
                style={{width}}
              >
                <ClickAwayListener
                  onClickAway={() => (open ? setOpen(false) : undefined)}
                >
                  <span>Minimum balance top-up amount from $50</span>
                </ClickAwayListener>
              </div>
            </Fade>
          )}
        </Popper>
      </div>
    </>
  )
}