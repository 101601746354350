import {makeAutoObservable} from 'mobx';
import {getTokenValue, removeTokenValue, removePubKey} from '../utils/LocalStorage';
import EventEmitter from '../utils/events/EventEmitter';
import {websockets} from 'stores/Websocket';

export const silentSound = 'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';

class AppStore {
  authorized = undefined;
  loading = undefined;
  currentUser = undefined;
  token = undefined;
  soundEffect = undefined;

  constructor() {
    makeAutoObservable(this);
    this.soundEffect = new Audio();
    this.soundEffect.autoplay = true;
    this.soundEffect.loop = true;
    this.resetSoundEffect();
  }

  resetSoundEffect = () => {
    this.soundEffect.src = silentSound;
  }

  reset = () => {
    this.authorized = undefined;
    this.loading = undefined;
    this.currentUser = undefined;
    this.token = undefined;
  };

  disconnect = () => {
    appStore.reset();
    localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
    try {
      localStorage.removeItem('walletconnect');
    } catch (error) {
      console.log(error)
    }
    removeTokenValue();
    removePubKey();
    window.location.pathname="/"
    window.location.reload();
  };

  setAuthorized = (authorized) => {
    if (authorized === false) {
      removeTokenValue();
    }

    if (authorized === true && appStore.currentUser.needWS) {
      websockets.onAppStateChange(authorized, getTokenValue());
    }

    this.authorized = authorized;
  };

  setToken = (token) => {
    this.token = token;
  };

  setCurrentUser = (currentUser) => {
    if (
      !this.currentUser ||
      this.currentUser.centsBalance !== currentUser.centsBalance
    ) {
      EventEmitter.emit('stop_user_polling');
    }
    this.currentUser = currentUser;
  };

  subtractFromBalance = (amount) => {
    this.currentUser = {
      ...this.currentUser,
      centsBalance: this.currentUser.centsBalance - amount,
    };
  };
}

export const appStore = new AppStore();
