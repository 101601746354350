import styles from './DIDNumbersTable.module.scss';
import {Box} from '@mui/material';
import Pagination from '../../../../../../components/Pagination/Pagination';
import {useState} from 'react';
import DIDNumbersTableRow from './DIDNumbersTableRow/DIDNumbersTableRow';

const DIDNumbersTable = ({numbers, fetchNumbers, isPremium}) => {
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const items = numbers?.slice(page * perPage - perPage, page * perPage);

  return <div className={styles.container}>
    <Box className={styles.tableHeader} display={'flex'} justifyContent={'space-between'}>
      <Box width={250}>Country / City</Box>
      <Box width={150}>DID number</Box>
      <Box width={150}>Description</Box>
      <Box width={100}>Expire at</Box>
      <Box width={75}>Voice / SMS</Box>
      <Box width={80}>Auto-renew</Box>
      <Box width={100}>Cost</Box>
      <Box width={70}>Actions</Box>
    </Box>

    <Box display={'flex'} flexDirection={'column'}>
      {items.map((item) => <DIDNumbersTableRow
        key={item.orderID}
        number={item}
        fetchNumbers={fetchNumbers}
        isPremium={isPremium}
      />)}
    </Box>

    <Pagination
      page={page}
      perPage={perPage}
      onPageChange={setPage}
      onPerPageChange={setPerPage}
      itemsCount={numbers.length}
    />
  </div>
}

export default DIDNumbersTable