import styles from './History.module.scss';
import {useEffect, useMemo, useState} from 'react';
import {Navigate} from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import Pagination from '../../../../components/Pagination/Pagination';
import {appStore} from '../../../../stores/AppStore';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import {ArrowRightButton} from '../../../../assets';
import {openUrl} from '../../../../utils/url';

// const history = [
//   {
//     type: "add_first",
//     date: new Date().getTime() / 1000,
//     walletFrom: "0xce3a...5c76",
//     walletOut: "0xce3a3a36a3c9c204be77cd9a324aaf1958fa5c76",
//     cents: 5000,
//   },
//   {
//     type: "add_second",
//     date: new Date().getTime() / 1000,
//     walletFrom: "0xce3a...5c76",
//     walletOut: "0xce3a3a36a3c9c204be77cd9a324aaf1958fa5c76",
//     cents: 5000,
//   },
//   {
//     type: "withdraw_balance",
//     date: new Date().getTime() / 1000,
//     walletFrom: "0xce3a...5c76",
//     walletOut: "0xce3a3a36a3c9c204be77cd9a324aaf1958fa5c76",
//     cents: 5000,
//   },
//   {
//     type: "withdraw_wallet_btc",
//     date: new Date().getTime() / 1000,
//     walletFrom: "0xce3a...5c76",
//     walletOut: "0xce3a3a36a3c9c204be77cd9a324aaf1958fa5c76",
//     cents: 5000,
//   },
//   {
//     type: "withdraw_wallet_usdc",
//     date: new Date().getTime() / 1000,
//     walletFrom: "0xce3a...5c76",
//     walletOut: "0xce3a3a36a3c9c204be77cd9a324aaf1958fa5c76",
//     cents: 5050,
//   },
//   {
//     type: "earn",
//     date: new Date().getTime() / 1000,
//     walletFrom: "0xce3a...5c76",
//     walletOut: "0xce3a3a36a3c9c204be77cd9a324aaf1958fa5c76",
//     cents: 5050,
//   },
// ];
const History = ({history}) => {
  const {isMobile} = useBreakpoints();
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const transactionsToRender = useMemo(() => {
    return history.slice(page * perPage - perPage, page * perPage);
  }, [page, perPage, history]);

  const getTypeText = (transaction) => {
    switch (transaction.type) {
      case 'add_second':
      case 'add_first':
        return (
          <span className={styles.text}>
            Top-up balance <span>({transaction.walletFrom})</span>
          </span>
        );
      case 'withdraw_balance':
        return <span className={styles.text}>Withdraw to Balance</span>;
      case 'withdraw_wallet_btc':
        return (
          <span className={styles.text}>
            Withdraw to BTC <span>({transaction.walletFrom})</span>
          </span>
        );
      case 'withdraw_wallet_usdc':
        return (
          <span className={styles.text}>
            Withdraw to USDC <span>({transaction.walletFrom})</span>
          </span>
        );
      case 'earn':
        return <span className={styles.text}>Top-up (from Earn)</span>;
      default:
        return transaction.type;
    }
  };

  useEffect(() => {
    window.scrollTo({behavior: 'smooth', top: 0});
  }, [page, perPage]);

  if (appStore?.authorized === false) {
    return <Navigate to="/"/>;
  }

  if (history.length === 0) {
    return (
      <div className={styles.container}>
        <span className={classNames(styles.smallText, styles.center)}>
          There is nothing here yet
        </span>
      </div>
    );
  }

  const renderRow = (transaction) => {
    if (isMobile) {
      return (
        <>
          <td>
            <div>
              {getTypeText(transaction)}
              <span className={styles.smallText}>
                {moment(transaction.date * 1000).format('DD.MM.YYYY, HH:mm')}
              </span>
            </div>
          </td>
          <td>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div style={{display: 'flex', flexDirection: 'column'}}>
                {transaction.type.startsWith('add') ||
                transaction.type.startsWith('earn') ? (
                  <>
                    <span
                      className={classNames(
                        styles.amount,
                        styles.amountPositive
                      )}
                    >
                      +${parseFloat(transaction.cents / 100)}
                    </span>
                    <span className={classNames(styles.text, styles.nsbpGrey)}>
                      Level:&nbsp;
                      {transaction.type.includes('first') ? (
                        <span className={styles.text}>
                          1<sup>st</sup>
                        </span>
                      ) : (
                        <span className={styles.text}>
                          2<sup>nd</sup>
                        </span>
                      )}
                    </span>
                  </>
                ) : (
                  <span
                    className={classNames(styles.amount, styles.amountNegative)}
                  >
                    -${parseFloat(transaction.cents / 100)}
                  </span>
                )}
              </div>
              {transaction.transaction_url && (
                <ArrowRightButton
                  style={{
                    cursor: 'pointer',
                    marginLeft: '12px',
                    height: 21,
                    width: 21,
                  }}
                  onClick={() => openUrl(transaction.transaction_url)}
                />
              )}
            </div>
          </td>
        </>
      );
    }

    return (
      <>
        <td>
          <div style={{alignItems: 'flex-start'}}>
            <span className={styles.text}>
              {moment(transaction.date * 1000).format('DD.MM.YYYY, HH:mm')}
            </span>
          </div>
        </td>
        <td>
          <div style={{alignItems: 'flex-start'}}>
            {transaction.type.startsWith('add') ? (
              <>
                <span
                  className={classNames(styles.amount, styles.amountPositive)}
                >
                  +${parseFloat(transaction.cents / 100)}
                </span>
              </>
            ) : (
              <span
                className={classNames(styles.amount, styles.amountNegative)}
              >
                -${parseFloat(transaction.cents / 100)}
              </span>
            )}
          </div>
        </td>
        <td>
          <div style={{alignItems: 'flex-start'}}>
            {getTypeText(transaction)}
          </div>
        </td>
        <td>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {transaction.type.startsWith('add') && (
              <>
                <span className={classNames(styles.text, styles.nsbpGrey)}>
                  {transaction.type.includes('first') ? (
                    <span className={styles.text}>
                      1<sup>st</sup>
                    </span>
                  ) : (
                    <span className={styles.text}>
                      2<sup>nd</sup>
                    </span>
                  )}
                </span>
              </>
            )}
            {transaction.transaction_url && (
              <ArrowRightButton
                style={{
                  cursor: 'pointer',
                  marginLeft: 'auto',
                  paddingLeft: '16px',
                }}
                onClick={() => openUrl(transaction.transaction_url)}
              />
            )}
          </div>
        </td>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
        <tr>
          {isMobile ? (
            <>
              <th>Date/time</th>
              <th>Amount</th>
            </>
          ) : (
            <>
              <th>Date/time</th>
              <th>Amount</th>
              <th>Description</th>
              <th>Level</th>
            </>
          )}
        </tr>
        </thead>

        <tbody>
        {transactionsToRender.map((transaction) => {
          return <tr key={transaction.startAt}>{renderRow(transaction)}</tr>;
        })}
        </tbody>
      </table>

      <Pagination
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
        itemsCount={history.length}
      />
    </div>
  );
};

export default History;
