import {useEffect} from 'react'
import {uiStore} from '../stores/UIStore'

const useHeaderSettings = ({title, showBackButton}) => {
  useEffect(() => {
    uiStore.setTitle(title)
    uiStore.setShowBackButton(showBackButton)

    return () => {
      uiStore.setTitle(undefined)
      uiStore.setShowBackButton(undefined)
    }
  }, [showBackButton, title])
}
export default useHeaderSettings