import {WalletIcon} from '../../../../../assets';
import {Button} from '../../../../Buttons';
import styles from './ConnectWalletButton.module.scss';
import {useWeb3Modal} from '@web3modal/react';

const ConnectWalletButton = () => {
  const { open } = useWeb3Modal()

  return <Button onClick={open} fontSize={'small'} size={'xs'}>
    <WalletIcon className={styles.icon}/>
    Connect Wallet
  </Button>
}

export default ConnectWalletButton
