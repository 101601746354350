import {makeAutoObservable} from 'mobx'

class UIStore {
  title = undefined
  showBackButton = undefined

  constructor() {
    makeAutoObservable(this)
  }

  setShowBackButton(val) {
    this.showBackButton = val
  }

  setTitle(val) {
    this.title = val
  }
}

export const uiStore = new UIStore()
