import styles from './MenuItems.module.scss';
import {
  ChangePlanIcon,
  DIDIcon,
  DisconnectIcon,
  EarnIcon,
  EsimIcon,
  HelpDeskIcon,
  MakeACallIcon,
  TopUpBalanceIcon,
  TransactionsIcon,
} from '../../../../../../../assets';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import {appStore} from '../../../../../../../stores/AppStore';
import {ClickAwayListener} from '@mui/material';
import EventEmitter from '../../../../../../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from '../../../../../../Modals/const';

const MenuItems = ({onClose, items, width}) => {
  const openHelpDesk = () => {
    EventEmitter.emit(
      MODAL_EVENTS.openModal,
      MODAL_TYPES.helpDesk
    );
  }

  const menuItems = items || [
    {
      title: 'Top-up balance',
      path: '/top-up-balance',
      icon: <TopUpBalanceIcon/>,
    },
    {title: 'Change plan', path: '/change-plan', icon: <ChangePlanIcon/>},
    {title: 'Make a call', path: '/make-a-call', icon: <MakeACallIcon/>},
    {title: 'Virtual numbers', path: '/did', icon: <DIDIcon/>, notification: appStore.currentUser?.unreadSms},
    {title: 'eSIM (Internet)', path: '/esim', icon: <EsimIcon/>},
    {title: 'Earn on invitees', path: '/earn', icon: <EarnIcon/>, notification: appStore.currentUser?.unreadEarn},
    {
      title: 'Transactions',
      path: '/transactions',
      icon: <TransactionsIcon/>,
    },
    {title: 'Help Desk', onClick: openHelpDesk, icon: <HelpDeskIcon/>},
    {title: 'Disconnect', path: '/disconnect', icon: <DisconnectIcon/>},
  ];

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div
        className={styles.menuContainer}
        style={{width}}
      >
        {menuItems.map((item) => {
          return (
            <Link
              key={item.title}
              onClick={item.onClick}
              // TODO: hack - change
              to={item.path || window.location.pathname}
              className={styles.menuItem}
              style={{minWidth: width ? 'initial' : 197}}
            >
              {item.title}
              {item.notification && (<div className={styles.redDot}/>)}
              <span className={styles.icon}>{item.icon}</span>
            </Link>
          );
        })}
      </div>
    </ClickAwayListener>
  );
};

export default observer(MenuItems);
