import styles from './DIDAddNumber.module.scss'
import {ReactComponent as KeypadIcon} from './icons/keypadIcon.svg';
import {ReactComponent as VoiceDisabledIcon} from './icons/voiceIconDisabled.svg';
import {ReactComponent as SMSDisabledIcon} from './icons/smsIconDisabled.svg';
import {ReactComponent as SMSIcon} from './icons/smsIconActive.svg';
import {ReactComponent as VoiceIcon} from './icons/voiceIconActive.svg';
import {ReactComponent as ArrowIcon} from './icons/arrowIcon.svg';
import {ReactComponent as EditIcon} from './icons/editIcon.svg';
import {Box} from '@mui/material';
import {useCallback, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import useRequest from '../../../../hooks/useRequest';
import {observer} from 'mobx-react';
import {appStore} from '../../../../stores/AppStore';
import DIDCountrySelectList from './components/DIDCountrySelectList/DIDCountrySelectList';
import {getFlagEmoji} from '../../../../utils/getFlagEmoji';
import DIDStateSelectList from './components/DIDStateSelectList/DIDStateSelectList';
import DIDCitySelectList from './components/DIDCitySelectList/DIDCitySelectList';
import DIDNumberSelectList from './components/DIDNumberSelectList/DIDNumberSelectList';
import DIDOrder from './components/DIDOrder/DIDOrder';
import useBreakpoints from '../../../../hooks/useBreakpoints';

const DIDAddNumber = () => {
  const {isMobile} = useBreakpoints();
  const {getDataWithCallback} = useRequest();
  const [type, setType] = useState('voice');
  const [countries, setCountries] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [selectedNumber, setSelectedNumber] = useState();

  const isPremium = appStore.currentUser?.vipType !== 'no';

  const loadCountries = useCallback(() => {
    getDataWithCallback('/api/did/countries/' + type, undefined, response => {
      setCountries(response.data);
    })
  }, [getDataWithCallback, type]);

  useEffect(() => {
    loadCountries();
  }, [getDataWithCallback, loadCountries]);

  const toggleType = useCallback(() => {
    setCountries(undefined);
    setType(type => type === 'voice' ? 'voicesms' : 'voice');
  }, []);

  const step = useMemo(() => {
    if (selectedNumber) {
      return 4;
    }

    if (selectedCity) {
      return 3;
    }

    if ((selectedCountry && !selectedCountry.attributes.coverage_has_regions) || selectedRegion) {
      return 2;
    }

    if (selectedCountry && selectedCountry.attributes.coverage_has_regions) {
      return 1;
    }

    if (!selectedCountry) {
      return 0;
    }
  }, [selectedCity, selectedCountry, selectedNumber, selectedRegion]);

  return <div className={styles.container}>
    {step !== 4 && (
      <>
        <Box display={'flex'} alignItems={'center'} justifyContent={isMobile ? 'center' : 'flex-start'}>
          <KeypadIcon/>
          <div className={styles.title}>Add number</div>
        </Box>

        {selectedCountry && (
          <Box
            gap={isMobile ? '6px' : '12px'}
            display={'flex'} mt={0.5}
            justifyContent={isMobile ? 'center' : 'initial'}
            flexWrap={'wrap'}
          >
            {!isMobile && <ArrowIcon/>}

            <Box
              className={styles.selectedBadge}
              onClick={() => {
                setSelectedCountry(undefined)
                setSelectedRegion(undefined)
                setSelectedCity(undefined)
                setSelectedNumber(undefined)
              }}
            >
              {getFlagEmoji(selectedCountry.attributes.iso)}&nbsp;{selectedCountry.attributes.name}<EditIcon/>
            </Box>

            {selectedRegion && (
              <Box
                className={styles.selectedBadge}
                onClick={() => {
                  setSelectedRegion(undefined)
                  setSelectedCity(undefined)
                  setSelectedNumber(undefined)
                }}
              >
                {getFlagEmoji(selectedCountry.attributes.iso)}&nbsp;{selectedRegion.attributes.name}<EditIcon/>
              </Box>
            )}

            {selectedCity && (
              <Box
                className={styles.selectedBadge}
                onClick={() => {
                  setSelectedCity(undefined)
                  setSelectedNumber(undefined)
                }}
              >
                {getFlagEmoji(selectedCountry.attributes.iso)}&nbsp;{selectedCity.attributes.area_name}<EditIcon/>
              </Box>
            )}
          </Box>
        )}
      </>
    )}

    {step === 0 && (
      <Box mt={isMobile ? 3 : '30px'} mb={3}>
        <div className={styles.subtitle}>Choose the type of virtual numbers:</div>
        <Box mt={1.5} display={'flex'} gap={'16px'} justifyContent={isMobile ? 'center' : 'flex-start'}>
          <div onClick={toggleType}
               className={classNames(styles.typeBox, type === 'voice' ? styles.typeBoxActive : '')}>
            {type === 'voice' ? <VoiceIcon/> : <VoiceDisabledIcon/>}
            <div>Voice</div>
          </div>

          <div onClick={toggleType}
               className={classNames(styles.typeBox, type === 'voicesms' ? styles.typeBoxActive : '')}>
            {type === 'voicesms' ? <VoiceIcon/> : <VoiceDisabledIcon/>}
            <div>Voice&nbsp;+&nbsp;</div>
            {type === 'voicesms' ? <SMSIcon/> : <SMSDisabledIcon/>}
            <div>SMS</div>
          </div>
        </Box>
      </Box>
    )}

    {step === 0 && (
      <>
        <Box mt={3} mb={1.5} className={styles.subtitle}>Select country:</Box>

        <DIDCountrySelectList
          type={type}
          isPremium={isPremium}
          countries={countries}
          setSelectedCountry={setSelectedCountry}
        />
      </>
    )}

    {step === 1 && (
      <>
        <Box mt={3} mb={1.5} className={styles.subtitle}>Select state:</Box>
        <DIDStateSelectList
          isPremium={isPremium}
          setSelectedRegion={setSelectedRegion}
          selectedCountry={selectedCountry}
          type={type}
        />
      </>
    )}

    {step === 2 && (
      <>
        <Box mt={3} mb={1.5} className={styles.subtitle}>Select city:</Box>
        <DIDCitySelectList
          isPremium={isPremium}
          setSelectedCity={setSelectedCity}
          selectedRegion={selectedRegion}
          selectedCountry={selectedCountry}
          type={type}
        />
      </>
    )}

    {step === 3 && (
      <>
        <DIDNumberSelectList
          isPremium={isPremium}
          setSelectedNumber={setSelectedNumber}
          selectedRegion={selectedRegion}
          selectedCountry={selectedCountry}
          selectedCity={selectedCity}
          type={type}
        />
      </>
    )}

    {step === 4 && (
      <DIDOrder
        number={selectedNumber}
        selectedCountry={selectedCountry}
        selectedCity={selectedCity}
        selectedRegion={selectedRegion}
        onBack={() => setSelectedNumber(undefined)}
        type={type}
        isPremium={isPremium}
      />
    )}
  </div>
}

export default observer(DIDAddNumber)