import styles from './CallHistory.module.scss';
import {useEffect, useMemo, useState} from 'react';
import useRequest from '../../hooks/useRequest';
import {appStore} from '../../stores/AppStore';
import {Navigate} from 'react-router-dom';
import {formatElapsed} from '../../utils/time';
import EncryptedNumber from './components/EncryptedNumber/EncryptedNumber';
import moment from 'moment';
import CallStatusBadge from './components/CallStatusBadge/CallStatusBadge';
import Pagination from '../../components/Pagination/Pagination';
import useBreakpoints from '../../hooks/useBreakpoints';
import {Box} from '@mui/material';
import {observer} from 'mobx-react';

const CallHistory = () => {
  const {getDataWithCallback} = useRequest();
  const [calls, setCalls] = useState();
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const {isMobile} = useBreakpoints();

  useEffect(() => {
    void getDataWithCallback('api/calls/phone', undefined, (data) => {
      setCalls(data || []);
    });
  }, [getDataWithCallback]);

  useEffect(() => {
    window.scrollTo({behavior: 'smooth', top: 0});
  }, [page, perPage]);

  const callsToRender = useMemo(() => {
    return calls?.slice(page * perPage - perPage, page * perPage);
  }, [page, perPage, calls]);

  if (calls && calls.length === 0) {
    return (
      <Box
        mt={2}
        display={'flex'}
        mx={'auto'}
        justifyContent={'center'}
      >
        <span className={styles.smallText}>There is nothing here yet</span>
      </Box>
    );
  }

  if (!calls) {
    return null;
  }

  if (appStore?.authorized === false) {
    return <Navigate to="/"/>;
  }

  const renderRow = (call) => {
    if (isMobile) {
      return (
        <>
          <td>
            <div>
              <div className={styles.badge}>
                <CallStatusBadge callStatus={call.status}/>
              </div>

              {call.encryptedNumber ? (
                <EncryptedNumber/>
              ) : (
                <span className={styles.text}>+{call.number}</span>
              )}
              <span className={styles.smallText}>
                {moment(call.startAt * 1000).format('DD.MM.YYYY, HH:mm')}
              </span>
            </div>
          </td>
          <td>
            <div>
              <span className={styles.text}>
                {formatElapsed(call.duration)}
              </span>
            </div>
          </td>
          <td>
            <div>
              <span className={styles.text}>
                ${((call.price / 60) * call.duration).toFixed(4)}
              </span>
              <span className={styles.smallText}>${call.price}</span>
            </div>
          </td>
        </>
      );
    }

    return (
      <>
        <td>
          <span
            className={styles.text}
            style={{whiteSpace: 'nowrap'}}
          >
            {moment(call.startAt * 1000).format('DD.MM.YYYY, HH:mm')}
          </span>
        </td>
        <td>
          {call.encryptedNumber ? (
            <EncryptedNumber/>
          ) : (
            <span className={styles.text}>+{call.number}</span>
          )}
        </td>
        <td>
          <div>
            <span className={styles.text}>{formatElapsed(call.duration)}</span>
          </div>
        </td>
        <td>
          <span className={styles.text}>
            ${((call.price / 60) * call.duration).toFixed(4)}
          </span>
        </td>
        <td>
          <span className={styles.text}>${call.price}</span>
        </td>
        <td>
          <div className={styles.badge}>
            <CallStatusBadge callStatus={call.status}/>
          </div>
        </td>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
        <tr>
          {isMobile ? (
            <>
              <th>Phone number</th>
              <th>Spent min.</th>
              <th>Cost</th>
            </>
          ) : (
            <>
              <th width={'150px'}>Date/time</th>
              <th>Phone number</th>
              <th>Spent min.</th>
              <th>Cost</th>
              <th>Cost min.</th>
              <th>Status</th>
            </>
          )}
        </tr>
        </thead>

        <tbody>
        {callsToRender.map((call) => {
          return <tr key={call.startAt}>{renderRow(call)}</tr>;
        })}
        </tbody>
      </table>

      <Pagination
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
        itemsCount={calls.length}
      />
    </div>
  );
};

export default observer(CallHistory);
