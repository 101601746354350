import {
  SOLIcon,
  BTCIcon,
  DOGEIcon,
  ETHIcon,
  LTCIcon,
  USDTIcon,
  TONIcon
} from '../../assets';

export const currencies = [
  {
    name: 'BTC',
    code: 'btc',
    icon: <BTCIcon/>,
  },
  {
    name: 'ETH',
    code: 'eth',
    icon: <ETHIcon/>,
  },
  {
    name: 'USDT',
    code: 'usdttrc20',
    icon: <USDTIcon/>,
  },
  {
    name: 'DOGE',
    code: 'doge',
    icon: <DOGEIcon/>,
  },
  {
    name: 'LTC',
    code: 'ltc',
    icon: <LTCIcon/>,
  },
  {
    name: 'TON',
    code: 'ton',
    icon: <TONIcon/>,
  },
  {
    name: 'SOL',
    code: 'sol',
    icon: <SOLIcon/>,
  },
]