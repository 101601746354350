import {useEffect, useState} from 'react';
import styles from '../assets/styles/_export.scss';

const breakpoints = {
  xs: styles.xs,
  sm: styles.sm,
  md: styles.md,
  lg: styles.lg,
  xl: styles.xl,
};

function useBreakpoints() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isXs = windowSize.width <= breakpoints.xs;
  const isSm =
    windowSize.width >= breakpoints.xs && windowSize.width < breakpoints.sm;

  return {
    isXs,
    isSm,
    isMd:
      windowSize.width >= breakpoints.sm && windowSize.width < breakpoints.md,
    isLg: windowSize.width >= breakpoints.md,
    isXl: windowSize.width >= breakpoints.lg,
    windowSize,
    isMobile: isSm || isXs,
  };
}

export default useBreakpoints;
