import {Box} from '@mui/material';
import styles from './DIDCountrySelectList.module.scss';
import {getFlagEmoji} from '../../../../../../utils/getFlagEmoji';
import {ReactComponent as VoiceIcon} from '../../icons/voiceIconActive.svg';
import {ReactComponent as SMSIcon} from '../../icons/smsIconActive.svg';
import {HeaderBackIcon} from '../../../../../../assets';
import Pagination from '../../../../../../components/Pagination/Pagination';
import {useEffect, useMemo, useState} from 'react';
import {ReactComponent as SearchIcon} from '../../../../../ESIM/Tabs/ESIMBuy/icons/search.svg';
import {Input} from '../../../../../../components/Input/Input';
import useBreakpoints from '../../../../../../hooks/useBreakpoints';
import {DIDListCard} from '../DIDListCard/DIDListCard';

const DIDCountrySelectList = ({countries, setSelectedCountry, type, isPremium}) => {
  const {isMobile} = useBreakpoints();
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState('');

  useEffect(() => {
    setPage(1);
  }, [type]);

  const countriesToRender = useMemo(() => {
    if (!countries) return [];
    if (!value) return countries;

    return [...countries]
      .filter(country =>
        (country.attributes.name + country.attributes.iso).toLowerCase().includes(value.toLowerCase())
      );
  }, [countries, value]);

  const paginatedCountries = countriesToRender.slice(page * perPage - perPage, page * perPage);

  if (countries === undefined) return null;
  if (countries === null) {
    return <div className={styles.emptyText}>
      There is nothing here yet
    </div>
  }

  return <div className={styles.container}>
    <div className={styles.searchContainer}>
      <div className={styles.searchIcon}><SearchIcon/></div>
      <Input
        value={value}
        onChange={(val) => {
          setPage(1);
          setValue(val);
          // countriesRef.current.scrollTop = 0;
        }}
        placeholder={'Search by country name or prefix'}
      />
    </div>

    <Box mt={2}>
      {!isMobile && (
        <>
          <Box
            className={styles.tableHeader}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Box width={'250px'}>Country</Box>
            <Box width={'150px'}>Type number</Box>
            <Box width={'120px'}>Setup fee</Box>
            <Box width={'120px'}>Monthly</Box>
            <Box width={'24px'}></Box>
          </Box>

          {paginatedCountries.map(country => (
            <Box
              key={country.id}
              className={styles.tableRow}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box
                width={'250px'}
                className={styles.tableRowCountryName}
              >
                {getFlagEmoji(country.attributes.iso)} {country.attributes.name} (+{country.attributes.prefix})
              </Box>

              <Box
                width={'150px'}
                className={styles.tableRowType}
              >
                {
                  type === 'voice' ? <><VoiceIcon/>Voice</> :
                    <><VoiceIcon/>Voice +&nbsp;<SMSIcon/>SMS</>
                }
              </Box>

              <Box
                width={'120px'}
              >${isPremium ? parseFloat(country.setupVipPrice) : parseFloat(country.setupPrice)}</Box>

              <Box
                width={'120px'}
              >${isPremium ? parseFloat(country.monthlyVipPrice) : parseFloat(country.monthlyPrice)}&nbsp;
                <span>/ mo</span>
              </Box>

              <Box
                width={'24px'}
                height={'24px'}
              >
                <HeaderBackIcon
                  className={styles.icon}
                  onClick={() => setSelectedCountry(country)}
                />
              </Box>
            </Box>
          ))}
        </>
      )}


      {isMobile && paginatedCountries.map(country =>
        <DIDListCard
          key={country.attributes.iso}
          title={<>{getFlagEmoji(country.attributes.iso)} {country.attributes.name} (+{country.attributes.prefix})</>}
          type={type}
          onSelect={() => setSelectedCountry(country)}
          setupFee={isPremium ? parseFloat(country.setupVipPrice) : parseFloat(country.setupPrice)}
          rentFee={isPremium ? parseFloat(country.monthlyVipPrice) : parseFloat(country.monthlyPrice)}
        />
      )}

      <Box mt={3}>
        <Pagination
          page={page}
          perPage={perPage}
          onPageChange={setPage}
          onPerPageChange={setPerPage}
          itemsCount={countriesToRender.length}
        />
      </Box>
    </Box>
  </div>
}

export default DIDCountrySelectList