import {makeAutoObservable} from 'mobx'

class Web3Store {
  infuraId = undefined
  network = undefined
  networkId = undefined
  web3 = undefined
  address = undefined

  constructor() {
    makeAutoObservable(this)
  }

  onApiInfo = ({infuraID, network, networkID}) => {
    this.infuraId = infuraID
    this.networkId = networkID
    this.network = network
  }

  setWeb3 = (web3) => {
    this.web3 = web3
  }

  setAddress = (address) => {
    this.address = address
  }

  get addressShort() {
    if (this.address) {
      return this.address.slice(this.address.length - 4)
    }

    return undefined
  }
}

export const web3Store = new Web3Store()
