import styles from './DIDSMSDeleteModal.module.scss';
import {Box, Modal} from '@mui/material';
import commonStyles from 'components/Modals/ModalsRoot.module.scss';
import {Button} from 'components/Buttons';
import {CloseIcon} from 'assets';

const DIDSMSDeleteModal = ({onDelete, onClose}) => {
  return <Modal disableAutoFocus={true} onClose={onClose} open={true}>
    <div className={styles.modalContainer}>
      <div className={styles.modalTitle}>{'Delete SMS log'}</div>
      <div onClick={onClose} className={commonStyles.closeButtonContainer}>
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <div className={styles.text}>
        {'Are you sure you want to delete \nthe SMS log?'}
      </div>


      <div className={styles.danger}>
        <span>Important!</span>&nbsp;Not recoverable.
      </div>

      <Box display={'flex'} gap={'16px'} maxWidth={344} mx={'auto'}>
        <Button style={{width: '100%'}} onClick={onClose} colorScheme={'dark'}>No</Button>
        <Button style={{width: '100%'}} onClick={onDelete}>Yes</Button>
      </Box>
    </div>
  </Modal>
}

export default DIDSMSDeleteModal