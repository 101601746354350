import styles from './Pagination.module.scss';
import {MenuItem, Select} from '@mui/material';
import {ArrowDown, NextIcon, PrevIcon} from '../../assets';
import useBreakpoints from '../../hooks/useBreakpoints';

const Pagination = ({
                      page,
                      perPage,
                      onPageChange,
                      onPerPageChange,
                      itemsCount,
                    }) => {
  const {isMobile} = useBreakpoints();
  const maxPage = Math.ceil(itemsCount / perPage);

  const options = [10, 20, 50];

  if (itemsCount < options[0]) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div>
        <span className={styles.text}>
          {page * perPage + 1 - perPage} -{' '}
          {Math.min(page * perPage, itemsCount)}
        </span>
        <span className={styles.textGrey}> of {itemsCount}</span>
      </div>
      <div className={styles.selectContainer}>
        <div className={styles.rowsPerPage}>Rows per page</div>
        <Select
          IconComponent={() => <ArrowDown/>}
          className={styles.select}
          value={perPage}
          onChange={(event) => {
            onPerPageChange(event.target.value);
            onPageChange(1);
          }}
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>{option}</MenuItem>
          ))}
        </Select>
        <PrevIcon
          onClick={
            page > 1 ? () => onPageChange((prev) => prev - 1) : undefined
          }
          style={{
            opacity: page === 1 ? 0.3 : 1,
            width: isMobile ? 'auto' : 36,
            height: isMobile ? 'auto' : 36,
          }}
          className={styles.prev}
        />
        <NextIcon
          onClick={
            maxPage <= page ? undefined : () => onPageChange((prev) => prev + 1)
          }
          style={{
            opacity: maxPage <= page ? 0.3 : 1,
            width: isMobile ? 'auto' : 36,
            height: isMobile ? 'auto' : 36,
          }}
        />
      </div>
    </div>
  );
};
export default Pagination;
