import styles from './ESIMBuy.module.scss';
import {ReactComponent as ESIMBuyIcon} from './icons/esim.svg';
import {Box, Typography} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import ESIMLocation from './components/ESIMLocation';
import useRequest from '../../../../hooks/useRequest';
import ESIMSelectPlans from './components/ESIMSelectPlans';
import {Button} from '../../../../components/Buttons';
import ESIMCountrySelect from './components/ESIMCountrySelect';
import {ESIMListOfCountries} from './components/ESIMListOfCountries';
import ESIMCheckout from './components/ESIMCheckout';
import useBreakpoints from '../../../../hooks/useBreakpoints';

export const esimLocations = [
  {
    name: <>🌐&nbsp;&nbsp;Worldwide</>,
    code: 'global',
  },
  {name: <>🇪🇺&nbsp;&nbsp;Europe</>, code: 'europe'},
  {name: <>🌏&nbsp;&nbsp;Asia</>, code: 'asia'},
  {name: <>One country</>, code: 'country'}
]

const ESIMBuy = ({onCheckout}) => {
  const [location, setLocation] = useState('global'); // TODO: global
  const {postDataWithCallback} = useRequest();
  const [plans, setPlans] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [isCheckout, setIsCheckout] = useState(false);
  const {isMobile} = useBreakpoints();

  const reset = useCallback(() => {
    setPlans();
    setSelectedPlan();
    setSelectedCountry();
  }, []);

  const onLocationSelect = useCallback((code) => {
    setLocation(code);
  }, []);

  useEffect(() => {
    reset();
    if (location !== 'country') {
      postDataWithCallback('/api/esim/region', {region: location}, data => {
        setPlans(data);
      });
    }
  }, [location, postDataWithCallback, reset]);

  const onCountrySelect = (country) => {
    reset();
    setSelectedCountry(country);
    postDataWithCallback('/api/esim/country', {code: country.countryCode}, data => {
      setPlans(data);
    });
  }

  if (isCheckout) {
    return <div className={styles.container}>
      <ESIMCheckout onCheckout={onCheckout} onBack={() => setIsCheckout(false)} plan={selectedPlan}
                    location={location} selectedCountry={selectedCountry}/>
    </div>
  }

  return (
    <div className={styles.container}>
      <Box mb={isMobile ? 2 : 3} display={'flex'} alignItems={'center'}
           justifyContent={isMobile ? 'center' : 'flex-start'}>
        <ESIMBuyIcon/>
        <Typography ml={1.5} variant={'h2'}>Buy eSIM</Typography>
      </Box>

      <div className={styles.locationContainer}>
        <div className={styles.locationContainerTitle}>{'Choose the location where you\nplan to use the eSIM:'}</div>

        <Box display={'grid'} gap={1} gridTemplateColumns={isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr'}>
          {esimLocations.map((loc) => (
            <ESIMLocation
              location={loc}
              key={loc.code}
              active={location === loc.code}
              onLocationSelect={onLocationSelect}
            />
          ))}
        </Box>
      </div>

      {location === 'country' && (
        <ESIMCountrySelect
          onCountrySelect={onCountrySelect}
          reset={reset}
          selectedCountry={selectedCountry}
        />
      )}

      <ESIMSelectPlans plans={plans} onSelect={setSelectedPlan} selectedPlan={selectedPlan}/>

      {isMobile && selectedPlan && (
        <ESIMListOfCountries plan={selectedPlan}/>
      )}

      <Box my={3} display={'flex'} gap={'40px'} alignItems={'center'}>
        <Button
          style={{
            width: 200,
            margin: isMobile ? '0 auto' : '0',
          }}
          onClick={() => setIsCheckout(true)}
          disabled={!selectedPlan}
        >
          Checkout
        </Button>

        {!isMobile && selectedPlan && (
          <ESIMListOfCountries plan={selectedPlan}/>
        )}
      </Box>
    </div>
  )
}

export default ESIMBuy