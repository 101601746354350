import React, {useCallback, useEffect, useRef} from 'react';
import {Box} from '@mui/material';

export const TextMarquee = ({child, centered}) => {
  const ref = useRef();
  const childRef = useRef();
  const timer = useRef();
  const position = useRef();

  const checkIfAnimationNeeded = useCallback(() => {
    if (ref.current && childRef.current) {
      const diff = childRef.current?.offsetWidth - ref.current.offsetWidth;
      return diff > 0;
    }
    return false;
  }, []);

  const move = useCallback((diff, reverse) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (!checkIfAnimationNeeded()) {
      return;
    }
    position.current = position.current + (reverse ? 1 : -1);
    childRef.current.style.marginLeft = position.current + 'px';
    timer.current = setTimeout(() => move(diff, reverse ? position.current < 10 : position.current < (-1 * diff - 10)), 1000 / 24);
  }, [checkIfAnimationNeeded]);

  const startAnimation = useCallback(() => {
    const diff = childRef.current?.offsetWidth - ref.current.offsetWidth;
    position.current = 0;
    timer.current = setTimeout(() => move(diff), 500);
  }, [move]);

  const endAnimation = useCallback(() => {
    clearTimeout(timer.current);
    if (centered) {
      childRef.current.style.margin = '0 auto'
    } else {
      childRef.current.style.marginLeft = '0'
    }
  }, [centered]);

  const initAnimation = useCallback(() => {
    const animationRequired = checkIfAnimationNeeded();
    if (animationRequired) {
      startAnimation();
      return;
    }

    endAnimation();
  }, [checkIfAnimationNeeded, endAnimation, startAnimation]);


  useEffect(() => {
    window.addEventListener('resize', initAnimation)
    initAnimation();
    return () => {
      window.removeEventListener('resize', initAnimation)
      clearTimeout(timer.current);
    }
  }, [initAnimation])

  return <Box
    display={'flex'}
    width={'100%'}
    overflow={'hidden'}
    ref={ref}
  >
    {React.cloneElement(child, {ref: childRef})}
  </Box>
}