import styles from './PointsList.module.scss';

const PointsList = ({points}) => {
  return <div>
    {points.map((point, index) => {
      return <div className={styles.pointContainer} key={index}>
        <div className={styles.point}><span>{index + 1}</span></div>
        <div className={styles.text}>{point}</div>
      </div>
    })}
  </div>
}

export default PointsList