import styles from './TopUpBalanceStepTwo.module.scss'
import {CopyIcon, HeaderBackIcon, TickIcon} from '../../assets';
import {currencies} from './currencies';
import {Box, Fade, Popper} from '@mui/material';
import QRCode from 'react-qr-code';
import {CopyToClipboard} from 'react-copy-to-clipboard/lib/Component';
import classNames from 'classnames';
import {useRef, useState} from 'react';
import useBreakpoints from '../../hooks/useBreakpoints';
import {TextMarquee} from '../../components/TextMarquee/TextMarquee';

export const TopUpBalanceStepTwo = ({response = {}, setTab, selectedCurrency, value}) => {
  const {isMobile} = useBreakpoints();

  const [active, setActive] = useState(false);
  const ref = useRef();
  const ref1 = useRef();

  const renderCard = (title, content, isSmall) => {
    return (
      <Box maxWidth={isSmall ? (isMobile ? 100 : 180) : 'initial'}
           className={styles.card}>
        <span className={styles.cardTitle}>{title}</span>
        <div className={styles.cardText}>{content}</div>
      </Box>
    )
  }

  const currencyItem = currencies.find(currency => currency.code === selectedCurrency);

  const onCopy = (r) => {
    const el = r.current
    if (el) {
      el.className.baseVal = classNames(styles.copyIcon, styles.active);
    }
    setActive(el)
    setTimeout(() => {
      el.className.baseVal = styles.copyIcon;
      setActive(null);
    }, 1500);
  };

  return <div className={styles.container}>
    <div className={styles.header}>
      <HeaderBackIcon onClick={() => setTab(0)} className={styles.backButton}/>
      <span>{isMobile ? 'Your order:' : 'Back'}</span>
    </div>

    <div className={styles.cardsContainer}>
      {renderCard(isMobile ? 'Method:' : 'Payment method:',
        <Box display={'flex'} justifyContent={'center'} mx={'auto'}>
          {currencyItem.icon}{currencyItem.name}
        </Box>,
        true
      )}

      <Box maxWidth={'initial'}
           className={styles.card}>
        <span className={styles.cardTitle}>{'Network:'}</span>
        <TextMarquee
          child={
            <Box
              className={classNames(styles.cardText)}
              width={'fit-content !important'}
            >
              {response.network}
            </Box>
          }
          centered
        />

      </Box>

      {renderCard('Amount:', '$' + value, true)}
    </div>


    <div className={styles.addressContainer}>
      <div className={styles.qrCodeContainer}>
        <QRCode size={isMobile ? 140 : 170} value={response.qrString || ''}/>
      </div>
      <div className={styles.addressContent}>
        <Box mb={isMobile ? 1 : 3}>
          <span className={styles.label}>Address:</span>
          <CopyToClipboard onCopy={() => onCopy(ref)} text={response.address}>
            <div className={styles.field}>
              <TextMarquee
                child={
                  <div className={styles.text}>{response.address}</div>
                }
              />

              <CopyIcon
                ref={ref}
                className={classNames(styles.copyIcon)}
              />
            </div>
          </CopyToClipboard>

        </Box>

        <Box mb={3}>
          <span className={styles.label}>Amount:</span>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <CopyToClipboard onCopy={() => onCopy(ref1)} text={response.amount}>
              <Box width={'fit-content'} maxWidth={'50%'} mr={1} className={styles.field}>
                <div className={styles.text}>{response.amount}&nbsp;{selectedCurrency}</div>
                <CopyIcon
                  ref={ref1}
                  className={classNames(styles.copyIcon)}
                />
              </Box>
            </CopyToClipboard>
            <span className={styles.greySmallText}>Exchange rate{isMobile ? <br/> : ' '}of ${response.rate}</span>
          </Box>
        </Box>

        <span className={styles.greySmallText}>
                    The address is valid for 1 hour. The actual amount you receive {!isMobile && <br/>}may differ due to changes in the current exchange rate.
                </span>

      </div>
    </div>

    <Popper
      open={!!active}
      anchorEl={active}
      placement={'bottom-end'}
      transition
    >
      {({TransitionProps}) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box className={styles.copiedContainer}>
            <TickIcon/>
            &nbsp;<span className={styles.copied}>Copied!</span>
          </Box>
        </Fade>
      )}
    </Popper>
  </div>
}
