import styles from './Dashboard.module.scss';
import Badge from './components/Badge';
import {Button} from '../../../../components/Buttons';
import {AvailableIcon, EarnedIcon, InviteesIcon, WithdrawnIcon,} from '../../../../assets';
import {useRef, useState} from 'react';
import {ClickAwayListener, Fade, Popper} from '@mui/material';
import EventEmitter from '../../../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from '../../../../components/Modals/const';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import {observer} from 'mobx-react';

const Dashboard = ({data}) => {
  const {isMobile} = useBreakpoints();
  const [mode, setMode] = useState('left');
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const withdrawBalance = () => {
    if (data.availableCents < 200) {
      setOpen(true);
      setMode('left');
      return;
    }
    EventEmitter.emit(MODAL_EVENTS.openModal, MODAL_TYPES.withdrawToBalance);
  };

  const withdrawCrypto = () => {
    if (data.availableCents < 5000) {
      setOpen(true);
      setMode('right');
      return;
    }
    EventEmitter.emit(MODAL_EVENTS.openModal, MODAL_TYPES.withdrawToCrypto);
  };

  if (!data) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.badgeGroup}>
        <Badge
          icon={<InviteesIcon/>}
          title="Total invitees:"
          text={data.invited}
        />
        <Badge
          icon={<EarnedIcon/>}
          title="Earned:"
          text={'$' + parseFloat(data.earnedCents / 100).toFixed(2)}
        />
      </div>

      <div className={styles.badgeGroup}>
        <Badge
          icon={<WithdrawnIcon/>}
          title={isMobile ? 'Withdrawn:' : 'Withdrawn\nby you:'}
          text={'$' + parseFloat(data.withdrawedCents / 100).toFixed(2)}
        />
        <Badge
          icon={<AvailableIcon/>}
          title={isMobile ? 'Available:' : 'Available\nfor withdrawal:'}
          text={'$' + parseFloat(data.availableCents / 100).toFixed(2)}
        />
      </div>

      <div ref={ref} className={styles.badgeGroup}>
        <Button
          onClick={withdrawBalance}
          colorScheme={data.availableCents < 200 ? 'dark' : undefined}
          fakeDisabled={data.availableCents < 200}
          onMouseEnter={() => {
            if (data?.availableCents < 200) {
              setOpen(true);
              setMode('left');
            }
          }}
        >
          <div className={styles.buttonTextContainer}>
            <span>Withdraw to{!isMobile && <>&nbsp;</>}</span>
            <span>Balance</span>
          </div>
        </Button>

        {!isMobile && <div className={styles.absolutText}>or</div>}

        <Button
          onClick={withdrawCrypto}
          colorScheme={data.availableCents < 5000 ? 'dark' : undefined}
          fakeDisabled={data.availableCents < 5000}
          onMouseEnter={() => {
            if (data?.availableCents < 5000) {
              setOpen(true);
              setMode('right');
            }
          }}
        >
          <div className={styles.buttonTextContainer}>
            <span>Withdraw to{!isMobile && <>&nbsp;</>}</span>
            <span>Crypto</span>
          </div>
        </Button>
      </div>

      <Popper
        open={open}
        anchorEl={ref.current}
        placement={mode === 'left' ? 'top-start' : 'top-end'}
        transition
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className={styles.notEnoughFundsContainer}>
              <ClickAwayListener
                onClickAway={() => (open ? setOpen(false) : undefined)}
              >
                <span>
                  {mode === 'left'
                    ? 'Oops, you don\'t have enough\nrewards to withdraw (min. $2).'
                    : 'Oops, you don\'t have enough\nrewards to withdraw (min. $50).'}
                </span>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default observer(Dashboard);
