export const keypadButtons = [
  {
    title: '1',
    subtitle: ' ',
  },
  {
    title: '2',
    subtitle: 'A B C',
  },
  {
    title: '3',
    subtitle: 'D E F',
  },
  {
    title: '4',
    subtitle: 'G H I',
  },
  {
    title: '5',
    subtitle: 'J K L',
  },
  {
    title: '6',
    subtitle: 'M N O',
  },
  {
    title: '7',
    subtitle: 'P Q R S',
  },
  {
    title: '8',
    subtitle: 'T U V',
  },
  {
    title: '9',
    subtitle: 'W X Y Z',
  },
  {
    title: '*',
  },
  {
    title: '0',
    subtitle: '+',
  },
  {
    title: '#',
  },
]