import {CloseIcon} from '../../../../assets';
import commonStyles from '../../ModalsRoot.module.scss';
import {Box} from '@mui/material';
import styles from './ActivationOfPremiumCongratulations.module.scss';
import classNames from 'classnames';
import useBreakpoints from '../../../../hooks/useBreakpoints';

const ActivationOfPremiumCongratulations = ({onClose, amount}) => {
  const {isMobile} = useBreakpoints();

  const getPackage = () => {
    switch (amount) {
      case 5:
        return '1 day';
      case 30:
        return '1 month';
      case 180:
        return '1 year';
      default:
        return '';
    }
  };

  return (
    <Box maxWidth={isMobile ? 320 : 368} className={commonStyles.defaultModal}>
      <div onClick={onClose} className={commonStyles.closeButtonContainer}>
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <h4 className={classNames(commonStyles.heading, styles.heading)}>
        Congratulations!
      </h4>

      <Box mt={1}>
        <span className={commonStyles.text}>
          The premium plan is connected
          <br/>
          for {getPackage(amount)}, ${amount} was withdrawn
          <br/>
          from the balance.
        </span>
      </Box>
    </Box>
  );
};

export default ActivationOfPremiumCongratulations;
