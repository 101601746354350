import styles from './ListOfCountriesModal.module.scss';
import commonStyles from '../../../../../../components/Modals/ModalsRoot.module.scss';
import {CloseIcon} from '../../../../../../assets';
import {Box, Modal} from '@mui/material';
import {getFlagEmoji} from '../../../../../../utils/getFlagEmoji';
import useBreakpoints from '../../../../../../hooks/useBreakpoints';

const ListOfCountriesModal = ({setOpen, open, plan}) => {
  const {isMobile} = useBreakpoints();
  return <Modal disableAutoFocus={true} onClose={() => setOpen(false)} open={open}>
    <div className={styles.modalContainer}>
      <div className={styles.modalTitle}>Countries eSIM works</div>
      <div onClick={() => setOpen(false)} className={commonStyles.closeButtonContainer}>
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <Box className={isMobile ? '' : styles.grid} height={'233px'}
           style={{overflowY: 'scroll', marginRight: '-12px', paddingRight: '12px'}}>
        {plan.countries.map((country) => {
          return <div className={styles.country}>
            {getFlagEmoji(country.countryCode)}&nbsp;{country.countryName}
          </div>
        })}
      </Box>
    </div>
  </Modal>
}

export default ListOfCountriesModal;