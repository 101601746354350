import {Modal} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import EventEmitter from '../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from './const';
import TopUpBalanceModal from './components/TopUpBalanceModal/TopUpBalanceModal';
import styles from './ModalsRoot.module.scss';
import AttentionLowBalance from './components/AttentionLowBalance/AttentionLowBalance';
import ActivationOfPremium from './components/ActivationOfPremium/ActivationOfPremium';
import ActivationOfPremiumCongratulations
  from './components/ActivationOfPremiumCongratulations/ActivationOfPremiumCongratulations';
import CliBasic from './components/CliBasic/CliBasic';
import CliChange from './components/CliChange/CliChange';
import WithdrawToBalance from './components/WithdrawToBalance/WithdrawToBalance';
import WithdrawCongratulations from './components/WithdrawCongratulations/WithdrawCongratulations';
import WithdrawToCrypto from './components/WithdrawToCrypto/WithdrawToCrypto';
import WithdrawToBalanceCongratulations
  from './components/WithdrawToBalanceCongratulations/WithdrawToBalanceCongratulations';
import BuyESIMCongratulations from './components/BuyESIMCongratulations/BuyESIMCongratulations';
import DeleteESIMNotification from './components/DeleteESIMNotification/DeleteESIMNotification';
import BuyDIDCongratulations from './components/BuyDIDCongratulations/BuyDIDCongratulations';
import DefaultNotification from './components/DefaultNotification/DefaultNotification';
import IncomingCallModal from 'components/Modals/components/IncomingCallModal/IncomingCallModal';
import HelpDesk from './components/HelpDesk/HelpDesk';
import VoiceBasic from './components/VoiceBasic/VoiceBasic';
import BuyNFT from './components/BuyNFT/BuyNFT';

const ModalsRoot = () => {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState();

  useEffect(() => {
    setOpen(!!modalContent);
  }, [modalContent]);

  const modalOnClose = useCallback(() => {
    setOpen(false);
    setModalContent(null);
  }, []);

  // TODO: replace generic text modals with MODAL_TYPES.defaultNotification
  const onModalOpen = useCallback(
    (type, ...args) => {
      let element = null;

      switch (type) {
        case MODAL_TYPES.topUpBalance:
          element = <TopUpBalanceModal onClose={modalOnClose}/>;
          break;
        case MODAL_TYPES.attentionLowBalance:
          element = <AttentionLowBalance onClose={modalOnClose}/>;
          break;
        case MODAL_TYPES.activationOfPremium:
          // eslint-disable-next-line no-undef
          element = (
            <ActivationOfPremium
              amount={args[0]}
              onClose={modalOnClose}
            />
          );
          break;
        case MODAL_TYPES.activationOfPremiumCongratulations:
          element = (
            <ActivationOfPremiumCongratulations
              amount={args[0]}
              onClose={modalOnClose}
            />
          );
          break;
        case MODAL_TYPES.cliBasic:
          element = <CliBasic onClose={modalOnClose}/>;
          break;
        case MODAL_TYPES.voiceBasic:
          element = <VoiceBasic onClose={modalOnClose}/>;
          break;
        case MODAL_TYPES.cliChange:
          element = <CliChange onClose={modalOnClose}/>;
          break;
        case MODAL_TYPES.withdrawToBalance:
          element = <WithdrawToBalance onClose={modalOnClose}/>;
          break;
        case MODAL_TYPES.withdrawToCrypto:
          element = <WithdrawToCrypto onClose={modalOnClose}/>;
          break;
        case MODAL_TYPES.withdrawCongratulations:
          element = <WithdrawCongratulations onClose={modalOnClose}/>;
          break;
        case MODAL_TYPES.withdrawToBalanceCongratulations:
          element = <WithdrawToBalanceCongratulations onClose={modalOnClose}/>;
          break;
        case MODAL_TYPES.buyESIMCongratulations:
          element = <BuyESIMCongratulations onClose={modalOnClose}/>;
          break;
        case MODAL_TYPES.deleteESIMNotification:
          element = <DeleteESIMNotification onClose={modalOnClose}/>;
          break;
        case MODAL_TYPES.buyDIDCongratulations:
          element = <BuyDIDCongratulations onClose={modalOnClose}/>;
          break;
        case MODAL_TYPES.defaultNotification:
          element = <DefaultNotification
            data={args[0]}
            onClose={modalOnClose}
          />;
          break;
        case MODAL_TYPES.onDirectCall:
          element = <IncomingCallModal
            data={args[0]}
            onClose={modalOnClose}
          />;
          break;
        case MODAL_TYPES.helpDesk:
          element = <HelpDesk onClose={modalOnClose}/>;
          break;
        case MODAL_TYPES.buyNFT:
          element = <BuyNFT onClose={modalOnClose}/>;
          break;
        default:
          element = null;
      }

      setModalContent(element);
    },
    [modalOnClose]
  );

  useEffect(() => {
    EventEmitter.on(MODAL_EVENTS.openModal, onModalOpen);

    return () => {
      EventEmitter.off(MODAL_EVENTS.openModal, onModalOpen);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      disableAutoFocus={true}
      onClose={modalOnClose}
      open={open}
    >
      <div className={styles.container}>{modalContent}</div>
    </Modal>
  );
};

export default ModalsRoot;
