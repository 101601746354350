import {CloseIcon} from '../../../../assets';
import commonStyles from '../../ModalsRoot.module.scss';
import {Button} from '../../../Buttons';
import {useEffect, useState} from 'react';
import InputWithFrame from '../../../InputWithFrame/InputWithFrame';
import {Box} from '@mui/material';
import useRequest from '../../../../hooks/useRequest';
import EventEmitter from '../../../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from '../../const';

const WithdrawToBalance = ({onClose}) => {
  const [value, setValue] = useState(0);
  const [data, setData] = useState({});
  const {postDataWithCallback, getDataWithCallback} = useRequest();

  useEffect(() => {
    getDataWithCallback('/api/user/earn', undefined, (data) => {
      setData(data);
    });
  }, [getDataWithCallback]);

  const valueOnChange = (value) => {
    setValue(value?.replace(/^0+/, '').replace(/[^\d.]/g, '') || '');
  };

  const currencyRegExp = new RegExp('^\\d+([.]\\d{1,2})?$');

  const onPayClick = async () => {
    postDataWithCallback(
      '/api/user/earn/balance',
      {cents: parseFloat(value * 100)},
      () => {
        EventEmitter.emit(
          MODAL_EVENTS.openModal,
          MODAL_TYPES.withdrawToBalanceCongratulations
        );
      }
    );
  };

  return (
    <div className={commonStyles.defaultModal}>
      <div onClick={onClose} className={commonStyles.closeButtonContainer}>
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <h4 className={commonStyles.heading}>Withdrawal of Reward</h4>

      <Box my={2}>
        <InputWithFrame
          value={value}
          onChange={valueOnChange}
          prefix="$"
          placeholder={'$0'}
          type="tel"
          pattern="[0-9]*"
          noValidate
        />
      </Box>

      <Button
        onClick={onPayClick}
        disabled={
          !currencyRegExp.test(value || '') ||
          value === '0' ||
          parseFloat(value) < 2 ||
          parseFloat(value) > data.availableCents / 100
        }
        style={{width: '100%'}}
      >
        Send to Balance
      </Button>
    </div>
  );
};

export default WithdrawToBalance;
