import styles from './DIDListCard.module.scss';
import {HeaderBackIcon} from '../../../../../../assets';
import {Box} from '@mui/material';
import {ReactComponent as VoiceIcon} from '../../icons/voiceIconActive.svg';
import {ReactComponent as SMSIcon} from '../../icons/smsIconActive.svg';

export const DIDListCard = ({title, onSelect, setupFee, rentFee, type, subTotal}) => {

  return <div className={styles.countryCard}>
    <div className={styles.cardHeader}>
      {title}
      {onSelect && <HeaderBackIcon className={styles.icon} onClick={onSelect}/>}
    </div>

    <Box display={'flex'} justifyContent={type === 'voice' ? 'flex-start' : 'space-between'}>
      <Box mr={type === 'voice' ? 3 : 1} className={styles.cardInfo}>
        <div className={styles.cardInfoTitle}>Type number</div>
        <div className={styles.cardInfoText}>
          {
            type === 'voice' ?
              <><VoiceIcon/>Voice</> :
              <><VoiceIcon/>Voice +&nbsp;<SMSIcon/>SMS</>
          }
        </div>
      </Box>

      <Box mr={type === 'voice' ? 3 : 1} className={styles.cardInfo}>
        <div className={styles.cardInfoTitle}>Setup fee</div>
        <div className={styles.cardInfoText}>
          ${setupFee}
        </div>
      </Box>

      <Box mr={type === 'voice' ? 3 : 1} className={styles.cardInfo}>
        <div className={styles.cardInfoTitle}>Monthly</div>
        <div className={styles.cardInfoText}>
          ${rentFee}&nbsp;
          <span>/ mo</span>
        </div>
      </Box>
    </Box>

    {subTotal && (
      <div className={styles.cardInfoSubtotal}>
        Subtotal:<span>${subTotal}</span>
      </div>
    )}
  </div>
}