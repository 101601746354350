import {ReactComponent as FlagIcon} from '../icons/flag.svg';
import {Box} from '@mui/material';
import {useState} from 'react';
import styles from './ESIMListOfCountries.module.scss'
import useBreakpoints from '../../../../../hooks/useBreakpoints';
import ListOfCountriesModal from './ListOfCountriesModal/ListOfCountriesModal';

export const ESIMListOfCountries = ({plan}) => {
  const {isMobile} = useBreakpoints();
  const [open, setOpen] = useState(false);

  return <>
    <Box onClick={() => setOpen(true)} className={styles.text} mt={isMobile ? 3 : 0} display={'flex'}
         alignItems={'center'}
         justifyContent={'center'}>
      <Box mr={1.5} height={'24px'}><FlagIcon/></Box>List of countries ({plan.countries.length})
    </Box>

    <ListOfCountriesModal setOpen={setOpen} open={open} plan={plan}/>
  </>
}