import {CloseIcon, EmailIcon, GitBookIcon, TelegramIcon} from '../../../../assets';
import commonStyles from '../../ModalsRoot.module.scss';
import {Box} from '@mui/material';
import styles from './HelpDesk.module.scss';
import classNames from 'classnames';
import {IconButton} from './components/IconButton';
import {openUrl} from '../../../../utils/url';
import useBreakpoints from '../../../../hooks/useBreakpoints';

const HelpDesk = ({onClose, data}) => {
  const {isMobile} = useBreakpoints();
  return (
    <div
      className={classNames(commonStyles.defaultModal, styles.modal)}
    >
      <div
        onClick={onClose}
        className={commonStyles.closeButtonContainer}
      >
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <h4 className={classNames(commonStyles.heading, styles.headingYellow)}>
        Help Desk
      </h4>

      <Box mt={isMobile ? 1 : 2}>
        <Box
          mb={1.5}
          className={styles.text}
        >
          Documentation:
        </Box>
        <Box
          display={'flex'}
          justifyContent={'center'}
          mb={isMobile ? 2 : 3}
        >
          <IconButton
            text={'GitBook'}
            icon={<GitBookIcon/>}
            onClick={() => openUrl('https://docs.dcalls.org')}
          />
        </Box>

        <Box
          mb={1.5}
          className={styles.text}
        >
          If you have any questions,<br/>
          you can ask them here:
        </Box>

        <Box
          display={'flex'}
          gap={2}
          justifyContent={'center'}
        >
          <IconButton
            text={'Telegram'}
            icon={<TelegramIcon/>}
            onClick={() => openUrl('https://t.me/dCallsCareBot')}
          />
          <IconButton
            text={'Email'}
            icon={<EmailIcon/>}
            onClick={() => openUrl('mailto:help@dcalls.org')}
          />
        </Box>
      </Box>
    </div>
  );
};

export default HelpDesk;
