import {useNavigate} from 'react-router-dom';
import commonStyles from 'components/Modals/ModalsRoot.module.scss';
import {Button} from 'components/Buttons';
import {AcceptCallIcon, HangUpIcon, IncomingCallIcon} from 'assets';
import {Box} from '@mui/material';
import styles from './IncomingCallModal.module.scss';
import {useCallback, useEffect} from 'react';
import useRequest from 'hooks/useRequest';
import {MODAL_TYPES} from 'components/Modals/const';
import EventEmitter from 'utils/events/EventEmitter';
import {appStore} from 'stores/AppStore';

const IncomingCallModal = ({data, onClose}) => {
  const navigate = useNavigate();
  const {postDataWithCallback} = useRequest();

  useEffect(() => {
    EventEmitter.on(MODAL_TYPES.onDirectCallEnd, onClose);
    appStore.soundEffect.src = '/callSound.mp3';

    return () => {
      EventEmitter.off(MODAL_TYPES.onDirectCallEnd, onClose);
      appStore.resetSoundEffect();
    }
  }, [onClose]);

  const decline = useCallback(() => {
    postDataWithCallback('/api/call/incoming/decline', {Key: data.key}, (data) => {
        onClose();
      }
    )
  }, [data.key, onClose, postDataWithCallback]);

  return (
    <div
      className={commonStyles.defaultModal}
    >
      <h4 className={commonStyles.heading}>+{data.from}</h4>

      <div className={styles.yellowText}>
        Incoming call
      </div>

      <div className={styles.toNumber}>
        <IncomingCallIcon/>&nbsp;+{data.to}
      </div>

      <Box display={'flex'} gap={'32px'} justifyContent={'center'}>
        <Box display={'flex'} alignItems={'center'} flexDirection={'column'} gap={1}>
          <Button
            onClick={() => {
              decline();
            }}
            style={{
              height: 64,
              width: 96,
              background: '#D00F0F',
              borderRadius: 20
            }}
          >
            <HangUpIcon/>
          </Button>
          <div className={styles.greyText}>
            Decline
          </div>
        </Box>

        <Box display={'flex'} alignItems={'center'} flexDirection={'column'} gap={1}>
          <Button
            onClick={() => {
              navigate('/make-a-call?state=' + JSON.stringify({state: {Key: data.key, number: data.from}}));
              onClose();
            }}
            style={{
              height: 64,
              width: 96,
              background: '#0FD05C',
              borderRadius: 20
            }}
          >
            <AcceptCallIcon/>
          </Button>
          <div className={styles.greyText}>
            Accept
          </div>
        </Box>

      </Box>
    </div>
  )
}

export default IncomingCallModal