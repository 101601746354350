import {useCallback, useEffect, useState} from 'react';
import EventEmitter from 'utils/events/EventEmitter';
import {Box} from '@mui/material';
import DIDSMS from 'components/IncomingSMS/components/DIDSMS/DIDSMS';

const IncomingSMS = () => {
  const [smsStack, setSmsStack] = useState([]);

  const onRead = useCallback((date) => {
    setSmsStack(smsStack => smsStack.filter(sms => sms.date !== date));
  }, [])

  const onSms = useCallback((sms) => {
    setSmsStack(stack => [...stack, sms])
  }, []);

  useEffect(() => {
    EventEmitter.on('incoming_sms', onSms);

    return () => {
      EventEmitter.off('incoming_sms', onSms);
    };
  }, [onSms]);

  if (smsStack.length === 0) {
    return null;
  }

  return <Box position={'absolute'} top={16} left={20} right={20} display={'flex'} alignItems={'center'}>
    <DIDSMS sms={smsStack[0]} onRead={onRead}/>
  </Box>
}

export default IncomingSMS;