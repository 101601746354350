import {useEffect} from 'react';
import {useDisconnect} from 'wagmi';

const Disconnect = () => {
  const {disconnect} = useDisconnect();

  useEffect(() => {
    disconnect();
  }, [disconnect]);

  return null;
};

export default Disconnect;
