import {createTheme} from '@mui/material';
import variables from './assets/styles/_export.scss'

let theme = createTheme({
  breakpoints: {
    xs: variables.xs,
    sm: variables.sm,
    md: variables.md,
    lg: variables.lg,
    xl: variables.xl,
  }
})
theme = createTheme(theme, {
  palette: {
    primary: {
      dark: variables.grey
    },
    secondary: {
      main: variables.yellow
    }
  },
  typography: {
    h2: {
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        fontSize: '24px',
        lineHeight: '36px',
      }
    },
    h6: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 400,
      letterSpacing: '0.03em',
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: 'initial'
      }
    },

  }
})

export {theme}