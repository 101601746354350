import styles from './TabNavigation.module.scss';
import classNames from 'classnames';

export const TabNavigation = ({tabs, setTabIndex, tabIndex}) => {
  return (
    <div className={styles.tabs}>
      {tabs.map((tab, index) => {
        return (
          <div
            key={index}
            onClick={() => setTabIndex(index)}
            className={classNames(
              styles.tab,
              String(tabIndex) === String(index) ? styles.tabActive : ''
            )}
          >
            {tab.name}
            {tab.notification && (<div className={styles.redDot}/>)}
          </div>
        );
      })}
    </div>
  );
};
