import styles from './DIDSMSLogs.module.scss';
import {Box} from '@mui/material';
import {useCallback, useEffect, useMemo, useState} from 'react';
import useRequest from '../../../../hooks/useRequest';
import DIDSMSCard from './components/DIDSMSCard/DIDSMSCard';
import Pagination from '../../../../components/Pagination/Pagination';
import {observer} from 'mobx-react';
import {appStore} from 'stores/AppStore';

const DIDSMSLogs = () => {
  const {getDataWithCallback} = useRequest();
  const [sms, setSms] = useState();
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    appStore.setCurrentUser({...appStore.currentUser, unreadSms: false})
  }, [])

  useEffect(() => {
    window.scrollTo({behavior: 'smooth', top: 0});
  }, [page, perPage]);

  const fetchSms = useCallback(() => {
    return getDataWithCallback('/api/sms/all', undefined, (data) => {
      setSms(data);
    });
  }, [getDataWithCallback]);

  useEffect(() => {
    void fetchSms();
  }, [fetchSms]);

  const itemsToRender = useMemo(() => {
    return sms?.slice(page * perPage - perPage, page * perPage);
  }, [page, perPage, sms]);

  return <div className={styles.container}>
    {sms?.length === 0 && (
      <Box pb={3} className={styles.text}>
        You have no inbound SMS logs
      </Box>
    )}

    {sms?.length === 1 && (
      <Box mx={'auto'} maxWidth={440} width={'100%'}>
        {itemsToRender.map((sms) => <DIDSMSCard key={sms.id} sms={sms} refetchSms={fetchSms}/>)}
      </Box>
    )}

    {sms?.length > 1 &&
      <>
        <Box className={styles.smsContainer}>
          {itemsToRender.map((sms) => <DIDSMSCard key={sms.id} sms={sms} refetchSms={fetchSms}/>)}
        </Box>

        <div className={styles.paginationContainer}>
          <Pagination
            page={page}
            perPage={perPage}
            onPageChange={setPage}
            onPerPageChange={setPerPage}
            itemsCount={sms.length}
          />
        </div>
      </>
    }
  </div>
}

export default observer(DIDSMSLogs);