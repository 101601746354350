import styles from './ESIMDeleteModal.module.scss';
import commonStyles from '../../../../../components/Modals/ModalsRoot.module.scss';
import {CloseIcon} from '../../../../../assets';
import {Box, Modal} from '@mui/material';
import {Button} from '../../../../../components/Buttons';
import {esimLocations} from '../../ESIMBuy/ESIMBuy';
import {getFlagEmoji} from '../../../../../utils/getFlagEmoji';
import moment from 'moment';
import useBreakpoints from '../../../../../hooks/useBreakpoints';
import BoxWithHeader from '../../../../../components/Misc/BoxWithHeader/BoxWithHeader';

const ESIMDeleteModal = ({esim, onDelete, onClose, countries}) => {
  const {isMobile} = useBreakpoints();

  return <Modal disableAutoFocus={true} onClose={onClose} open={true}>
    <div className={styles.modalContainer}>
      <div className={styles.modalTitle}>{'Are you sure you want to\nremove selected eSIM?'}</div>
      <div onClick={onClose} className={commonStyles.closeButtonContainer}>
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      {isMobile ? (
        <div className={styles.plan}>
          <Box display={'flex'} flexDirection={'column'}>
            <div
              className={styles.esimTitle}>{esim.region ? esimLocations.find(loc => loc.code === esim.region).name : (
              <>{getFlagEmoji(esim.code)}&nbsp;{countries.find(c => c.countryCode === esim.code).countryName}</>
            )}
            </div>
            <div className={styles.esimPlan}>
              <span>Plan</span>&nbsp;{esim.capacity} GB
              for {esim.period} days
            </div>
          </Box>

          <div className={styles.esimUntil}>
            <div>ID order&nbsp;<span>{esim.orderID}</span></div>
            <div>Purchase&nbsp;<span>{moment(esim.purchasedAt * 1000).format('DD.MM.YYYY (HH:mm:ss)')}</span></div>
          </div>
        </div>
      ) : (
        <BoxWithHeader headerText={
          <Box className={styles.rowHeader} display={'flex'} justifyContent={'space-between'}>
            <Box width={'120px'}>Date of purchase</Box>
            <Box width={'120px'}>ID order</Box>
            <Box width={'250px'}>Country</Box>
            <Box width={'150px'}>Package</Box>
          </Box>
        }>
          <Box className={styles.row} display={'flex'} justifyContent={'space-between'}>
            <Box width={'120px'}>
              <div className={styles.rowText}>
                {moment(esim.purchasedAt * 1000).format('DD.MM.YYYY')}<br/>
                <span>{moment(esim.purchasedAt * 1000).format('(HH:mm:ss)')}</span>
              </div>
            </Box>
            <Box width={'120px'}>
              <div className={styles.esimUntil}>
                <div><span>{esim.orderID}</span></div>
              </div>
            </Box>
            <Box width={'250px'}>
              <div
                className={styles.rowText}>{esim.region ? esimLocations.find(loc => loc.code === esim.region).name : (
                <>{getFlagEmoji(esim.code)}&nbsp;{countries.find(c => c.countryCode === esim.code).countryName}</>
              )}
              </div>
            </Box>
            <Box width={'150px'}>
              <div className={styles.rowText}>
                {esim.capacity} GB<span> for {esim.period} days</span>
              </div>
            </Box>
          </Box>
        </BoxWithHeader>
      )}


      <div className={styles.danger}>
        <span>Important!</span>&nbsp;The eSIM will be deleted immediately.
      </div>

      <Box display={'flex'} gap={'16px'} maxWidth={344} mx={'auto'}>
        <Button style={{width: '100%'}} onClick={onClose} colorScheme={'dark'}>Cancel</Button>
        <Button style={{width: '100%'}} onClick={onDelete}>Delete</Button>
      </Box>
    </div>
  </Modal>
}

export default ESIMDeleteModal