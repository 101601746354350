import {useEffect, useRef, useState} from 'react';
import {Tone} from 'utils/Tone';
import useRequest from './useRequest';
import {loadCurrentUser} from 'lib/auth';
import {getTokenValue} from 'utils/LocalStorage';

export const usePhoneCall = ({phoneNumber, onHandleDisconnect}) => {
  const {postDataWithCallback} = useRequest();
  const dcRef = useRef();
  const pcRef = useRef();
  const localStreamRef = useRef();
  const audioRemoteRef = useRef();
  const ringtoneRef = useRef(new Tone(400, 450));
  const [id, setId] = useState(null);
  const [ice, setIce] = useState(false);
  const [status, setStatus] = useState();
  const [elapsed, setElapsed] = useState(0);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const intervalRef = useRef();
  const baseUrl = useRef('/api/call')

  useEffect(() => {
    if (status === 'Confirmed') {
      setElapsed(1);
      intervalRef.current = setInterval(
        () => setElapsed((prev) => prev + 1),
        1000
      );
    } else {
      clearInterval(intervalRef.current);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [status]);

  useEffect(() => {
    if (status === 'No answer') {
      setTimeout(() => setStatus(undefined), 2000);
    }
  }, [status]);

  useEffect(() => {
    if (ice && id !== null) {
      sendAnswer();
    }
    // eslint-disable-next-line
  }, [ice, id]);

  function sendAnswer() {
    let ans = {};
    ans.sdp = pcRef.current.localDescription;
    ans.id = id;
    postDataWithCallback(
      baseUrl.current + '/answer',
      ans,
      (data) => {
        console.log(data);
      },
      (error) => {
        console.error(error);
        handleDisconnect();
      }
    );
  }

  const call = () => {
    ringtoneRef.current.startRinging();
    setStatus('Calling');
    connect({number: phoneNumber});
  };

  const incomingCall = (Key) => {
    setStatus('Incoming call');
    connect({Key});
  }

  function connect(offerObject) {
    const off = offerObject;

    pcRef.current.onicecandidate = (event) => {
      console.log('candidate: ', event.candidate);
      setIce(true);
    };

    postDataWithCallback(baseUrl.current + '/offer', off, (data) => {
      pcRef.current
        .setRemoteDescription(new RTCSessionDescription(data.sdp))
        .catch(console.error);
      pcRef.current
        .createAnswer()
        .then((answer) => {
          answer.sdp = answer.sdp.replaceAll(
            'a=setup:active',
            'a=setup:passive'
          );
          pcRef.current.setLocalDescription(answer);
        })
        .catch(console.error);
      setId(data.id);
    });
  }

  function reset() {
    setId(0);
    setIce(false);
    setStatus(undefined);
    setElapsed(0);
    baseUrl.current = '/api/call'
  }

  async function initWebrtc(Key) {
    reset();

    if (Key) {
      baseUrl.current = '/api/call/incoming';
    }

    let localPc = new RTCPeerConnection({
      iceServers: [
        {
          urls: 'stun:web.yous.ai:33478',
        },
      ],
    });

    localPc.onsignalingstatechange = (e) => {
      console.log(`signalling: ${localPc.signalingState}`);
    };

    localPc.onicegatheringstatechange = () => {
      console.log(`onicegatheringstatechange: ${localPc.iceGatheringState}`);
    };

    localPc.oniceconnectionstatechange = (e) => {
      console.log(`connection: ${localPc.iceConnectionState}`);
    };

    localPc.ondatachannel = (e) => {
      let dc = e.channel;
      dcRef.current = dc;
      dc.onclose = () => {
        console.log('dc has closed');
        handleDisconnect();
      };
      dc.onopen = () => {
        if (Key) {
          setStatus('Confirmed');
        }
        console.log('dc has opened')
      };
      dc.onmessage = (e) => {
        console.log('handleDCMessage', e);
        handleDCMessage(e.data);
      };
    };

    localPc.ontrack = function (event) {
      if (event.track.kind === 'audio') {
        audioRemoteRef.current.srcObject = new MediaStream(
          event.streams[0].getAudioTracks()
        );
      }
    };

    navigator.mediaDevices
      .getUserMedia({
        video: false,
        audio: true,
      })
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          localPc.addTrack(track, stream);
        });
        localStreamRef.current = stream;
        if (Key) {
          incomingCall(Key)
        } else {
          call()
        }
      })
      .catch(console.log);

    pcRef.current = localPc;
  }

  function handleDCMessage(data) {
    if (['Confirmed'].includes(data)) {
      ringtoneRef.current.stopRinging();
      setStatus(data);
    }

    if (data === 'Failure') {
      handleDisconnect();
      setStatus('No answer');
    }

    if (data === 'Terminated') {
      handleDisconnect();
      reset();
    }
  }

  function handleDisconnect() {
    if (pcRef.current) {
      pcRef.current.close();
    }

    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
    }

    ringtoneRef.current.stopRinging();
    reset();
    onHandleDisconnect();
    void loadCurrentUser(getTokenValue());
  }

  function toggleAudio() {
    const prevState = audioEnabled;
    if (localStreamRef.current) {
      localStreamRef.current.getAudioTracks()[0].enabled = !prevState;
    }
    setAudioEnabled(!prevState);
  }

  function sendMessageToDC(payload) {
    if (dcRef.current && dcRef.current.readyState === 'open') {
      dcRef.current.send(payload);
    }
  }

  return {
    initWebrtc,
    toggleAudio,
    handleDisconnect,
    setAudioEnabled,
    audioRemoteRef,
    status,
    elapsed,
    audioEnabled,
    sendMessageToDC,
  };
};
