import styles from './Badge.module.scss';
import {Textfit} from 'react-textfit';
import useBreakpoints from '../../../../../hooks/useBreakpoints';

const Badge = ({icon, title, text}) => {
  const {isMobile} = useBreakpoints();

  return (
    <div className={styles.container}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.textContainer}>
        <span className={styles.title}>{title}</span>
        <span className={styles.text}>
          <Textfit max={isMobile ? 18 : 32} mode="single">
            {text}
          </Textfit>
        </span>
      </div>
    </div>
  );
};

export default Badge;
