import styles from './Header.module.scss';
import {HeaderBackIcon, Logo} from '../../../assets';
import ConnectWalletButton from './components/ConnectWalletButton/ConnectWalletButton';
import {appStore} from '../../../stores/AppStore';
import {observer} from 'mobx-react';
import {uiStore} from '../../../stores/UIStore';
import TopBarInfo from './components/TopBarInfo/TopBarInfo';
import {useNavigate} from 'react-router-dom';
import {Box} from '@mui/material';
import useBreakpoints from '../../../hooks/useBreakpoints';

const Header = () => {
  const navigate = useNavigate();
  const {isMobile} = useBreakpoints();

  const goBack = () => {
    navigate('/make-a-call');
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerTop}>
        <Box
          flex={isMobile ? 0 : 1}
          display={isMobile ? 'block' : 'flex'}
          justifyContent={'flex-start'}
        >
          <Logo
            onClick={() => navigate('/make-a-call')}
            className={styles.logo}
          />
        </Box>

        <Box className={styles.desktopTitle}>
          {uiStore.showBackButton && (
            <HeaderBackIcon
              onClick={goBack}
              className={styles.backButton}
            />
          )}
          <span>{uiStore.title}</span>
        </Box>

        <Box
          flex={1}
          display={'flex'}
          justifyContent={'flex-end'}
        >
          {!appStore.authorized && <ConnectWalletButton/>}

          {appStore.authorized === true && <TopBarInfo/>}
        </Box>
      </div>

      <div className={styles.headerBottom}>
        {uiStore.showBackButton && (
          <HeaderBackIcon
            onClick={goBack}
            className={styles.backButton}
          />
        )}

        {uiStore.title && <h1 className={styles.title}>{uiStore.title}</h1>}
      </div>
    </div>
  );
};

export default observer(Header);
