export const MODAL_EVENTS = {
  openModal: 'open_modal',
};

export const MODAL_TYPES = {
  topUpBalance: 'top_up_balance',
  attentionLowBalance: 'attention_low_balance',
  activationOfPremium: 'activation_of_premium',
  activationOfPremiumCongratulations: 'activation_of_premium_congratulations',
  cliBasic: 'cli_basic',
  cliChange: 'cli_change',
  withdrawToBalance: 'withdraw_to_balance',
  withdrawToCrypto: 'withdraw_to_crypto',
  withdrawCongratulations: 'withdraw_congratulations',
  withdrawToBalanceCongratulations: 'withdraw_to_balance_congratulations',
  buyESIMCongratulations: 'buy_esim_congratulations',
  deleteESIMNotification: 'delete_esim_notification',
  buyDIDCongratulations: 'buy_did_congratulations',
  defaultNotification: 'default_notification',
  onDirectCall: 'on_direct_call',
  onDirectCallEnd: 'on_direct_call_end',
  helpDesk: 'help_desk',
  voiceBasic: 'voice_basic',
  buyNFT: 'buy_nft',
};