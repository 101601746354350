import styles from './DIDCallLogsTableMobile.module.scss';
import moment from 'moment/moment';
import {formatElapsed} from 'utils/time';
import CallStatusBadge from 'pages/CallHistory/components/CallStatusBadge/CallStatusBadge';
import {Box} from '@mui/material';

export const DIDCallLogsTableMobile = ({items}) => {
  const renderRow = (item) => {
    return <div className={styles.row}>
      <Box width={'50%'}>
        <Box mb={1} width={'fit-content'}>
          <CallStatusBadge callStatus={item.status}/>
        </Box>
        +{item.to}
        <br/>
        <span>{moment(item.startAt * 1000).format('DD.MM.YYYY')} {moment(item.startAt * 1000).format('(HH:mm:ss)')}</span>
      </Box>

      <Box width={'50%'} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
        +{item.from}
        <br/>
        <Box whiteSpace={'nowrap'}>
          <span>Spent min.&nbsp;</span>
          <div className={styles.elapsed}>{formatElapsed(item.duration)}</div>
        </Box>
      </Box>
    </div>
  }

  return <div>
    <div className={styles.header}>
      <Box width={'50%'}>DID number</Box>
      <Box width={'50%'}>Source</Box>
    </div>

    <div className={styles.rows}>
      {items.map(item => renderRow(item))}
    </div>
  </div>
}