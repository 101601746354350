import {Box} from '@mui/material';
import styles from './DIDStateSelectList.module.scss';
import {getFlagEmoji} from '../../../../../../utils/getFlagEmoji';
import {ReactComponent as VoiceIcon} from '../../icons/voiceIconActive.svg';
import {ReactComponent as SMSIcon} from '../../icons/smsIconActive.svg';
import {HeaderBackIcon} from '../../../../../../assets';
import Pagination from '../../../../../../components/Pagination/Pagination';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {ReactComponent as SearchIcon} from '../../../../../ESIM/Tabs/ESIMBuy/icons/search.svg';
import {Input} from '../../../../../../components/Input/Input';
import useRequest from '../../../../../../hooks/useRequest';
import useBreakpoints from '../../../../../../hooks/useBreakpoints';
import {DIDListCard} from '../DIDListCard/DIDListCard';

const DIDCountrySelectList = ({setSelectedRegion, type, isPremium, selectedCountry}) => {
  const {isMobile} = useBreakpoints();
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState('');
  const [regions, setRegions] = useState();
  const {getDataWithCallback} = useRequest();

  const loadStates = useCallback(() => {
    getDataWithCallback('/api/did/regions/' + selectedCountry.attributes.iso.toLowerCase() + '/' + type, undefined, response => {
      setRegions(response.data);
    })
  }, [selectedCountry.attributes.iso, getDataWithCallback, type]);

  useEffect(() => {
    loadStates();
  }, [loadStates]);

  const regionsToRender = useMemo(() => {
    if (!regions) return [];
    if (!value) return regions;

    return [...regions].filter(country => country.attributes.name.toLowerCase().includes(value.toLowerCase()));
  }, [regions, value]);

  const paginatedRegions = regionsToRender.slice(page * perPage - perPage, page * perPage);

  if (regions === undefined) return null;
  if (regions === null) {
    return <div className={styles.emptyText}>
      There is nothing here yet
    </div>
  }

  return <div className={styles.container}>
    <div className={styles.searchContainer}>
      <div className={styles.searchIcon}><SearchIcon/></div>
      <Input
        value={value}
        onChange={(val) => {
          setPage(1);
          setValue(val);
          // countriesRef.current.scrollTop = 0;
        }}
        placeholder={'Search by state name'}
      />
    </div>

    <Box mt={2}>
      {!isMobile && (
        <>
          <Box className={styles.tableHeader} display={'flex'} justifyContent={'space-between'}>
            <Box width={'250px'}>State</Box>
            <Box width={'150px'}>Type number</Box>
            <Box width={'120px'}>Setup fee</Box>
            <Box width={'120px'}>Monthly</Box>
            <Box width={'24px'}></Box>
          </Box>

          {paginatedRegions.map(region => (
            <Box key={region.id} className={styles.tableRow} display={'flex'} justifyContent={'space-between'}>
              <Box width={'250px'} className={styles.tableRowCountryName}>
                {getFlagEmoji(selectedCountry.attributes.iso)} {region.attributes.name}
              </Box>

              <Box width={'150px'} className={styles.tableRowType}>
                {
                  type === 'voice' ? <><VoiceIcon/>Voice</> :
                    <><VoiceIcon/>Voice +&nbsp;<SMSIcon/>SMS</>
                }
              </Box>

              <Box width={'120px'}>${isPremium ? parseFloat(region.setupVipPrice) : parseFloat(region.setupPrice)}</Box>

              <Box
                width={'120px'}>${isPremium ? parseFloat(region.monthlyVipPrice) : parseFloat(region.monthlyPrice)}&nbsp;
                <span>/ mo</span>
              </Box>

              <Box width={'24px'} height={'24px'}>
                <HeaderBackIcon className={styles.icon} onClick={() => setSelectedRegion(region)}/>
              </Box>
            </Box>
          ))}
        </>
      )}

      {isMobile && paginatedRegions.map(region =>
        <DIDListCard
          title={<>{getFlagEmoji(selectedCountry.attributes.iso)} {region.attributes.name}</>}
          type={type}
          onSelect={() => setSelectedRegion(region)}
          setupFee={isPremium ? parseFloat(region.setupVipPrice) : parseFloat(region.setupPrice)}
          rentFee={isPremium ? parseFloat(region.monthlyVipPrice) : parseFloat(region.monthlyPrice)}
        />
      )}

      <Box mt={3}>
        <Pagination
          page={page}
          perPage={perPage}
          onPageChange={setPage}
          onPerPageChange={setPerPage}
          itemsCount={regionsToRender.length}
        />
      </Box>
    </Box>
  </div>
}

export default DIDCountrySelectList