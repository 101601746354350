import {Box, Typography} from '@mui/material';
import MenuButton
  from '../../../../../components/Navigation/Header/components/TopBarInfo/components/MenuButton/MenuButton';
import styles from './DIDNumberMobileCard.module.scss';
import classNames from 'classnames';
import useRequest from '../../../../../hooks/useRequest';
import {useCallback, useMemo, useState} from 'react';
import EventEmitter from '../../../../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from '../../../../../components/Modals/const';
import {
  AutoRenewActiveIcon,
  AutoRenewIcon,
  DeleteIcon,
  DescriptionIcon,
  RenewIcon,
  SetAsCalledIdIcon,
  SMSActiveIcon,
  SMSIcon,
  VoiceActiveIcon,
  VoiceIcon
} from './DIDNumbersTable/DIDNumbersTableRow/icons';
import DIDDeleteModal from './DIDDeleteModal/DIDDeleteModal';
import useBreakpoints from '../../../../../hooks/useBreakpoints';
import {getFlagEmoji} from '../../../../../utils/getFlagEmoji';
import moment from 'moment/moment';
import DIDNumbersRowDescription
  from './DIDNumbersTable/DIDNumbersTableRow/components/DIDNumbersRowDescription/DIDNumbersRowDescription';
import DIDRenewModal from './DIDRenewModal/DIDRenewModal';
import {loadCurrentUser} from '../../../../../lib/auth';
import {getTokenValue} from '../../../../../utils/LocalStorage';

export const DIDNumberMobileCard = ({number, fetchNumbers, isPremium}) => {
  const {isMobile} = useBreakpoints();
  const {postDataWithCallback} = useRequest();
  const [descriptionEditOpen, setDescriptionEditOpen] = useState(false);
  const [renewOpen, setRenewOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const numberActive = useMemo(() => (
    new Date().getTime() < (number.until * 1000)
  ), [number.until])

  const enableVoice = useCallback(() => {
    postDataWithCallback('/api/did/edit/voice', {'number': number.number, 'voice': !number.enabledVoice}, () => {
      void fetchNumbers();
    })
  }, [fetchNumbers, number.enabledVoice, number.number, postDataWithCallback]);

  const enableSMS = useCallback(() => {
    postDataWithCallback('/api/did/edit/sms', {'number': number.number, 'sms': !number.enabledSms}, () => {
      void fetchNumbers();
    })
  }, [fetchNumbers, number.enabledSms, number.number, postDataWithCallback]);

  const enableAutoRenew = useCallback(() => {
    postDataWithCallback('/api/did/edit/renew', {'number': number.number, 'renew': !number.renew}, () => {
      void fetchNumbers();
    })
  }, [fetchNumbers, number.number, number.renew, postDataWithCallback]);

  const onDelete = useCallback(() => {
    postDataWithCallback('/api/did/delete', {'number': number.number}, () => {
      EventEmitter.emit(MODAL_EVENTS.openModal, MODAL_TYPES.defaultNotification,
        {title: 'Notification', text: <>{`Your DID has been${isMobile ? '\n' : ' '}permanently deleted.`}</>});
      setDeleteOpen(false);
      void fetchNumbers();
    })
  }, [fetchNumbers, isMobile, number.number, postDataWithCallback]);

  const onCliChanged = useCallback(() => {
    postDataWithCallback(
      '/api/did/cli',
      {number: number.number},
      async () => {
        EventEmitter.emit(MODAL_EVENTS.openModal, MODAL_TYPES.defaultNotification,
          {title: 'Notification', text: <>{`Your current Caller ID\nhas been changed.`}</>});
          void loadCurrentUser(getTokenValue());
      },
      (e) => {
        console.error(e);
      }
    );
  }, [number.number, postDataWithCallback])

  const menuItems = useMemo(() => {
      const items = [
        {
          title: number.enabledVoice ? 'Disable Voice' : 'Enable Voice',
          onClick: () => enableVoice(),
          icon: !number.enabledVoice ? <VoiceActiveIcon/> : <VoiceIcon/>,
        },
      ]

      if (number.type === 'voicesms') {
        items.push({
          title: number.enabledSms ? 'Disable SMS' : 'Enable SMS',
          onClick: () => enableSMS(),
          icon: !number.enabledSms ? <SMSActiveIcon/> : <SMSIcon/>,
        })
      }

      items.push({
          title: number.renew ? 'Disable renew' : 'Enable renew',
          onClick: () => enableAutoRenew(),
          icon: !number.renew ? <AutoRenewActiveIcon/> : <AutoRenewIcon/>,
        },
        ...(numberActive ? [{
          title: 'Set as Caller ID',
          onClick: onCliChanged,
          icon: <SetAsCalledIdIcon/>
        }] : []),
        {
          title: 'Set Description',
          onClick: () => setDescriptionEditOpen(true),
          icon: <DescriptionIcon/>
        },
        {
          title: 'Renew DID',
          onClick: () => setRenewOpen(true),
          icon: <RenewIcon/>,
        },
        {
          title: 'Delete DID',
          onClick: () => setDeleteOpen(true),
          icon: <DeleteIcon/>,
        },
      );
      return items;
    }, [enableAutoRenew, enableSMS, enableVoice, number.enabledSms, number.enabledVoice, number.renew, number.type, numberActive, onCliChanged]
  )

  const rentFee = (isPremium ? parseFloat(number.monthlyVipPrice) : parseFloat(number.monthlyPrice));

  return (
    <Box
      className={styles.container}
      display={'flex'}
      flexDirection={'column'}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Typography variant={'h6'}>
          +{number.number}
        </Typography>

        <MenuButton
          items={menuItems}
          size={24}
          iconSize={14}
          borderRadius={5}
        />
      </Box>

      <Typography variant={'subtitle2'}>
        {getFlagEmoji(number.countryISO)}&nbsp;{number.country}
        {number.region && ', ' + number.region}
        {number.city && ', ' + number.city}
      </Typography>

      <Box
        display={'flex'}
        gap={1}
        my={1}
      >
        <div className={styles.tag}>
          <div className={classNames(styles.tagDot, number.enabledVoice ? styles.tagDotActive : '')}/>
          Voice
        </div>

        {number.type === 'voicesms' && (
          <div className={styles.tag}>
            <div className={classNames(styles.tagDot, number.enabledSms ? styles.tagDotActive : '')}/>
            SMS
          </div>
        )}


        <div className={styles.tag}>
          <div className={classNames(styles.tagDot, number.renew ? styles.tagDotActive : '')}/>
          Auto-renew
        </div>
      </Box>

      <Box
        display={'flex'}
        flexWrap={'wrap'}
      >
        <div className={styles.text}>
          <span className={styles.grey}>Description</span>&nbsp;
          <DIDNumbersRowDescription
            number={number}
            description={number.alias}
            fetchNumbers={fetchNumbers}
            open={descriptionEditOpen}
            setOpen={setDescriptionEditOpen}
          />
        </div>

        <div className={styles.text}>
          <span className={styles.grey}>Cost</span>&nbsp;${rentFee} / mo
        </div>

        <div className={styles.text}>
          <span
            className={styles.grey}
          >Expire at</span>&nbsp;{moment(number.until * 1000).format('DD.MM.YYYY (HH:mm:ss)')}
        </div>
      </Box>

      {deleteOpen && <DIDDeleteModal
        number={number}
        onClose={() => setDeleteOpen(false)}
        onDelete={onDelete}
      />}

      {renewOpen && <DIDRenewModal
        number={number}
        onClose={() => setRenewOpen(false)}
        fetchNumbers={fetchNumbers}
      />}
    </Box>
  )
}