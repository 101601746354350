import styles from './DIDCallLogs.module.scss';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Box} from '@mui/material';
import useRequest from 'hooks/useRequest';
import Pagination from 'components/Pagination/Pagination';
import {
  DIDCallLogsTableDesktop
} from 'pages/DID/Tabs/DIDCallLogs/components/DIDCallLogsTableDesktop/DIDCallLogsTableDesktop';
import {
  DIDCallLogsTableMobile
} from 'pages/DID/Tabs/DIDCallLogs/components/DIDCallLogsTableMobile/DIDCallLogsTableMobile';

const DIDCallLogs = () => {
  const {getDataWithCallback} = useRequest();
  const {isMobile} = useBreakpoints();
  const [logs, setLogs] = useState();
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    window.scrollTo({behavior: 'smooth', top: 0});
  }, [page, perPage]);

  const fetch = useCallback(() => {
    return getDataWithCallback('/api/calls/incoming', undefined, (data) => {
      setLogs(data);
    });
  }, [getDataWithCallback]);

  useEffect(() => {
    void fetch();
  }, [fetch]);

  const itemsToRender = useMemo(() => {
    return logs?.slice(page * perPage - perPage, page * perPage);
  }, [page, perPage, logs]);

  return <div className={styles.container}>

    {logs?.length === 0 && (
      <Box pb={3} className={styles.text}>
        You have no inbound call logs
      </Box>
    )}

    {logs?.length > 0 &&
      <>
        <div>
          {isMobile ? <DIDCallLogsTableMobile items={itemsToRender}/> :
            <DIDCallLogsTableDesktop items={itemsToRender}/>}
        </div>

        <div className={styles.paginationContainer}>
          <Pagination
            page={page}
            perPage={perPage}
            onPageChange={setPage}
            onPerPageChange={setPerPage}
            itemsCount={logs.length}
          />
        </div>
      </>
    }
  </div>
}

export default DIDCallLogs