import styles from './PhoneInput.module.scss';
import {Input} from '../../../../components/Input/Input';
import classNames from 'classnames';
import {formatElapsed} from '../../../../utils/time';

const PhoneInput = ({
                      onChange,
                      disabled,
                      value,
                      rate,
                      callStatus,
                      elapsed,
                    }) => {
  const onInputFocus = () => {
    if (!value) {
      onChange('+');
    }
  };

  const getCallStatusText = (status) => {
    switch (status) {
      case 'Confirmed':
      case 'Calling':
        return 'Calling...';
      case 'Incoming call':
        return 'Incoming call...';
      default:
        return status;
    }
  };

  return (
    <div
      className={classNames(
        styles.container,
        rate && !callStatus && styles.containerActive,
        rate || elapsed || callStatus
          ? styles.withSubtitle
          : styles.withoutSubtitle
      )}
    >
      <Input
        type={'tel'}
        onFocus={onInputFocus}
        placeholder={'Enter phone number'}
        onChange={onChange}
        disabled={disabled}
        value={value}
      />
      <div
        className={classNames(
          styles.info,
          rate || elapsed || callStatus
            ? styles.infoActive
            : styles.infoInActive
        )}
      >
        <div style={{height: 24}}>
          {!callStatus && rate && (
            <span className={styles.rate}>${rate} / min</span>
          )}

          {!!elapsed && (
            <span className={styles.elapsed}>{formatElapsed(elapsed)}</span>
          )}

          {!elapsed && callStatus && (
            <span
              className={classNames(
                styles.callStatus,
                callStatus === 'No answer' ? styles.noAnswer : ''
              )}
            >
              {getCallStatusText(callStatus)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhoneInput;
