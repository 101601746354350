import {ReactComponent as InfoIcon} from './infoIcon.svg';
import styles from './InfoBadge.module.scss';

const InfoBadge = ({text, width}) => {
  return <div className={styles.container} style={{width: width ? width : 'initial'}}>
    <div className={styles.icon}><InfoIcon/></div>
    <div className={styles.text}>{text}</div>
  </div>
}

export default InfoBadge