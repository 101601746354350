import {CloseIcon} from '../../../../assets';
import commonStyles from '../../ModalsRoot.module.scss';
import {Button} from '../../../Buttons';
import {Box} from '@mui/material';
import {useNavigate} from 'react-router-dom';

const VoiceBasic = ({onClose}) => {
  const navigate = useNavigate();

  const text = 'Voice changer is only available\non the';

  return (
    <div
      style={{paddingLeft: 24, paddingRight: 24}}
      className={commonStyles.defaultModal}
    >
      <div
        onClick={onClose}
        className={commonStyles.closeButtonContainer}
      >
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <h4 className={commonStyles.heading}>Attention</h4>

      <Box
        maxWidth={'320px'}
        mt={1}
        mb={2}
      >
        <span className={commonStyles.text}>
          {text}
          <span className={commonStyles.yellowText}>
            &nbsp;Premium&nbsp;plan
          </span>
        </span>
      </Box>

      <Button
        onClick={() => {
          navigate('/change-plan');
          onClose();
        }}
        style={{
          paddingLeft: 27,
          paddingRight: 27,
          margin: '0 auto',
          width: 194,
        }}
      >
        Go Premium
      </Button>
    </div>
  );
};

export default VoiceBasic;
