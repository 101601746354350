import React, {useEffect, useRef, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import SendLink from './SendLink';
import {CopyToClipboard} from 'react-copy-to-clipboard/lib/Component';
import {appStore} from '../../../../stores/AppStore';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import {observer} from 'mobx-react';
import {Button} from '../../../../components/Buttons';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 325,
  },
  text: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    backgroundColor: 'transparent',
    borderRadius: 10,
    padding: '20px 24px',
  },
  shareText: {
    color: 'grey',
  },
  socialIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
}));

const useStylesDesktop = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 566,
    background: 'linear-gradient(180deg, #181F27 0%, #0E151D 100%)',
    padding: '48px 40px',
    borderRadius: 20,
    boxSizing: 'border-box',
  },
  text: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    backgroundColor: 'transparent',
    borderRadius: 10,
    padding: '20px 24px',
  },
  shareText: {
    color: 'grey',
  },
  socialIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
}));

function InviteFriendsPage({showDesktopLayout}) {
  const {isMobile} = useBreakpoints();

  const mobileClasses = useStyles();
  const desktopClasses = useStylesDesktop();
  const classes = isMobile ? mobileClasses : desktopClasses;

  const {isLg} = useBreakpoints();
  const [id, setId] = useState();
  const [copied, setCopied] = useState(false);
  const timer = useRef();
  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  function onCopy() {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setCopied(true);
    timer.current = setTimeout(() => setCopied(false), 2000);
  }

  useEffect(() => {
    if (appStore.currentUser?.inviteID) {
      setId(appStore.currentUser.inviteID);
    }
    // eslint-disable-next-line
  }, [appStore.currentUser]);

  const text =
    'dCalls is a Web3 App for making calls, renting numbers, and buying eSIMs from your browser. Connect via a crypto wallet and pay with cryptocurrency. Earn by inviting friends.\n' +
    '\n' +
    'Join: ';

  const link = window.location.host === 'app.dcalls.org' ? `https://dcalls.org?invite=${id}` : `https://test-promo.dcalls.org?invite=${id}`;

  return (
    <Box mx={showDesktopLayout ? 0 : 'auto'} className={classes.container}>
      <SendLink link={link}/>

      <Box mb={isLg ? 1.5 : 1.25} mr="auto">
        <Typography
          style={
            isMobile
              ? {
                fontWeight: 600,
                fontSize: '18px',
                lineHeight: '27px',
              }
              : {
                fontWeight: 600,
                fontSize: '24px',
                lineHeight: '36px',
              }
          }
        >
          Write post
        </Typography>
      </Box>

      <Box border="1px solid #313942" borderRadius="10px" width={'100%'}>
        <Box className={classes.text}>
          <Typography
            style={
              !isMobile
                ? {
                  fontSize: 16,
                  lineHeight: '24px',
                }
                : {
                  fontSize: '14px',
                  lineHeight: '21px',
                }
            }
          >
            {text} {link}
          </Typography>
        </Box>
      </Box>
      <Box width={'100%'} display={'flex'} mt={3}>
        <Box width={'100%'} maxWidth={isMobile ? 'auto' : '240px'}>
          <CopyToClipboard onCopy={onCopy} text={text + '\n' + link}>
            <Button style={{width: '100%'}}>
              {copied ? (
                <>
                  <Box display={'flex'} alignItems={'center'} mr={1}>
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.4403 9.93997C22.7221 9.67175 23.097 9.52347 23.486 9.52641C23.8749 9.52935 24.2476 9.68329 24.5252 9.95575C24.8029 10.2282 24.9638 10.5979 24.9741 10.9867C24.9844 11.3756 24.8432 11.7532 24.5803 12.04L16.6003 22.02C16.4631 22.1678 16.2975 22.2864 16.1134 22.3687C15.9293 22.451 15.7305 22.4954 15.5288 22.4991C15.3272 22.5029 15.1269 22.4659 14.9398 22.3904C14.7528 22.315 14.5829 22.2026 14.4403 22.06L9.14832 16.768C9.00094 16.6306 8.88274 16.465 8.80075 16.281C8.71877 16.0971 8.67469 15.8984 8.67113 15.697C8.66758 15.4956 8.70463 15.2956 8.78007 15.1088C8.85551 14.922 8.9678 14.7523 9.11024 14.6099C9.25268 14.4675 9.42234 14.3552 9.60912 14.2797C9.7959 14.2043 9.99595 14.1672 10.1974 14.1708C10.3988 14.1743 10.5974 14.2184 10.7814 14.3004C10.9654 14.3824 11.131 14.5006 11.2683 14.648L15.4563 18.834L22.4023 9.98397C22.4148 9.96858 22.4282 9.95389 22.4423 9.93997H22.4403Z"
                        fill="#0E151D"
                      />
                    </svg>
                    Copied!
                  </Box>
                </>
              ) : (
                'Copy'
              )}
            </Button>
          </CopyToClipboard>
        </Box>
      </Box>
    </Box>
  );
}

export default observer(InviteFriendsPage);
