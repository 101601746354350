import {CloseIcon} from '../../../../assets';
import commonStyles from '../../ModalsRoot.module.scss';
import {Box} from '@mui/material';
import styles from './DeleteESIMNotification.module.scss';
import classNames from 'classnames';
import useBreakpoints from '../../../../hooks/useBreakpoints';

const DeleteESIMNotification = ({onClose}) => {
  const {isMobile} = useBreakpoints();
  return (
    <div
      style={{paddingLeft: 24, paddingRight: 24}}
      className={commonStyles.defaultModal}
    >
      <div onClick={onClose} className={commonStyles.closeButtonContainer}>
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <h4 className={classNames(commonStyles.heading, styles.headingYellow)}>
        Notification
      </h4>

      <Box maxWidth={isMobile ? '320px' : 'auto'} mt={1}>
        <span className={commonStyles.text}
              style={{textAlign: 'center', whiteSpace: isMobile ? 'pre-wrap' : 'nowrap'}}>
          {'Your eSIM has been permanently deleted.'}
        </span>
      </Box>
    </div>
  );
};

export default DeleteESIMNotification;
