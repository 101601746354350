import {CloseIcon} from '../../../../assets';
import commonStyles from '../../ModalsRoot.module.scss';
import {Box} from '@mui/material';
import styles from './DefaultNotification.module.scss';
import classNames from 'classnames';

const DefaultNotification = ({onClose, data}) => {
  return (
    <div
      style={{paddingLeft: 24, paddingRight: 24}}
      className={commonStyles.defaultModal}
    >
      <div onClick={onClose} className={commonStyles.closeButtonContainer}>
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <h4 className={classNames(commonStyles.heading, styles.headingYellow)}>
        {data.title}
      </h4>

      <Box mt={1}>
        <span className={styles.text}>
          {data.text}
        </span>
      </Box>
    </div>
  );
};

export default DefaultNotification;
