import {CloseIcon} from '../../../../assets'
import commonStyles from '../../ModalsRoot.module.scss'
import {Button} from '../../../Buttons'
import {Box} from '@mui/material'
import useRequest from '../../../../hooks/useRequest'
import EventEmitter from '../../../../utils/events/EventEmitter'
import {MODAL_EVENTS, MODAL_TYPES} from '../../const'
import styles from './ActivationOfPremium.module.scss'
import {loadCurrentUser} from '../../../../lib/auth'
import {getTokenValue} from '../../../../utils/LocalStorage'

const ActivationOfPremium = ({onClose, amount}) => {
  const {postDataWithCallback} = useRequest()

  const onPremium = () => {
    const body = {}
    switch (amount) {
      case 5:
        body.type = 'day'
        break
      case 30:
        body.type = 'month'
        break
      case 180:
        body.type = 'year'
        break
      default:
        return
    }

    postDataWithCallback('/api/user/vip', body, async () => {
      EventEmitter.emit(MODAL_EVENTS.openModal, MODAL_TYPES.activationOfPremiumCongratulations, amount)
      await loadCurrentUser(getTokenValue())
    })
  }

  const getPackage = (amount) => {
    switch (amount) {
      case 5:
        return '1 day'
      case 30:
        return '1 month'
      case 180:
        return '1 year'
      default:
        return ''
    }
  }

  return <div className={commonStyles.defaultModal}>
    <div onClick={onClose} className={commonStyles.closeButtonContainer}>
      <CloseIcon className={commonStyles.closeButton}/>
    </div>

    <h4 className={commonStyles.heading}>Activation of Premium</h4>

    <Box mt={2} mb={2} className={styles.premiumContainer}>
      <div>
        <span>Package:</span>
        <span>{getPackage(amount)}</span>
      </div>
      <div>
        <span>Price:</span>
        <span>${amount}</span>
      </div>
    </Box>

    <Box display="flex" gap="16px">
      <Button
        colorScheme="dark"
        onClick={onClose}
        style={{paddingLeft: 27, paddingRight: 27, margin: '0 auto'}}
      >
        Cancel
      </Button>
      <Button
        onClick={onPremium}
        style={{paddingLeft: 27, paddingRight: 27, margin: '0 auto'}}
      >
        Accept
      </Button>
    </Box>
  </div>
}

export default ActivationOfPremium