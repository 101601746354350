import classNames from 'classnames';
import styles from './InputWithFrame.module.scss';
import {Input} from '../Input/Input';
import {useRef, useState} from 'react';

const InputWithFrame = ({onChange, ...props}) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef();

  const onContainerClick = () => {
    inputRef.current.focus();
  };

  return (
    <div
      onClick={onContainerClick}
      className={classNames(
        styles.container,
        focused && styles.containerActive
      )}
    >
      <Input
        ref={inputRef}
        {...props}
        onFocus={() => {
          setFocused(true);
          if (props.onFocus) {
            props.onFocus();
          }
        }}
        onBlur={() => setFocused(false)}
        onChange={onChange}
        value={props.value}
      />
    </div>
  );
};

export default InputWithFrame;
