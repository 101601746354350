import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {Box, ClickAwayListener, Fade, Popper, Typography} from '@mui/material';
import {observer} from 'mobx-react';
import styles from './DIDOrder.module.scss';
import useRequest from '../../../../../../hooks/useRequest';
import useBreakpoints from '../../../../../../hooks/useBreakpoints';
import {appStore} from '../../../../../../stores/AppStore';
import EventEmitter from '../../../../../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from '../../../../../../components/Modals/const';
import {loadCurrentUser} from '../../../../../../lib/auth';
import {getTokenValue} from '../../../../../../utils/LocalStorage';
import {HeaderBackIcon} from '../../../../../../assets';
import BoxWithHeader from '../../../../../../components/Misc/BoxWithHeader/BoxWithHeader';
import {Button} from '../../../../../../components/Buttons';
import {theme} from '../../../../../../theme';
import {getFlagEmoji} from '../../../../../../utils/getFlagEmoji';
import classNames from 'classnames';
import {DIDListCard} from '../DIDListCard/DIDListCard';
import {monthOptions} from 'utils/env';

const DIDOrder = ({number, selectedCountry, selectedRegion, selectedCity, type, isPremium, onBack}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [el, setEl] = useState();
  const {postDataWithCallback} = useRequest();
  const {isMobile} = useBreakpoints();
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(monthOptions[0]);

  const setupFee = isPremium ? parseFloat(number.setupVipPrice) : parseFloat(number.setupPrice);
  const rentFee = isPremium ? parseFloat(number.monthlyVipPrice) : parseFloat(number.monthlyPrice);
  const subTotal = rentFee * month;
  const total = (setupFee + subTotal).toFixed(2);

  const buy = async (event) => {
    if (appStore.currentUser.centsBalance < total * 100 || loading) {
      setEl(event.target);
      setOpen(true);
      return
    }

    setLoading(true);

    // const publicKey = await getSetPublicKey();

    const body = {
      numberId: number.id,
      countryName: selectedCountry.attributes.name,
      regionName: selectedRegion?.attributes.name,
      cityName: selectedCity.attributes.area_name,
      sms: type === 'voicesms',
      countryISO: selectedCountry.attributes.iso,
      // publicKey,
      month,
    }

    postDataWithCallback('/api/did/buy', body, () => {
      EventEmitter.emit(
        MODAL_EVENTS.openModal,
        MODAL_TYPES.buyDIDCongratulations
      );

      void loadCurrentUser(getTokenValue());
      setLoading(false);
      navigate('/did/my-numbers');
    })
  }

  const onTopUpClick = async () => {
    setOpen(false);
    navigate('/top-up-balance');
  };

  const infoLocation =
    <>
      {getFlagEmoji(selectedCountry.attributes.iso)}&nbsp;{selectedCountry.attributes.name}
      {selectedRegion && ', ' + selectedRegion.attributes.name}
      {selectedCity && ', ' + selectedCity.attributes.area_name}
    </>

  return <div className={styles.container}>
    <div onClick={onBack} className={styles.title}>
      <HeaderBackIcon/><Box>Your order:</Box>
    </div>

    {isMobile && (
      <DIDListCard
        title={<Box display={'flex'} flexDirection={'column'}>
          <Box>+{number.number}</Box>
          <Box className={styles.mobileSubtitle}>{infoLocation}</Box>
        </Box>
        }
        type={type}
        setupFee={isPremium ? parseFloat(number.setupVipPrice) : parseFloat(number.setupPrice)}
        rentFee={isPremium ? parseFloat(number.monthlyVipPrice) : parseFloat(number.monthlyPrice)}
        subTotal={subTotal}
      />
    )}

    {!isMobile && (
      <BoxWithHeader headerText={
        <Box py={0.5} display={'flex'} justifyContent={'space-between'}>
          <Box className={styles.infoTitleDesktop} width={'250px'}>Country / City</Box>
          <Box className={styles.infoTitleDesktop} width={'150px'}>DID number</Box>
          <Box className={styles.infoTitleDesktop} width={'100px'}>Type number</Box>
          <Box className={styles.infoTitleDesktop} width={'88px'}>Setup fee</Box>
          <Box className={styles.infoTitleDesktop} width={'88px'}>Monthly</Box>
          <Box className={classNames(styles.infoTitleDesktop, styles.yellow)} width={'88px'}>Subtotal</Box>
        </Box>
      }>
        <Box className={styles.row} display={'flex'} justifyContent={'space-between'}>
          <Box width={'250px'}>{infoLocation}</Box>
          <Box width={'150px'}>+{number.number}</Box>
          <Box width={'100px'} className={styles.tableRowType}>
            {
              type === 'voice' ? <>Voice</> :
                <>Voice + SMS</>
            }
          </Box>

          <Box width={'88px'}>${setupFee}</Box>

          <Box width={'88px'}>${rentFee}</Box>

          <Box width={'88px'}>${subTotal}</Box>
        </Box>
      </BoxWithHeader>
    )}

    <Box
      mt={5}
      display={'flex'}
      alignItems={isMobile ? 'center' : 'flex-start'}
      flexDirection={'column'}
    >
      <div className={styles.periodTitle}>Select period (in months):</div>
      <Box mt={1.5} display={'flex'} width={'100%'} justifyContent={isMobile ? 'center' : 'flex-start'}>
        {monthOptions.map((option) => (
          <Box key={option} className={classNames(styles.period, month === option ? styles.periodActive : '')}
               onClick={() => setMonth(option)}>{option}</Box>
        ))}
      </Box>
    </Box>

    <Box className={styles.total}>
      Total:<span>${total}</span>
    </Box>

    <Button
      style={{width: 200, margin: isMobile ? '0 auto 24px auto' : '0 0 24px 0'}}
      onClick={buy}
      disabled={loading}
    >
      Pay
    </Button>

    <Typography
      variant={'subtitle2'}
      color={theme.palette.primary.dark}
      align={isMobile ? 'center' : 'left'}
      whiteSpace={'pre-wrap'}
    >
      {'By clicking the Pay button, money will be debited from your balance.'}
    </Typography>


    <Popper open={open} anchorEl={el} placement={'top-start'} transition>
      {({TransitionProps}) => (
        <Fade {...TransitionProps} timeout={350}>
          <div
            className={styles.notEnoughFundsContainer}
            onMouseLeave={() => {
              setOpen(false);
            }}
          >
            <ClickAwayListener
              onClickAway={() => (open ? setOpen(false) : undefined)}
            >
                <span>
                  {
                    'Oops, you don\'t have enough money on your balance to buy esim.'
                  }
                  <span onClick={onTopUpClick} className={styles.highlighted}>
                    Please, top-up balance.
                  </span>
                </span>
            </ClickAwayListener>
          </div>
        </Fade>
      )}
    </Popper>
  </div>
}

export default observer(DIDOrder)