import classNames from 'classnames';
import styles from './Button.module.scss';

const Button = ({
  colorScheme = 'light',
  fontSize = 'medium',
  size = 'md',
  children,
  fakeDisabled,
  ...props
}) => {
  return (
    <button
      className={classNames(
        styles.button,
        styles[size],
        styles[colorScheme],
        styles[fontSize],
        fakeDisabled && styles.fakeDisabled
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
