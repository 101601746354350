import styles from './Input.module.scss';
import {forwardRef} from 'react';
import classNames from 'classnames';
import useBreakpoints from '../../hooks/useBreakpoints';

export const Input = forwardRef(({prefix, textarea, ...props}, ref) => {
  const {isMobile} = useBreakpoints();

  const inputOnChange = (event) => {
    let val = event.target.value;
    if (prefix) {
      val = event.target.value.split(prefix)[1] || event.target.value;
    }
    props.onChange(val);
  };
  const val = (prefix || '') + props.value;

  if (textarea) {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: isMobile ? '300px' : 'auto',
        }}
      >
        <span
          className={styles.input}
          style={{
            opacity: 0,
            width: '100%',
            whiteSpace: 'pre-line',
            maxWidth: '100%',
            display: 'block',
            minHeight: '27px',
            textAlign: props.textAlign || 'left',
            wordBreak: 'break-all',
          }}
        >
          {val}
        </span>
        <textarea
          ref={ref}
          {...props}
          className={classNames(styles.input, styles.textarea)}
          onChange={inputOnChange}
          value={val === '$0' ? '' : val}
        />
      </div>
    );
  }

  return (
    <input
      ref={ref}
      {...props}
      className={styles.input}
      onChange={inputOnChange}
      value={val === '$0' ? '' : val}
    />
  );
});
