import styles from './TransactionHistory.module.scss';
import useHeaderSettings from '../../hooks/useHeaderSettings';
import {useEffect, useMemo, useState} from 'react';
import useRequest from '../../hooks/useRequest';
import {appStore} from 'stores/AppStore';
import {Navigate} from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination';
import moment from 'moment';
import classNames from 'classnames';
import useBreakpoints from '../../hooks/useBreakpoints';
import {ArrowRightButton} from '../../assets';
import {openUrl} from 'utils/url';
import {monthOptions} from 'utils/env';

const TransactionHistory = () => {
  const {isMobile} = useBreakpoints();
  useHeaderSettings({title: 'Transactions'});
  const {getDataWithCallback} = useRequest();
  const [transactions, setTransActions] = useState();
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    void getDataWithCallback('api/pay/transactions', undefined, (data) => {
      setTransActions(data);
    });
  }, [getDataWithCallback]);

  const transactionsToRender = useMemo(() => {
    return transactions?.slice(page * perPage - perPage, page * perPage);
  }, [page, perPage, transactions]);

  const getTypeText = (transaction) => {
    const purchase = monthOptions.map(option => 'number_' + option);
    const renew = monthOptions.map(option => 'number_up_' + option);
    const isDidPurchaseOrRenew = [...purchase, ...renew].includes(transaction.type);

    if (isDidPurchaseOrRenew) {
      return <>
        Renting a virtual number&nbsp;
        {transaction.number && <span>(+{transaction.number})</span>}
      </>
    }

    switch (transaction.type) {
      case 'day':
        return (
          <>
            Premium Activation&nbsp;
            <span>(${transaction.localAmount}/day)</span>
          </>
        );
      case 'month':
        return (
          <>
            Premium Activation&nbsp;
            <span>(${transaction.localAmount}/month)</span>
          </>
        );
      case 'year':
        return (
          <>
            Premium Activation&nbsp;
            <span>(${transaction.localAmount}/year)</span>
          </>
        );
      case 'add':
        return (
          <>
            Top-up&nbsp;
            <span>
              ({parseFloat(transaction.cryptoAmount)}&nbsp;
              {transaction.cryptoCurrency})
            </span>
          </>
        );
      case 'earn':
        return (
          <>
            Top-up&nbsp;
            <span>(from Earn)</span>
          </>
        );
      case 'esim':
        return <>
          Purchased eSIM&nbsp;
          {transaction.esim && <span>({transaction.esim})</span>}
        </>
      default:
        return transaction.type;
    }
  };

  useEffect(() => {
    window.scrollTo({behavior: 'smooth', top: 0});
  }, [page, perPage]);

  if (appStore?.authorized === false) {
    return <Navigate to="/"/>;
  }

  if (transactions === undefined) return null;

  if (!transactions || transactions?.length === 0) {
    return (
      <div className={styles.container}>
        <span className={classNames(styles.smallText, styles.center)}>
          There is nothing here yet
        </span>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
        <tr>
          {isMobile ? (
            <>
              <th>Date/time</th>
              <th>Amount</th>
            </>
          ) : (
            <>
              <th>Date/time</th>
              <th>Amount</th>
              <th>Desciption</th>
            </>
          )}
        </tr>
        </thead>

        <tbody>
        {transactionsToRender.map((transaction) => {
          return (
            <tr key={transaction.date}>
              <td>
                <div>
                  {isMobile && (
                    <span className={styles.text}>
                        {getTypeText(transaction)}
                      </span>
                  )}

                  <span className={isMobile ? styles.smallText : styles.text}>
                      {moment(transaction.date * 1000).format(
                        'DD.MM.YYYY, HH:mm'
                      )}
                    </span>
                </div>
              </td>
              <td>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  {transaction.type === 'add' ||
                  transaction.type === 'earn' ? (
                    <span
                      className={classNames(
                        styles.amount,
                        styles.amountPositive
                      )}
                    >
                        +${parseFloat(transaction.localAmount)}
                      </span>
                  ) : (
                    <span
                      className={classNames(
                        styles.amount,
                        styles.amountNegative
                      )}
                    >
                        -${parseFloat(transaction.localAmount)}
                      </span>
                  )}

                  {isMobile && transaction.transaction_url && (
                    <ArrowRightButton
                      style={{
                        cursor: 'pointer',
                        marginLeft: '12px',
                        height: 21,
                        width: 21,
                      }}
                      onClick={() => openUrl(transaction.transaction_url)}
                    />
                  )}
                </div>
              </td>

              {!isMobile && (
                <td>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                      <span className={styles.text}>
                        {getTypeText(transaction)}
                      </span>

                    {transaction.transaction_url && (
                      <ArrowRightButton
                        style={{cursor: 'pointer', marginLeft: '16px'}}
                        onClick={() => openUrl(transaction.transaction_url)}
                      />
                    )}
                  </div>
                </td>
              )}
            </tr>
          );
        })}
        </tbody>
      </table>

      <Pagination
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
        itemsCount={transactions.length}
      />
    </div>
  );
};

export default TransactionHistory;
