import styles from './ESIMSelectPlans.module.scss';
import {PlanetIcon} from '../../../../../assets';
import classNames from 'classnames';
import {Box} from '@mui/material';
import useBreakpoints from '../../../../../hooks/useBreakpoints';
import variables from '../../../../../assets/styles/_export.scss';

const ESIMSelectPlans = ({plans, onSelect, selectedPlan}) => {
  const {isMobile} = useBreakpoints();
  if (!plans || plans.length === 0) return null;

  const renderPlan = plan => {
    const selected = plan.slug === selectedPlan?.slug;

    return (
      <div key={plan.slug} onClick={() => onSelect(plan)}
           className={classNames(styles.badge, {[styles.badgeActive]: selected})}>
        <Box height={'24px'} mr={1}>
          <PlanetIcon/>
        </Box>
        {plan.capacity} GB for {plan.period} days
        <Box ml={'auto'} color={variables.yellow}>${plan.price}</Box>
      </div>
    )
  }
  return <div className={styles.container}>
    <div className={styles.title}>{'Select plan:'}</div>

    {isMobile ? plans.map(renderPlan) : <>
      <Box display={'grid'} gap={2} rowGap={'12px'} gridTemplateColumns={'1fr 1fr 1fr'}>{plans.map(renderPlan)}</Box>
    </>}
  </div>
}

export default ESIMSelectPlans;
