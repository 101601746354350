import styles from './CopyWithLabel.module.scss';
import {Box, Fade, Popper} from '@mui/material';
import {CopyToClipboard} from 'react-copy-to-clipboard/lib/Component';
import {CopyIcon, TickIcon} from '../../../assets';
import classNames from 'classnames';
import {useRef, useState} from 'react';
import {TextMarquee} from '../../TextMarquee/TextMarquee';

const CopyWithLabel = ({title, text}) => {
  const [active, setActive] = useState(false);
  const ref = useRef();

  const onCopy = (r) => {
    const el = r.current
    if (el) {
      el.className.baseVal = classNames(styles.copyIcon, styles.active);
    }
    setActive(el)
    setTimeout(() => {
      el.className.baseVal = styles.copyIcon;
      setActive(null);
    }, 1500);
  };

  return <div className={styles.addressContent}>
    <Box>
      <span className={styles.label}>{title}</span>
      <CopyToClipboard onCopy={() => onCopy(ref)} text={text}>
        <div className={styles.field}>
          <TextMarquee child={<div className={styles.text}>{text}</div>}/>

          <CopyIcon
            ref={ref}
            className={styles.copyIcon}
          />
        </div>
      </CopyToClipboard>
    </Box>

    <Popper
      open={!!active}
      anchorEl={active}
      placement={'bottom-end'}
      transition
    >
      {({TransitionProps}) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box className={styles.copiedContainer}>
            <TickIcon/>
            &nbsp;<span className={styles.copied}>Copied!</span>
          </Box>
        </Fade>
      )}
    </Popper>
  </div>
}

export default CopyWithLabel;