import styles from './CallStatusBadge.module.scss';
import {useMemo} from 'react';
import {AnsweredIcon, CanceledIcon, NoAnswerIcon} from '../../../../assets';

const CallStatusBadge = ({callStatus}) => {
  const title = useMemo(() => {
    switch (callStatus) {
      case 'Busy':
      case 'No answer': {
        return 'No Answer';
      }
      case 'Answered': {
        return 'Answer';
      }
      default:
        return callStatus;
    }
  }, [callStatus]);

  const icon = useMemo(() => {
    switch (callStatus) {
      case 'Busy':
      case 'No answer': {
        return <NoAnswerIcon/>;
      }
      case 'Answered': {
        return <AnsweredIcon/>;
      }
      default:
        return <CanceledIcon/>;
    }
  }, [callStatus]);

  return (
    <div className={styles.container}>
      {icon}
      <span className={styles.title}>{title}</span>
    </div>
  );
};

export default CallStatusBadge;
