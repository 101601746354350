import styles from './PremiumPlan.module.scss';
import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';
import {Box, ClickAwayListener, Fade, Popper, styled, Switch,} from '@mui/material';
import {appStore} from '../../../../stores/AppStore';
import moment from 'moment';
import useRequest from '../../../../hooks/useRequest';
import {loadCurrentUser} from '../../../../lib/auth';
import {getTokenValue} from '../../../../utils/LocalStorage';
import {PremiumPlanIcon} from '../../../../assets';
import {useNavigate} from 'react-router-dom';

const CustomSwitch = styled((props) => <Switch disableRipple {...props} />)(
  () => ({
    height: 14,
    width: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      margin: 0,
      padding: 0,

      '&.Mui-checked': {
        transform: 'translateX(10px)',
        color: '#0FD05C',
        '& .MuiSwitch-thumb': {
          color: '#0FD05C',
        },
        '& + .MuiSwitch-track': {
          backgroundColor: '#0FD05C',
        },
        '&.Mui-disabled + .MuiSwitch-track': {},
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#4A5663',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        color: '#4A5663',
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 14,
      height: 14,
      margin: 0,
      right: 0,
      color: '#4A5663',
    },
    '& .MuiSwitch-track': {
      height: 10,
      opacity: 0.38,
      borderRadius: 20 / 2,
      marginTop: 2,
      backgroundColor: '#4A5663',
    },
  })
);

const PremiumPlan = ({active, onClick, isPremiumPromo}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const {postDataWithCallback} = useRequest();
  const [el, setEl] = useState(null);
  const [timeLeft, setTimeLeft] = useState();
  const timer = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    tick();
    return () => {
      clearTimeout(timer.current);
    };
  });

  const doubleDigitsTime = (number) => {
    if (number < 10 && number > 0) {
      return '0' + number;
    }
    return number;
  };

  const tick = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    const diff = moment(appStore.currentUser?.vipUntil * 1000).diff(moment());
    if (diff < 0) {
      setTimeLeft(null);
      return;
    }
    const duration = moment.duration(diff);
    const h = doubleDigitsTime(duration.hours());
    const m = doubleDigitsTime(duration.minutes());
    const s = doubleDigitsTime(duration.seconds());
    setTimeLeft(appStore.currentUser?.vipUntil ? `${h}:${m}:${s}` : null);
    timer.current = setTimeout(() => tick(), 1000);
  };

  const onPlanPriceClick = (e, price) => {
    setEl(e.target);
    if (price * 100 > appStore.currentUser.centsBalance) {
      setOpen(true);
    } else {
      onClick(price);
    }
  };

  const onTopUpClick = async () => {
    setOpen(false);
    navigate('/top-up-balance');
  };

  const getPrice = () => {
    switch (appStore.currentUser?.vipType) {
      case 'day':
        return '$5/day';
      case 'month':
        return '$30/mo';
      case 'year':
        return '$180/year';
      default:
        return '';
    }
  };

  const switchOnClick = (event) => {
    event.stopPropagation();
    postDataWithCallback(
      '/api/user/vip/update',
      {update: !appStore.currentUser?.vipUpdate},
      () => {
        void loadCurrentUser(getTokenValue());
      }
    );
  };

  const renderPrice = () => {
    return (
      <div className={styles.priceContainerContent}>
        <span className={styles.priceContainerContentTitle}>Price:</span>
        <span className={styles.priceContainerContentText}>{getPrice()}</span>
      </div>
    );
  };

  const renderValidUntil = () => {
    return (
      <div className={styles.priceContainerContent}>
        <span className={styles.priceContainerContentTitle}>Valid until:</span>
        <Box
          flexDirection={'row'}
          display={'flex'}
        >
          <span className={styles.priceContainerContentText}>
            {moment(appStore.currentUser?.vipUntil * 1000).format('DD.MM.YYYY')}

            {!isPremiumPromo && (
              <div
                className={styles.autoContainer}
                onClick={switchOnClick}
              >
                <CustomSwitch checked={appStore.currentUser?.vipUpdate}/>
                <span>Auto renewal</span>
              </div>
            )}
          </span>

          {isPremiumPromo && appStore.currentUser?.vipUntil && timeLeft && (
            <span className={styles.timer}>({timeLeft})</span>
          )}
        </Box>
      </div>
    );
  };

  // TODO: move to separate component
  const renderInActive = (showDesktop) => {
    if (!showDesktop) return null;
    return (
      <div className={styles.buttons}>
        <div
          onClick={(e) => onPlanPriceClick(e, 5)}
          className={classNames(
            styles.button,
            appStore.currentUser?.centsBalance < 500 && styles.buttonDisabled
          )}
          onMouseEnter={(e) => {
            if (appStore.currentUser?.centsBalance < 500) {
              setEl(e.target);
              setOpen(true);
            }
          }}
        >
          $5/day
        </div>
        <div
          onClick={(e) => onPlanPriceClick(e, 30)}
          className={classNames(
            styles.button,
            appStore.currentUser?.centsBalance < 3000 && styles.buttonDisabled
          )}
          onMouseEnter={(e) => {
            if (appStore.currentUser?.centsBalance < 3000) {
              setEl(e.target);
              setOpen(true);
            }
          }}
        >
          $30/mo
        </div>
        <div
          onClick={(e) => onPlanPriceClick(e, 180)}
          className={classNames(
            styles.button,
            appStore.currentUser?.centsBalance < 18000 && styles.buttonDisabled
          )}
          onMouseEnter={(e) => {
            if (appStore.currentUser?.centsBalance < 18000) {
              setEl(e.target);
              setOpen(true);
            }
          }}
        >
          $180/year
        </div>
      </div>
    );
  };

  return (
    <div
      className={classNames(styles.container, active && styles.containerActive)}
      ref={ref}
    >
      {isPremiumPromo && (
        <div className={styles.promoContainer}>
          <div className={styles.promoBanner}>
            <span>PROMO</span>
          </div>
        </div>
      )}

      <div
        className={classNames(
          styles.header,
          !active ? styles.withDesktopButtons : ''
        )}
      >
        <div className={styles.headerIcon}>
          <PremiumPlanIcon/>
        </div>

        <div className={styles.headerContent}>
          <div className={styles.headerContentRow}>
            <Box
              display={'flex'}
              flexDirection={'column'}
            >
              <Box
                display={'flex'}
                flexDirection={'row'}
              >
                <div className={classNames(styles.priceContainerContent)}>
              <span className={styles.headerTitle}>
                {active ? 'Current plan:' : 'Plan:'}
              </span>
                  <span className={styles.headerPlan}>Premium</span>
                </div>

                {active && (
                  <>
                    {!isPremiumPromo && renderPrice()}
                    {renderValidUntil()}
                  </>
                )}
              </Box>

              {(isPremiumPromo || !active) && renderInActive(true)}
            </Box>
          </div>

          <div className={styles.headerContentRow}>
            <div className={classNames(styles.footer, active && styles.footerActive)}>
              <div>
                Billing:<strong>per second</strong>
              </div>
              <div className={styles.divider}>|</div>
              <div>
                Discount on calls:<strong>20%</strong>
              </div>
              <div className={styles.divider}>|</div>
              <div>
                Discount on rent DID:<strong>20%</strong>
              </div>

              <div>
                Changing the outgoing number:<strong>Yes</strong>
              </div>
              <div className={styles.divider}>|</div>
              <div>
                Changing the voice:<strong>Yes</strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popper
        open={open}
        anchorEl={el}
        placement={'top-start'}
        transition
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <div
              className={styles.notEnoughFundsContainer}
              onMouseLeave={() => {
                setOpen(false);
              }}
            >
              <ClickAwayListener
                onClickAway={() => (open ? setOpen(false) : undefined)}
              >
                <span>
                  {
                    'Oops, you don\'t have enough money on your balance to activate the premium plan.'
                  }
                  <span
                    onClick={onTopUpClick}
                    className={styles.highlighted}
                  >
                    Please, top-up balance.
                  </span>
                </span>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default PremiumPlan;
