import styles from 'components/IncomingSMS/components/DIDSMS/DIDSMS.module.scss';
import {Box, Fade, Modal} from '@mui/material';
import commonStyles from 'components/Modals/ModalsRoot.module.scss';
import {CloseIcon, SMSIconYellow} from 'assets';
import {
  DIDSMSCardNumbers
} from 'pages/DID/Tabs/DIDSMSLogs/components/DIDSMSCard/components/DIDSMSCardNumbers/DIDSMSCardNumbers';
import useBreakpoints from 'hooks/useBreakpoints';
import {useCallback} from 'react';
import classNames from 'classnames';

const DIDSMS = ({sms, onRead}) => {
  const {isMobile} = useBreakpoints();

  const wrapper = useCallback((component) => {
    if (isMobile) {
      return component
    }
    return <Box mx={'auto'} maxWidth={'1360px'} width={'100%'}>
      {component}
    </Box>
  }, [isMobile])

  return <Modal disableAutoFocus onClose={() => onRead(sms.date)} open hideBackdrop>
    <Fade in={true}>
      {wrapper(
        <div className={styles.modalContainer}>
          <div className={styles.modalTitle}>
            <Box
              height={'24px'}
              mb={isMobile ? 0 : 1}
              display={!isMobile ? 'flex' : 'initial'}
              alignItems={'center'}
              mr={isMobile ? 0.5 : 0}
            >
              <SMSIconYellow/>{!isMobile && <div className={styles.smsTitleText}>SMS</div>}
            </Box>

            <DIDSMSCardNumbers sms={sms}/>
          </div>

          <div onClick={() => onRead(sms.date)}
               className={classNames(commonStyles.closeButtonContainer, styles.closeButtonContainer)}>
            <CloseIcon className={classNames(commonStyles.closeButton, styles.closeButton)}/>
          </div>

          <div className={styles.text}>
            {sms.text}
          </div>
        </div>
      )}
    </Fade>
  </Modal>
}

export default DIDSMS