import {useCallback, useState} from 'react';
import {TopUpBalanceStepOne} from './TopUpBalanceStepOne';
import {TopUpBalanceStepTwo} from './TopUpBalanceStepTwo';
import useRequest from '../../hooks/useRequest';
import useHeaderSettings from '../../hooks/useHeaderSettings';

export const TopUpBalance = () => {
  useHeaderSettings({title: 'Top-up balance'});
  const [value, setValue] = useState('50');
  const [selectedCurrency, setSelectedCurrency] = useState('USDT');
  const [tab, setTab] = useState(0);
  const [response, setResponse] = useState();
  const {postDataWithCallback} = useRequest();

  const currencyRegExp = new RegExp('^\\d+([.]\\d{1,2})?$');

  const onCurrencyChange = (currencyCode) => {
    setSelectedCurrency(currencyCode);
    setResponse(null);
  }

  const checkDisabled = (amount) => {
    return (
      !amount || !currencyRegExp.test(amount || '') || parseFloat(amount) < 50
    );
  };

  const getData = useCallback((amount, currency, callback) => {
    postDataWithCallback('/api/pay', {amount, currency}, (data) => {
      setResponse(data);
      if (callback) {
        callback();
      }
    })
  }, [postDataWithCallback]);

  const valueOnChange = (value) => {
    const updVal = value?.replace(/^0+/, '').replace(/[^\d.]/g, '') || '';
    setValue(updVal);
  };

  const onStepTwo = useCallback(() => {
    void getData(value, selectedCurrency, () => setTab(1))
  }, [getData, selectedCurrency, value])

  return (
    <>
      {tab === 0 ? (
        <TopUpBalanceStepOne
          value={value}
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={onCurrencyChange}
          onStepTwo={onStepTwo}
          valueOnChange={valueOnChange}
          disabled={checkDisabled(value)}
        />
      ) : (
        <TopUpBalanceStepTwo
          value={value}
          setTab={setTab}
          selectedCurrency={selectedCurrency}
          response={response}
        />
      )}
    </>
  );
};
