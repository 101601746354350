import styles from './PhoneCall.module.scss';
import Keypad from './components/Keypad/Keypad';
import {appStore} from 'stores/AppStore';
import {observer} from 'mobx-react';
import {useEffect, useState} from 'react';
import {isValidPhoneNumber} from 'react-phone-number-input';
import useRequest from '../../hooks/useRequest';
import {usePhoneCall} from 'hooks/usePhoneCall';
import EventEmitter from '../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from 'components/Modals/const';
import {CardMedia} from '@mui/material';
import {useSearchParams} from 'react-router-dom';

const PhoneCall = () => {
  const [phone, setPhone] = useState('');
  const [rate, setRate] = useState();
  const {postDataWithCallback} = useRequest();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const params = JSON.parse(searchParams.get('state'));
    if (status === undefined && params && params.state?.number && params.state?.Key) {
      setSearchParams();
      initWebrtc(params.state.Key);
      onPhoneChange(params.state.number, true);
    }
    // eslint-disable-next-line
  }, [searchParams]);

  const {
    initWebrtc,
    audioRemoteRef,
    handleDisconnect,
    status,
    toggleAudio,
    elapsed,
    audioEnabled,
    sendMessageToDC,
  } = usePhoneCall({
    phoneNumber: phone, onHandleDisconnect: () => {
      setPhone('')
      setRate(undefined);
    }
  });

  useEffect(() => {
    EventEmitter.on(MODAL_TYPES.onDirectCallEnd, handleDisconnect);

    return () => {
      EventEmitter.off(MODAL_TYPES.onDirectCallEnd, handleDisconnect);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      handleDisconnect();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (appStore.currentUser?.centsBalance && elapsed > 0) {
      appStore.subtractFromBalance(parseFloat(rate) / 60);
    }
  }, [elapsed, rate]);

  const onPhoneChange = (phone, skipRate) => {
    phone = phone.replace(/[^0-9+*#]/g, '');
    if (phone && phone[0] !== '+') {
      phone = '+' + phone;
    }
    setPhone(phone);
    // TODO: remove fake number
    if (!skipRate && (isValidPhoneNumber(phone) || phone === '+0111')) {
      postDataWithCallback('/api/call/price', {number: phone}, (data) => {
        setRate(data.price);
      });
      return;
    }
    setRate('');
  };

  const onCallClick = () => {
    if (
      parseFloat(rate) &&
      appStore.currentUser.centsBalance < parseFloat(rate)
    ) {
      EventEmitter.emit(
        MODAL_EVENTS.openModal,
        MODAL_TYPES.attentionLowBalance
      );
      return;
    }
    initWebrtc();
  };

  const sendDTMFMessage = (symbol) => {
    if (status === 'Confirmed') {
      sendMessageToDC(JSON.stringify({dtmf: symbol}));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.keypadContainer}>
        <Keypad
          onCallClick={onCallClick}
          callDisabled={!rate || !!status}
          phone={phone}
          onPhoneChange={onPhoneChange}
          disabled={appStore.authorized !== true}
          callStatus={status}
          rate={rate}
          elapsed={elapsed}
          handleDisconnect={handleDisconnect}
          toggleAudio={toggleAudio}
          audioEnabled={audioEnabled}
          onKeyboardButtonClick={sendDTMFMessage}
        />
      </div>

      <CardMedia
        component="audio"
        ref={audioRemoteRef}
        autoPlay={true}
        playsInline={true}
      />
    </div>
  );
};

export default observer(PhoneCall);
