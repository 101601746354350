import React, {useEffect, useRef, useState} from 'react';
import {Box, Fade, Popper, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {CopyToClipboard} from 'react-copy-to-clipboard/lib/Component';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import {CopyDesktop, TickIcon} from '../../../../assets';
import styles from '../../../../components/Navigation/Header/components/TopBarInfo/TopBarInfo.module.scss';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: 'transparent',
    borderRadius: 10,
    padding: '16px',
    paddingRight: '56px',
    position: 'relative',
    color: 'white',
    cursor: 'pointer',
    width: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    lineHeight: '21px',
  },
  icon: {
    position: 'absolute',
    right: 20,
    bottom: 0,
    color: '#4A5663',
    '&:hover': {
      color: '#fff',
    },
  },
}));

function SendLink(props) {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const timer = useRef();
  const {isMobile} = useBreakpoints();
  const ref = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  function onCopy() {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setCopied(true);
    timer.current = setTimeout(() => setCopied(false), 2000);
  }

  return (
    <Box mb={3} px={2.5} width={'100%'}>
      <Box mb={!isMobile ? 1.5 : 1.25}>
        <span
          style={
            isMobile
              ? {
                fontWeight: 600,
                fontSize: '18px',
                lineHeight: '27px',
              }
              : {
                fontWeight: 600,
                fontSize: '24px',
                lineHeight: '36px',
              }
          }
        >
          Send link
        </span>
      </Box>
      <CopyToClipboard onCopy={onCopy} text={props.link}>
        <Box
          border="1px solid #313942"
          borderRadius={isMobile ? '10px' : '20px'}
          className={classes.box}
        >
          <Typography
            className={classes.text}
            style={
              !isMobile
                ? {
                  fontSize: 16,
                  lineHeight: '24px',
                }
                : {}
            }
          >
            {props.link}
          </Typography>

          <CopyDesktop
            ref={ref}
            className={classes.icon}
            style={{
              width: isMobile ? 17 : 32,
              height: isMobile ? 17 : 32,
              top: isMobile ? 18 : 12,
              color: copied ? '#FBC04E' : '',
            }}
          />
        </Box>
      </CopyToClipboard>

      <Popper
        open={copied}
        anchorEl={ref.current}
        placement={'bottom-end'}
        transition
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box className={styles.copiedContainer}>
              <TickIcon/>
              &nbsp;<span className={styles.copied}>Copied!</span>
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}

export default SendLink;
