import {Box, Typography} from '@mui/material';
import {theme} from '../../../../theme';
import {DIDDescriptionImageDesktop, DIDDescriptionImageMobile} from '../../../../assets';
import styles from './DIDDescription.module.scss';
import useBreakpoints from '../../../../hooks/useBreakpoints';

export const DIDDescription = () => {
  const {isMobile} = useBreakpoints();

  const title = isMobile ?
    'Virtual phone numbers for\nreceiving incoming calls* and\nSMS directly in Web browser.' :
    'Virtual phone numbers for receiving incoming\ncalls* and SMS directly in Web browser.'

  const text = isMobile ?
    '* you must have the app.dcalls.org page open in\norder to receive an incoming call in your browser,\notherwise you will not receive an incoming call\nnotification.' :
    '* you must have the app.dcalls.org page open in order to receive an incoming call in your browser, \notherwise you will not receive an incoming call notification.'

  return (
    <div className={styles.container}>
      <Typography align={'center'} variant={'h2'} whiteSpace={'pre-wrap'}>{title}</Typography>

      <Box className={styles.svgContainer} my={3}>
        {isMobile ? <DIDDescriptionImageMobile/> : <DIDDescriptionImageDesktop/>}
      </Box>

      <Typography
        whiteSpace={'pre-wrap'}
        align={'center'}
        variant={'subtitle2'}
        color={theme.palette.primary.dark}
      >
        {text}
      </Typography>
    </div>
  )
}