import styles from './DIDCallLogsTableDesktop.module.scss';
import moment from 'moment/moment';
import {formatElapsed} from 'utils/time';
import CallStatusBadge from 'pages/CallHistory/components/CallStatusBadge/CallStatusBadge';
import {Box} from '@mui/material';

export const DIDCallLogsTableDesktop = ({items}) => {
  const renderRow = (item) => {
    return <div key={item.key} className={styles.row}>
      <Box width={'120px'}>
        {moment(item.startAt * 1000).format('DD.MM.YYYY')}
        <br/>
        <span>{moment(item.startAt * 1000).format('(HH:mm:ss)')}</span>
      </Box>

      <Box width={'150px'}>+{item.to}</Box>

      <Box width={'150px'}>+{item.from}</Box>

      <Box width={'80px'}>{formatElapsed(item.duration)}</Box>

      <Box width={'109px'}><CallStatusBadge callStatus={item.status}/></Box>
    </div>
  }

  return <div>
    <div className={styles.header}>
      <Box width={'120px'}>Date, time</Box>
      <Box width={'150px'}>DID number</Box>
      <Box width={'150px'}>Source</Box>
      <Box width={'80px'}>Spent min.</Box>
      <Box width={'109px'}>Status</Box>
    </div>

    <div className={styles.rows}>
      {items.map(item => renderRow(item))}
    </div>
  </div>
}