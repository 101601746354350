import {CloseIcon} from '../../../../assets';
import commonStyles from '../../ModalsRoot.module.scss';
import {Button} from '../../../Buttons';
import {useMemo, useState} from 'react';
import InputWithFrame from '../../../InputWithFrame/InputWithFrame';
import {Box, ClickAwayListener, debounce, Fade, Popper} from '@mui/material';
import useRequest from '../../../../hooks/useRequest';
import EventEmitter from '../../../../utils/events/EventEmitter';
import styles from '../../../../pages/ChangePlan/components/PremiumPlan/PremiumPlan.module.scss';

const TopUpBalanceModal = ({onClose}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('0');
  const {postDataWithCallback} = useRequest();
  const [el, setEl] = useState();
  const [url, setUrl] = useState('');
  const [width, setWidth] = useState(250);

  const currencyRegExp = new RegExp('^\\d+([.]\\d{1,2})?$');

  const checkDisabled = (amount) => {
    return (
      !amount || !currencyRegExp.test(amount || '') || parseFloat(amount) < 10
    );
  };

  const getUrl = useMemo(
    () =>
      debounce(
        (amount) =>
          postDataWithCallback('/api/pay', {amount}, (data) => {
            setUrl(data.url);
          }),
        200
      ),
    [postDataWithCallback]
  );

  const valueOnChange = (value) => {
    const updVal = value?.replace(/^0+/, '').replace(/[^\d.]/g, '') || '';
    setValue(updVal);
    setUrl('');
    if (!checkDisabled(updVal)) {
      getUrl(updVal);
    }
  };

  const disabled = checkDisabled(value);

  const onPayClick = async (event) => {
    if (disabled) {
      setEl(event.target);
      setOpen(true);
      return;
    }

    if (url) {
      onClose();
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.click();
      EventEmitter.emit('start_user_polling');
    }
  };

  return (
    <div className={commonStyles.defaultModal}>
      <div onClick={onClose} className={commonStyles.closeButtonContainer}>
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <h4 className={commonStyles.heading}>Top-up balance</h4>

      <Box my={2}>
        <InputWithFrame
          value={value}
          onChange={valueOnChange}
          prefix="$"
          placeholder="$0"
          type="tel"
          pattern="[0-9]*"
          noValidate
        />
      </Box>

      <Button
        onClick={onPayClick}
        fakeDisabled={checkDisabled(value) || !url}
        style={{width: '100%'}}
        colorScheme={disabled ? 'dark' : undefined}
        onMouseEnter={(event) => {
          if (disabled) {
            setEl(event.target);
            setWidth(event.target.offsetWidth);
            setOpen(true);
          }
        }}
      >
        Pay
      </Button>

      <Popper
        style={{zIndex: 9999}}
        open={open}
        anchorEl={el}
        placement={'bottom-end'}
        transition
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <div
              className={styles.notEnoughFundsContainer}
              onMouseLeave={() => {
                setOpen(false);
              }}
              style={{width}}
            >
              <ClickAwayListener
                onClickAway={() => (open ? setOpen(false) : undefined)}
              >
                <span>Minimum balance top-up amount from $10</span>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default TopUpBalanceModal;
