import {Box} from '@mui/material';
import styles from './DIDNumberSelectList.module.scss';
import {getFlagEmoji} from '../../../../../../utils/getFlagEmoji';
import {ReactComponent as VoiceIcon} from '../../icons/voiceIconActive.svg';
import {ReactComponent as SMSIcon} from '../../icons/smsIconActive.svg';
import {HeaderBackIcon} from '../../../../../../assets';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {ReactComponent as TickIcon} from './icons/tick.svg';
import {ReactComponent as RefreshIcon} from './icons/refresh.svg';
import useRequest from '../../../../../../hooks/useRequest';
import useBreakpoints from '../../../../../../hooks/useBreakpoints';
import {DIDListCard} from '../DIDListCard/DIDListCard';

const DIDNumberSelectList = ({setSelectedNumber, type, isPremium, selectedCity, selectedCountry}) => {
  const {isMobile} = useBreakpoints();
  const [numbers, setNumbers] = useState();
  const {postDataWithCallback} = useRequest();

  const loadNumbers = useCallback(() => {
    setNumbers();
    const body = {
      city: selectedCity.id,
    }

    postDataWithCallback('/api/did/city', body, response => {
      setNumbers(response);
    })
  }, [selectedCity.id, postDataWithCallback]);

  useEffect(() => {
    loadNumbers();
  }, [loadNumbers]);

  const header = useMemo(() => {
    return <Box
      mt={3}
      mb={1.5}
      className={styles.subtitle}
      display={'flex'}
      flexDirection={isMobile ? 'column' : 'row'}
      textAlign={'center'}
    >
      Select number:
      {numbers &&
        <Box
          width={'fit-content'}
          className={styles.available}
          ml={'auto'}
          mt={isMobile ? 1 : 0}
          mr={isMobile ? 'auto' : 'initial'}
        >
          <TickIcon/>Available {numbers?.total} pcs.
        </Box>
      }
    </Box>
  }, [isMobile, numbers]);

  if (numbers === undefined) return null;
  if (numbers.data === null) {
    return <>
      {header}
      <div className={styles.emptyText}>
        There is nothing here yet
      </div>
    </>
  }

  return <div className={styles.container}>
    {header}

    <Box mt={2}>
      {!isMobile && <>
        <Box className={styles.tableHeader} display={'flex'} justifyContent={'space-between'}>
          <Box width={'250px'}>Number</Box>
          <Box width={'150px'}>Type number</Box>
          <Box width={'120px'}>Setup fee</Box>
          <Box width={'120px'}>Monthly</Box>
          <Box width={'24px'}></Box>
        </Box>

        {numbers?.data?.map(number => (
          <Box key={number.id} className={styles.tableRow} display={'flex'} justifyContent={'space-between'}>
            <Box width={'250px'} className={styles.tableRowCountryName}>
              {getFlagEmoji(selectedCountry.attributes.iso)} +{number.number}
            </Box>

            <Box width={'150px'} className={styles.tableRowType}>
              {
                type === 'voice' ? <><VoiceIcon/>Voice</> :
                  <><VoiceIcon/>Voice +&nbsp;<SMSIcon/>SMS</>
              }
            </Box>

            <Box width={'120px'}>${isPremium ? parseFloat(number.setupVipPrice) : parseFloat(number.setupPrice)}</Box>

            <Box
              width={'120px'}>${isPremium ? parseFloat(number.monthlyVipPrice) : parseFloat(number.monthlyPrice)}&nbsp;
              <span>/ mo</span>
            </Box>

            <Box width={'24px'} height={'24px'}>
              <HeaderBackIcon className={styles.icon} onClick={() => setSelectedNumber(number)}/>
            </Box>
          </Box>
        ))}
      </>}

      {isMobile && numbers?.data?.map(number =>
        <DIDListCard
          title={<>{getFlagEmoji(selectedCountry.attributes.iso)} +{number.number}</>}
          type={type}
          onSelect={() => setSelectedNumber(number)}
          setupFee={isPremium ? parseFloat(number.setupVipPrice) : parseFloat(number.setupPrice)}
          rentFee={isPremium ? parseFloat(number.monthlyVipPrice) : parseFloat(number.monthlyPrice)}
        />
      )}

      <Box onClick={() => loadNumbers()} mt={3} mb={3} mx={'auto'} className={styles.refresh}>
        <RefreshIcon/>Refresh
      </Box>
    </Box>
  </div>
}

export default DIDNumberSelectList