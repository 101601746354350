import styles from './DIDSMSCard.module.scss';
import {useCallback, useMemo, useState} from 'react';
import {DeleteIcon, ViewIcon} from 'assets';
import MenuButton from 'components/Navigation/Header/components/TopBarInfo/components/MenuButton/MenuButton';
import moment from 'moment';
import DIDSMSDeleteModal from './components/DIDSMSDeleteModal/DIDSMSDeleteModal';
import useRequest from 'hooks/useRequest';
import DIDSMSViewModal
  from 'pages/DID/Tabs/DIDSMSLogs/components/DIDSMSCard/components/DIDSMSViewModal/DIDSMSViewModal';
import {
  DIDSMSCardNumbers
} from 'pages/DID/Tabs/DIDSMSLogs/components/DIDSMSCard/components/DIDSMSCardNumbers/DIDSMSCardNumbers';

const DIDSMSCard = ({sms, refetchSms}) => {
  const {postDataWithCallback} = useRequest();
  const [viewOpen, setViewOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const menuItems = useMemo(() => {
      return [
        {
          title: 'View',
          onClick: () => setViewOpen(true),
          icon: <ViewIcon/>
        },
        {
          title: 'Delete DID',
          onClick: () => setDeleteOpen(true),
          icon: <DeleteIcon/>,
        },
      ]
    }, []
  );

  const onDelete = useCallback(() => {
    postDataWithCallback('/api/sms/delete', {id: sms.id}, () => {
      refetchSms();
      setDeleteOpen(false);
    })
  }, [postDataWithCallback, refetchSms, sms.id]);

  return <div className={styles.card}>
    <div className={styles.cardHeader}>
      <div>
        <div className={styles.cardHeaderDate}>
          {moment(sms.date * 1000).format('DD.MM.YYYY, HH:mm:ss')}
        </div>

        <DIDSMSCardNumbers sms={sms}/>
      </div>

      <MenuButton size={24} iconSize={14} borderRadius={5} items={menuItems}/>
    </div>

    <div onClick={() => setViewOpen(true)} className={styles.cardText}>
      <p>{sms.text}</p>
    </div>

    {deleteOpen && <DIDSMSDeleteModal onDelete={onDelete} onClose={() => setDeleteOpen(false)}/>}

    {viewOpen && <DIDSMSViewModal sms={sms} onClose={() => setViewOpen(false)}/>}
  </div>
}

export default DIDSMSCard;