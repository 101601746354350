import styles from './DIDRenewModal.module.scss';
import useBreakpoints from '../../../../../../hooks/useBreakpoints';
import {Box, ClickAwayListener, Fade, Modal, Popper} from '@mui/material';
import {CloseIcon} from '../../../../../../assets';
import commonStyles from '../../../../../../components/Modals/ModalsRoot.module.scss';
import {getFlagEmoji} from '../../../../../../utils/getFlagEmoji';
import moment from 'moment';
import BoxWithHeader from '../../../../../../components/Misc/BoxWithHeader/BoxWithHeader';
import {Button} from '../../../../../../components/Buttons';
import {appStore} from '../../../../../../stores/AppStore';
import {observer} from 'mobx-react';
import {useState} from 'react';
import classNames from 'classnames';
import EventEmitter from '../../../../../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from '../../../../../../components/Modals/const';
import {loadCurrentUser} from '../../../../../../lib/auth';
import {getTokenValue} from '../../../../../../utils/LocalStorage';
import useRequest from '../../../../../../hooks/useRequest';
import {useNavigate} from 'react-router-dom';
import {monthOptions} from 'utils/env';

const DIDRenewModal = ({number, onClose, fetchNumbers}) => {
  const {postDataWithCallback} = useRequest();
  const navigate = useNavigate();
  const {isMobile} = useBreakpoints();

  const [month, setMonth] = useState(1);
  const [open, setOpen] = useState(false);
  const [el, setEl] = useState();
  const [loading, setLoading] = useState(false);

  const isPremium = appStore.currentUser?.vipType !== 'no';

  const onTopUpClick = async () => {
    setOpen(false);
    navigate('/top-up-balance');
  };

  const rentFee = (isPremium ? parseFloat(number.monthlyVipPrice) : parseFloat(number.monthlyPrice)) * month;

  const onPay = (event) => {
    if (appStore.currentUser.centsBalance < rentFee * 100 || loading) {
      setEl(event.target);
      setOpen(true);
      return
    }

    setLoading(true);
    postDataWithCallback('/api/did/renew', {'number': number.number, month}, () => {
      EventEmitter.emit(MODAL_EVENTS.openModal, MODAL_TYPES.defaultNotification,
        {title: 'Congratulations!', text: <>{`Your DID has been${isMobile ? '\n' : ' '}successfully renewed.`}</>});
      void fetchNumbers();
      void loadCurrentUser(getTokenValue());
      setLoading(false);
      onClose();
    })
  }


  return <Modal disableAutoFocus={true} onClose={onClose} open={true}>
    <div className={styles.modalContainer}>
      <div className={styles.modalTitle}>{'Would you like\nto renew the DID?'}</div>
      <div onClick={onClose} className={commonStyles.closeButtonContainer}>
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      {isMobile ? (
        <div className={styles.plan}>
          <Box display={'flex'} flexDirection={'column'}>
            <div className={styles.esimTitle}>
              +{number.number}
            </div>
            <div className={styles.esimPlan}>
              {getFlagEmoji(number.countryISO)}&nbsp;{number.country}
              {number.region && ', ' + number.region}
              {number.city && ', ' + number.city}
            </div>
          </Box>

          <div className={styles.esimUntil}>
            <div>Descripion&nbsp;<span>{number.alias || '-'}</span></div>
            <div>Expire at&nbsp;<span>{moment(number.until * 1000).format('DD.MM.YYYY (HH:mm:ss)')}</span></div>
            <div>Total&nbsp;<span>${rentFee}</span></div>
          </div>
        </div>
      ) : (
        <BoxWithHeader headerText={
          <Box className={styles.rowHeader} display={'flex'} justifyContent={'space-between'}>
            <Box width={'250px'}>Country / City</Box>
            <Box width={'150px'}>DID number</Box>
            <Box width={'150px'}>Description</Box>
            <Box width={'100px'}>Expire at</Box>
            <Box width={'100px'}>Total </Box>
          </Box>
        }>
          <Box className={styles.row} display={'flex'} justifyContent={'space-between'}>
            <Box width={'250px'}>
              <div className={styles.rowText}>
                {getFlagEmoji(number.countryISO)}&nbsp;{number.country}
                {number.region && ', ' + number.region}
                {number.city && ', ' + number.city}
              </div>
            </Box>
            <Box width={'150px'}>
              <div className={styles.rowText}>
                +{number.number}
              </div>
            </Box>
            <Box width={'150px'}>
              <div className={styles.rowText} style={{wordBreak: 'break-all'}}>
                {number.alias || '-'}
              </div>
            </Box>
            <Box width={'100px'}>
              <div className={styles.rowText}>
                {moment(number.until * 1000).format('DD.MM.YYYY')}<br/>
                <span>{moment(number.until * 1000).format('(HH:mm:ss)')}</span>
              </div>
            </Box>

            <Box width={'100px'}>
              <div className={styles.rowText}>
                ${rentFee}
              </div>
            </Box>
          </Box>
        </BoxWithHeader>
      )}

      <Box
        mt={isMobile ? 2 : 4}
        mb={isMobile ? 2 : 4}
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <div className={styles.periodTitle}>Select period (in months):</div>
        <Box mt={1.5} display={'flex'} width={'100%'} justifyContent={'center'}>
          {monthOptions.map((option) => (
            <Box key={option} className={classNames(styles.period, month === option ? styles.periodActive : '')}
                 onClick={() => setMonth(option)}>{option}</Box>
          ))}
        </Box>
      </Box>

      <Box display={'flex'} gap={'16px'} maxWidth={344} mx={'auto'}>
        <Button style={{width: '100%', minHeight: '56px'}} onClick={onClose} colorScheme={'dark'}>Cancel</Button>
        <Button style={{width: '100%', minHeight: '56px'}} onClick={onPay}>Pay</Button>
      </Box>

      <Popper className={styles.popper} open={open} anchorEl={el} placement={'top-start'} transition>
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <div
              className={styles.notEnoughFundsContainer}
              onMouseLeave={() => {
                setOpen(false);
              }}
            >
              <ClickAwayListener
                onClickAway={() => (open ? setOpen(false) : undefined)}
              >
                <span>
                  {
                    'Oops, you don\'t have enough money on your balance to renew DID.'
                  }
                  <span onClick={onTopUpClick} className={styles.highlighted}>
                    Please, top-up balance.
                  </span>
                </span>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  </Modal>
}

export default observer(DIDRenewModal)