import styles
  from 'pages/DID/Tabs/DIDSMSLogs/components/DIDSMSCard/components/DIDSMSCardNumbers/DIDSMSCardNumbers.module.scss';
import {ArrowRightGreenIcon} from 'assets';

export const DIDSMSCardNumbers = ({sms}) => {
  return <div className={styles.cardHeaderNumbers}>
    <div className={styles.cardHeaderFrom}>{sms.from}</div>
    <ArrowRightGreenIcon/>
    <div className={styles.cardHeaderTo}>+{sms.to}</div>
  </div>
}