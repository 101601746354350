import {Box} from '@mui/material';
import styles from './ESIMProfile.module.scss';
import {AndroidIcon, AppleIcon, HeaderBackIcon} from '../../../../../assets';
import {esimLocations} from '../../ESIMBuy/ESIMBuy';
import {getFlagEmoji} from '../../../../../utils/getFlagEmoji';
import moment from 'moment';
import {useEffect, useState} from 'react';
import useRequest from '../../../../../hooks/useRequest';
import InfoBadge from '../../../../../components/Misc/InfoBadge/InfoBadge';
import BoxWithHeader from '../../../../../components/Misc/BoxWithHeader/BoxWithHeader';
import CopyWithLabel from '../../../../../components/Misc/CopyWithLabel/CopyWithLabel';
import useBreakpoints from '../../../../../hooks/useBreakpoints';
import {useNavigate} from 'react-router-dom';
import ListOfCountriesModal from '../../ESIMBuy/components/ListOfCountriesModal/ListOfCountriesModal';

const ESIMProfile = ({esim: _esim, onClose, countries}) => {
  const {isMobile} = useBreakpoints();
  const [listOpen, setListOpen] = useState(false);
  const [esim, setEsim] = useState();
  const [plan, setPlan] = useState();
  const {
    postDataWithCallback
  } = useRequest();
  const navigate = useNavigate();

  useEffect(() => {
    postDataWithCallback('/api/esim/show', {orderID: _esim.orderID}, data => {
      setEsim(data.esim)
      setPlan(data.plan)
    })
  }, [_esim.orderID, postDataWithCallback])

  if (!esim) {
    return null;
  }

  const renderIphoneManual = () => {
    return <BoxWithHeader
      headerText={<div className={styles.boxHeader}>Manual installation for<AppleIcon/><span>iOS:</span></div>}>
      <CopyWithLabel text={esim.manual1} title={'SM-DP+ Address:'}/>
      <Box mt={2} maxWidth={'100%'}>
        <CopyWithLabel
          text={esim.manual2}
          title={'Activation Code:'}
        />
      </Box>
    </BoxWithHeader>
  }

  const renderAndroidManual = () => {
    return <BoxWithHeader
      height={'100%'}
      headerText={
        <div className={styles.boxHeader}>Manual installation for<AndroidIcon/><span>Android:</span></div>
      }>
      <CopyWithLabel
        text={esim.qrCodeString}
        title={'Activation Code:'}
      />
    </BoxWithHeader>
  }

  return <div className={styles.container}>
    <Box className={styles.desktopTitle}>
      <HeaderBackIcon onClick={onClose} className={styles.backButton}/>
      <span>ID order #{esim.orderID}</span>
    </Box>

    {isMobile ?
      <div className={styles.plan}>
        <Box display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={'column'}>
              <div
                className={styles.esimTitle}>{esim.region ? esimLocations.find(loc => loc.code === esim.region).name : (
                <>{getFlagEmoji(esim.code)}&nbsp;{countries.find(c => c.countryCode === esim.code).countryName}</>
              )}
              </div>
              <div className={styles.esimPlan}>
                <span>Plan</span>&nbsp;{esim.capacity} GB
                for {esim.period} days
              </div>
            </Box>

            <Box display={'flex'} flexDirection={'column'}>
              <div onClick={() => setListOpen(true)} className={styles.infoList}>List of countries</div>
              <div className={styles.infoPrice}>Price <div>{esim.price}$</div></div>
            </Box>
          </Box>
        </Box>

        <div className={styles.esimUntil}>
          <div>ID order&nbsp;<span>{esim.orderID}</span></div>
          <div>Purchase&nbsp;<span>{moment(esim.purchasedAt * 1000).format('DD.MM.YYYY (HH:mm:ss)')}</span></div>
        </div>
      </div>
      :
      <BoxWithHeader headerText={
        <Box py={0.5} display={'flex'} justifyContent={'space-between'}>
          <Box className={styles.infoTitleDesktop} width={'120px'}>Date of purchase</Box>
          <Box className={styles.infoTitleDesktop} width={'300px'}>Country</Box>
          <Box className={styles.infoTitleDesktop} width={'150px'}>Package</Box>
          <Box className={styles.infoTitleDesktop} width={'100px'}>Price</Box>
        </Box>
      }>
        <div className={styles.row}>
          <Box width={'120px'}>
            {moment(esim.purchasedAt * 1000).format('DD.MM.YYYY')}
            <br/>
            <span>{moment(esim.purchasedAt * 1000).format('(HH:mm:ss)')}</span>
          </Box>

          <Box width={'300px'} display={'flex'} gap={'16px'} alignItems={'center'}>
            <div
              className={styles.esimTitle}>{esim.region ? esimLocations.find(loc => loc.code === esim.region).name : (
              <>{getFlagEmoji(esim.code)}&nbsp;{countries.find(c => c.countryCode === esim.code).countryName}</>
            )}
            </div>
            <div onClick={() => setListOpen(true)} className={styles.infoList}>List of countries</div>
          </Box>

          <Box width={'150px'}>
            <div className={styles.infoPrice}>
              <div>{plan.capacity} GB</div>
              &nbsp;
              for {plan.period} days
            </div>
          </Box>

          <Box width={'100px'}>
            <div>{plan.price}$</div>
          </Box>
        </div>
      </BoxWithHeader>}

    <Box mt={isMobile ? 0 : 5}>
      <InfoBadge width={'fit-content'} text={'This eSIM profile can be downloaded only once.'}/>
    </Box>

    <Box mt={1} mb={isMobile ? 3 : 5}>
      <Box mb={1}
           className={styles.greyText}>{'Please do not delete/remove the eSIM cellular plan from your phone settings until you are done using it.\n' +
        'Once deleted eSIM profile cannot be restored.'}</Box>
      <div
        className={styles.greyText}>{'Please follow the activation steps below and contact us if you need any help.'}</div>
    </Box>


    <Box mb={2} className={styles.smallTitle}>eSIM Profile</Box>

    {isMobile ? (
      <>
        {renderIphoneManual()}
        <Box mb={2}/>
        {renderAndroidManual()}
      </>
    ) : (
      <Box width={'100%'} display={'flex'} alignItems={'stretch'} justifyContent={'space-between'}>
        <Box width={'calc(50% - 12px)'}>{renderIphoneManual()}</Box>
        <Box alignSelf={'stretch'} width={'calc(50% - 12px)'}>{renderAndroidManual()}</Box>
      </Box>
    )}


    <Box mt={isMobile ? 3 : 5} mb={2}
         className={styles.smallTitle}>{'Add an eSIM profile' + (isMobile ? '\n' : ' ') + 'to your phone'}</Box>

    {isMobile ? (
      <>
        <Box mb={2} className={styles.greyText}>
          You must activate eSIM, that is, install on the phone. This happens through the <span
          className={styles.white}>"Settings"</span> menu, in which you need
          to open the <span
          className={styles.white}>"Cellular"</span>, and then <span
          className={styles.white}>"Add Cellular Plan"</span>.
        </Box>

        <Box display={'flex'}>
          <Box mr={3} className={styles.qrCodeContainer}>
            <img src={esim.qrCodeDataURL} width={120} alt={esim.qrCodeString}/>
          </Box>
          <Box px={'6px'} className={styles.greyText}>
            When you click on "Add Cellular Plan", the QR code reader application opens, after which you should <span
            className={styles.white}>direct the
        phone camera to the specified code</span>.
          </Box>
        </Box>

        <Box mt={2} className={styles.bottomBadge} onClick={() => {
          window.scrollTo(0, 0);
          navigate('/esim?tab=3')
        }}>
          More detailed instructions can be found in the <span className={styles.yellow}>"Quide"</span> section.
        </Box>
      </>
    ) : (
      <Box mb={2} display={'flex'} gap={'57px'}>

        <Box>
          <Box mb={2} className={styles.greyText}>
            You must activate eSIM, that is, install on the phone. This happens through the <span
            className={styles.white}>"Settings"</span> menu, in which you need
            to open the <span
            className={styles.white}>"Cellular"</span>, and then <span
            className={styles.white}>"Add Cellular Plan"</span>.
          </Box>

          <Box display={'flex'}>
            <Box pr={'6px'} className={styles.greyText}>
              When you click on "Add Cellular Plan", the QR code reader application opens, after which you should <span
              className={styles.white}>direct the phone camera to the specified code</span>.
            </Box>
          </Box>

          <Box mt={3} width={'fit-content'} className={styles.bottomBadge} onClick={() => {
            window.scrollTo(0, 0);
            navigate('/esim?tab=3')
          }}>
            More detailed instructions can be found in the <span className={styles.yellow}>"Quide"</span> section.
          </Box>
        </Box>

        <Box className={styles.qrCodeContainer}>
          <img src={esim.qrCodeDataURL} width={142} alt={esim.qrCodeString}/>
        </Box>
      </Box>
    )}


    <ListOfCountriesModal setOpen={setListOpen} open={listOpen} plan={plan}/>
  </div>
}

export default ESIMProfile