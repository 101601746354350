import styles from './ESIMTable.module.scss';
import moment from 'moment';
import {esimLocations} from '../../ESIMBuy/ESIMBuy';
import {getFlagEmoji} from '../../../../../utils/getFlagEmoji';
import MenuButton
  from '../../../../../components/Navigation/Header/components/TopBarInfo/components/MenuButton/MenuButton';
import {DeleteIcon, EsimIcon} from '../../../../../assets';

const ESIMTable = ({esims, countries, setSelectedEsim, setEsimToDelete}) => {

  const renderRow = (esim) => {
    const menuItems = [
      {
        title: 'eSIM profile',
        onClick: () => setSelectedEsim(esim),
        icon: <EsimIcon/>,
      },
      {
        title: 'Delete',
        onClick: () => setEsimToDelete(esim),
        icon: <DeleteIcon/>,
      },
    ];

    return <div key={esim.orderID} className={styles.row}>
      <div>
        {moment(esim.purchasedAt * 1000).format('DD.MM.YYYY')}
        <br/>
        <span>{moment(esim.purchasedAt * 1000).format('(HH:mm:ss)')}</span>
      </div>

      <div>{esim.orderID}</div>

      <div className={styles.esimTitle}>
        {esim.region ? esimLocations.find(loc => loc.code === esim.region).name : (
          <>{getFlagEmoji(esim.code)}&nbsp;{countries?.find(c => c.countryCode === esim.code).countryName}</>
        )}
      </div>

      <div className={styles.esimPlan}>
        <div>{esim.capacity} GB <span>for {esim.period} days</span></div>
      </div>

      <div>{esim.price}$</div>

      <div><MenuButton width={167} items={menuItems}/></div>
    </div>
  }

  return <div>
    <div className={styles.header}>
      <div>Date of purchase</div>
      <div>ID order</div>
      <div>Country</div>
      <div>Package</div>
      <div>Price</div>
      <div>Actions</div>
    </div>

    <div className={styles.rows}>
      {esims.map(esim => renderRow(esim))}
    </div>
  </div>
}

export default ESIMTable