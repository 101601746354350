import styles from './ESIMDescription.module.scss';
import {Box, Typography} from '@mui/material';
import {theme} from '../../../../theme';
import {ESIMDescriptionBackgroundDesktop, ESIMDescriptionBackgroundMobile} from '../../../../assets';
import {AnonymousIcon, BigWalletIcon, PlanetIcon, RocketIcon} from './icons';
import useBreakpoints from '../../../../hooks/useBreakpoints';

const ESIMDescription = ({setTabIndex}) => {
  const {isMobile} = useBreakpoints();

  const title = <>
    {'Virtual SIM card (eSIM)'}
    {isMobile ? <br/> : ' '}
    {'for secure mobile internet.'}
    <br/>
    <span style={{color: theme.palette.secondary.main}}>No subscription or hidden fees.</span>
  </>

  const renderCard = ({title, icon}) => {
    return (
      <div className={styles.card}>
        <div className={styles.iconContainer}>
          {icon}
        </div>
        <div className={styles.cardTitle}>{title}</div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <Typography variant={'h2'} align={'center'}>{title}</Typography>

      <Box my={3} position={'relative'}>
        <Box display={'flex'} alignItems={'center'}
             justifyContent={'center'}>
          {isMobile ? <ESIMDescriptionBackgroundMobile/> : <ESIMDescriptionBackgroundDesktop/>}
        </Box>

        <Box
          position={'absolute'}
          top={0}
          left={0}
          right={0}
          pt={'57px'}
          display={'flex'}
          justifyContent={'center'}
        >
          <Box
            display={'grid'}
            gap={isMobile ? '12px' : '24px'}
            gridTemplateColumns={isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr'}
            width={isMobile ? 'fit-content' : '100%'}
          >
            {renderCard({title: 'Available\nin 178 countries', icon: <PlanetIcon/>})}
            {renderCard({title: 'Get an eSIM\nin a few minutes', icon: <RocketIcon/>})}
            {renderCard({title: 'Cryptocurrency\npayment', icon: <BigWalletIcon/>})}
            {renderCard({title: 'KYC/KYB\nis not required', icon: <AnonymousIcon/>})}
          </Box>
        </Box>
      </Box>

      <Typography align="center" variant={'subtitle2'} color={theme.palette.primary.dark}>
        {'Please read the '}
        <Box
          onClick={() => setTabIndex(3)} color={theme.palette.secondary.main} display={'inline'}
          cursor={'pointer'}>
          Guide
        </Box>
        {' carefully before purchasing'}
        {isMobile ? <br/> : ' '}
        {'an eSIM.'}{isMobile ? ' ' : <br/>}{'By placing an order, you confirm that you'}
        {isMobile ? <br/> : ' '}
        {'have read this information.'}
      </Typography>
    </div>
  )
}

export default ESIMDescription