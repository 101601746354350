export function formatElapsed(seconds) {
  let timeString = new Date(seconds * 1000).toISOString()

  if (seconds >= 3600) {
    timeString = timeString.substr(11, 8)
  } else {
    timeString = timeString.substr(14, 5)
  }

  return timeString
}
