export function setTokenValue(value) {
  localStorage.setItem("token", value);
}

export function getTokenValue() {
  return localStorage.getItem("token");
}

export function removeTokenValue() {
  return localStorage.removeItem("token");
}

export function setPubKey(value) {
  localStorage.setItem("pubKey", value);
}

export function getPubKey() {
  return localStorage.getItem("pubKey");
}

export function removePubKey() {
  return localStorage.removeItem("pubKey");
}
