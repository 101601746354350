import styles from './DIDDeleteModal.module.scss';
import useBreakpoints from '../../../../../../hooks/useBreakpoints';
import {Box, Modal} from '@mui/material';
import {CloseIcon} from '../../../../../../assets';
import commonStyles from '../../../../../../components/Modals/ModalsRoot.module.scss';
import {getFlagEmoji} from '../../../../../../utils/getFlagEmoji';
import moment from 'moment';
import BoxWithHeader from '../../../../../../components/Misc/BoxWithHeader/BoxWithHeader';
import {Button} from '../../../../../../components/Buttons';

const DIDDeleteModal = ({number, onDelete, onClose}) => {
  const {isMobile} = useBreakpoints();

  return <Modal disableAutoFocus={true} onClose={onClose} open={true}>
    <div className={styles.modalContainer}>
      <div className={styles.modalTitle}>{'Are you want to\nremove selected DID?'}</div>
      <div onClick={onClose} className={commonStyles.closeButtonContainer}>
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      {isMobile ? (
        <div className={styles.plan}>
          <Box display={'flex'} flexDirection={'column'}>
            <div className={styles.esimTitle}>
              +{number.number}
            </div>
            <div className={styles.esimPlan}>
              {getFlagEmoji(number.countryISO)}&nbsp;{number.country}
              {number.region && ', ' + number.region}
              {number.city && ', ' + number.city}
            </div>
          </Box>

          <div className={styles.esimUntil}>
            <div>ID order&nbsp;<span>{number.until}</span></div>
            <div>Description&nbsp;<span>{number.alias || '-'}</span></div>
            <div>Expire at&nbsp;<span>{moment(number.until * 1000).format('DD.MM.YYYY (HH:mm:ss)')}</span></div>
          </div>
        </div>
      ) : (
        <BoxWithHeader headerText={
          <Box className={styles.rowHeader} display={'flex'} justifyContent={'space-between'}>
            <Box width={'250px'}>Country / City</Box>
            <Box width={'150px'}>DID number</Box>
            <Box width={'150px'}>Description</Box>
            <Box width={'100px'}>Expire at</Box>
          </Box>
        }>
          <Box className={styles.row} display={'flex'} justifyContent={'space-between'}>
            <Box width={'250px'}>
              <div className={styles.rowText}>
                {getFlagEmoji(number.countryISO)}&nbsp;{number.country}
                {number.region && ', ' + number.region}
                {number.city && ', ' + number.city}
              </div>
            </Box>
            <Box width={'150px'}>
              <div className={styles.rowText}>
                +{number.number}
              </div>
            </Box>
            <Box width={'150px'}>
              <div className={styles.rowText} style={{wordBreak: 'break-all'}}>
                {number.alias}
              </div>
            </Box>
            <Box width={'100px'}>
              <div className={styles.rowText}>
                {moment(number.until * 1000).format('DD.MM.YYYY')}<br/>
                <span>{moment(number.until * 1000).format('(HH:mm:ss)')}</span>
              </div>
            </Box>
          </Box>
        </BoxWithHeader>
      )}


      <div className={styles.danger}>
        <span>Important!</span>&nbsp;The DID will be deleted immediately. No refund will be issued for the service time
        remaining.
      </div>

      <Box display={'flex'} gap={'16px'} maxWidth={344} mx={'auto'}>
        <Button style={{width: '100%', minHeight: '56px'}} onClick={onClose} colorScheme={'dark'}>Cancel</Button>
        <Button style={{width: '100%', minHeight: '56px'}} onClick={onDelete}>Delete</Button>
      </Box>
    </div>
  </Modal>
}

export default DIDDeleteModal