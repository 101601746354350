import {useCallback, useEffect, useState} from 'react';
import useRequest from '../../../../hooks/useRequest';
import styles from './ESIMMyNumbers.module.scss';
import {esimLocations} from '../ESIMBuy/ESIMBuy';
import {getFlagEmoji} from '../../../../utils/getFlagEmoji';
import MenuButton
  from '../../../../components/Navigation/Header/components/TopBarInfo/components/MenuButton/MenuButton';
import moment from 'moment';
import {Box} from '@mui/material';
import {DeleteIcon, EsimIcon} from '../../../../assets';
import ESIMDeleteModal from './components/ESIMDeleteModal';
import EventEmitter from '../../../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from '../../../../components/Modals/const';
import ESIMProfile from './components/ESIMProfile';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import ESIMTable from './components/ESIMTable';

const ESIMMyNumbers = () => {
  const [countries, setCountries] = useState();
  const [esims, setEsims] = useState();
  const {getDataWithCallback, postDataWithCallback} = useRequest();
  const [selectedEsim, setSelectedEsim] = useState();
  const [esimToDelete, setEsimToDelete] = useState();
  const {isMobile} = useBreakpoints();

  const fetchEsim = useCallback(() => {
    getDataWithCallback('/api/esim/all', {}, data => {
      setEsims(data);
    });
  }, [getDataWithCallback]);

  useEffect(() => {
    fetchEsim();
    getDataWithCallback('/api/esim/countries', {}, data => {
      setCountries(data);
    });
  }, [fetchEsim, getDataWithCallback])

  const onDelete = () => {
    if (esimToDelete) {
      postDataWithCallback('/api/esim/del', {orderID: esimToDelete.orderID}, () => {
        setEsimToDelete();
        fetchEsim();
        EventEmitter.emit(
          MODAL_EVENTS.openModal,
          MODAL_TYPES.deleteESIMNotification
        );
      })
    }
  }

  const renderEsim = (esim) => {
    const menuItems = [
      {
        title: 'eSIM profile',
        onClick: () => setSelectedEsim(esim),
        icon: <EsimIcon/>,
      },
      {
        title: 'Delete',
        onClick: () => setEsimToDelete(esim),
        icon: <DeleteIcon/>,
      },
    ];

    return <div className={styles.esim}>
      <div className={styles.esimHeader}>
        <div className={styles.esimTitle}>{esim.region ? esimLocations.find(loc => loc.code === esim.region).name : (
          <>{getFlagEmoji(esim.code)}&nbsp;{countries?.find(c => c.countryCode === esim.code).countryName}</>
        )}</div>

        <MenuButton width={167} items={menuItems} size={24} iconSize={14} borderRadius={5}/>
      </div>

      <div className={styles.esimPlan}>{esim.capacity} GB for {esim.period} days <span>|</span> ${esim.price}</div>

      <Box className={styles.esimUntil} mt={1}>
        <div>{moment(esim.purchasedAt * 1000).format('DD.MM.YYYY (HH:mm:ss)')}</div>
        <div>ID order&nbsp;<span>{esim.orderID}</span></div>
      </Box>
    </div>
  }

  if (selectedEsim) {
    return <ESIMProfile countries={countries} onClose={() => setSelectedEsim()} esim={selectedEsim}/>
  }

  return <div className={styles.container}>
    {esims && esims.length === 0 && (
      <div className={styles.text}>
        You have no eSIM
      </div>
    )}

    {isMobile && esims && esims.length > 0 && (
      <>
        <div className={styles.badge}>Country & Package</div>
        {esims?.map((esim) => renderEsim(esim))}
      </>
    )}

    {!isMobile && esims && esims.length > 0 && (
      <ESIMTable setEsimToDelete={setEsimToDelete} setSelectedEsim={setSelectedEsim} countries={countries}
                 esims={esims}/>
    )}

    {esimToDelete && <ESIMDeleteModal
      esim={esimToDelete}
      onClose={() => setEsimToDelete()}
      onDelete={onDelete}
      countries={countries}
    />}
  </div>
}
export default ESIMMyNumbers