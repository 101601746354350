import styles from './IconButton.module.scss'

export const IconButton = ({icon, text, onClick}) => {
  return (
    <button
      className={styles.button}
      onClick={onClick}
    >
      {icon}
      <span>{text}</span>
    </button>
  )
}