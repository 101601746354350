import styles from './CryptoSelect.module.scss';
import {Box, ClickAwayListener, Fade, Popper} from '@mui/material';
import {useRef, useState} from 'react';
import {ArrowDown, BTCIcon, USDCIcon} from '../../../../../../assets';

const CryptoSelect = ({value, onChange}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const onSelect = (type) => {
    onChange(type);
    setOpen(false);
  };

  const onOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      <div onClick={onOpen} ref={ref} className={styles.container}>
        <span className={styles.text}>Withdraw to</span>
        <div className={styles.select}>
          {value === 'btc' && <BTCIcon/>}
          {value === 'usdc' && <USDCIcon/>}
          <span className={styles.currency}>{value.toUpperCase()}</span>
          <Box translate={`rotate(${open ? 180 : 0}deg)`}>
            <ArrowDown/>
          </Box>
        </div>
      </div>
      <Popper
        open={open}
        anchorEl={ref.current}
        placement={'bottom-start'}
        transition
        style={{zIndex: 9999}}
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              <ClickAwayListener
                onClickAway={() => (open ? setOpen(false) : undefined)}
              >
                <div>
                  {value === 'usdc' && (
                    <div
                      onClick={() => onSelect('btc')}
                      className={styles.currencyBadge}
                    >
                      <BTCIcon/>
                      <span>BTC</span>
                    </div>
                  )}
                  {value === 'btc' && (
                    <div
                      onClick={() => onSelect('usdc')}
                      className={styles.currencyBadge}
                    >
                      <USDCIcon/>
                      <span>USDC</span>
                    </div>
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default CryptoSelect;
