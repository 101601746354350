import {delay} from 'utils/delay';
import EventEmitter from 'utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from 'components/Modals/const';

class WebsocketsStore {
  ws;
  startConnect = false;
  authorized = false;
  accessToken;

  get state() {
    if (this.ws === undefined) return 'CLOSED';
    if (this.ws?.readyState === WebSocket.OPEN) return 'OPEN';
    if (this.ws?.readyState === WebSocket.CONNECTING) return 'CONNECTING';
    if (this.ws?.readyState === WebSocket.CLOSED) return 'CLOSED';
    if (this.ws?.readyState === WebSocket.CLOSING) return 'CLOSING';
    return 'CLOSED';
  }

  onAppStateChange = (authorized, accessToken) => {
    console.log('WebsocketsStore', 'onAppStateChange', authorized);
    this.authorized = authorized;
    this.accessToken = accessToken;
    if (authorized) {
      this.connectToWebsockets();
    }
  };

  close = () => {
    console.log('[WebsocketsStore.close]');
    this.ws.close();
    if (this.ws) {
      this.ws.onclose = null;
      this.ws.onopen = null;
      this.ws.onerror = null;
      this.ws.onmessage = null;
    }
    this.ws = undefined;
  };

  onOpen = () => {
    this.startConnect = false;
    console.log('WebsocketsStore', 'onOpen');
  };

  onError = event => {
    this.startConnect = false;
    console.log('[WebsocketsStore.onError]', event);
  };

  onClose = async () => {
    this.startConnect = false;
    console.log('WebsocketsStore', 'onClose');
    await delay(1000);
    console.log('[WebsocketsStore.onClose]', 'tryReconnect');
    this.connectToWebsockets();
  };

  onMessage = message => {
    const data = JSON.parse(message.data);
    console.log('WebsocketsStore.onMessage', data);
    switch (data.type) {
      case 'call':
        EventEmitter.emit(MODAL_EVENTS.openModal, MODAL_TYPES.onDirectCall, data);
        break;
      case 'call_end':
        EventEmitter.emit(MODAL_TYPES.onDirectCallEnd, data);
        break;
      case 'sms':
        EventEmitter.emit('incoming_sms', data);
        break;
      default:
    }
  };

  connectToWebsockets = () => {
    console.log(
      '[WebsocketsStore.connectToWebsockets]',
      'isLoggedIn',
      this.authorized,
      'STATE',
      this.state
    );

    // proxy doesn't work for ws
    let url = 'wss://test.dcalls.org';
    if (process.env.NODE_ENV !== 'development') {
      url = `wss://${window.location.host}`;
    }

    if (!this.authorized) return;
    if (this.state === 'CONNECTING' || this.state === 'OPEN') return;
    if (this.startConnect) return;
    this.startConnect = true;
    this.ws = new WebSocket(`${url}/ws?access_token=${this.accessToken}`);
    this.ws.onopen = this.onOpen;
    this.ws.onclose = this.onClose;
    this.ws.onerror = this.onError;
    this.ws.onmessage = this.onMessage;
  };
}

export const websockets = new WebsocketsStore();
