import styles from './ESIMCountrySelect.module.scss'
import {ReactComponent as SearchIcon} from '../icons/search.svg';
import {Input} from '../../../../../components/Input/Input';
import useRequest from '../../../../../hooks/useRequest';
import {useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {getFlagEmoji} from '../../../../../utils/getFlagEmoji';
import {Box} from '@mui/material';
import useBreakpoints from '../../../../../hooks/useBreakpoints';

const ESIMCountrySelect = ({onCountrySelect, reset, selectedCountry}) => {
  const {getDataWithCallback} = useRequest();
  const [countries, setCountries] = useState();
  const [value, setValue] = useState('');
  const countriesRef = useRef();
  const {isMobile} = useBreakpoints();

  useEffect(() => {
    getDataWithCallback('/api/esim/countries', {}, data => {
      setCountries(data);
    });
  }, [getDataWithCallback])

  useEffect(() => {
    reset();
  }, [reset, value])

  const countriesToRender = useMemo(() => {
    if (!countries) return [];
    if (!value) return countries

    return [...countries].filter(country => country.countryName.toLowerCase().includes(value.toLowerCase())
      || country.countryCode.toLowerCase().includes(value.toLowerCase()))
  }, [countries, value])

  const countriesEl = useMemo(() => (countriesToRender?.sort((a, b) => ('' + a.countryName).localeCompare(b.countryName)).map((country) => {
    return <div
      className={classNames(styles.country, selectedCountry?.countryCode === country.countryCode ? styles.countryActive : '')}
      onClick={() => onCountrySelect(country)} key={country.countryCode}>
      <span>
        {getFlagEmoji(country.countryCode)}&nbsp;&nbsp;
        {country.countryName}
      </span>
    </div>
  })), [countriesToRender, onCountrySelect, selectedCountry?.countryCode])

  return <div className={styles.container}>
    <div className={styles.title}>Select country</div>

    <div className={styles.searchContainer}>
      <div className={styles.searchIcon}><SearchIcon/></div>
      <Input
        value={value}
        onChange={(val) => {
          setValue(val);
          countriesRef.current.scrollTop = 0;
        }}
        placeholder={'Search by country name'}
      />
    </div>

    <div ref={countriesRef} className={styles.countryContainer}>
      {isMobile ? countriesEl : <>
        <Box display={'grid'} gap={2} rowGap={'12px'} gridTemplateColumns={'1fr 1fr 1fr'}>{countriesEl}</Box>
      </>}
    </div>
  </div>
}

export default ESIMCountrySelect