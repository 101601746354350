import {CheckedIcon, CloseIcon, UnCheckedIcon} from '../../../../assets';
import commonStyles from '../../ModalsRoot.module.scss';
import {Button} from '../../../Buttons';
import {useEffect, useState} from 'react';
import useRequest from '../../../../hooks/useRequest';
import EventEmitter from '../../../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from '../../const';
import {Input} from '../../../Input/Input';
import styles from './WithdrawToCrypto.module.scss';
import CryptoSelect from './components/CryptoSelect/CryptoSelect';
import {Box, Checkbox} from '@mui/material';
import InputWithFrame from '../../../InputWithFrame/InputWithFrame';
import classNames from 'classnames';
import useBreakpoints from '../../../../hooks/useBreakpoints';

const WithdrawToCrypto = ({onClose}) => {
  const {isMobile} = useBreakpoints();
  const [value, setValue] = useState('0');
  const [address, setAddress] = useState('');
  const [type, setType] = useState('btc');
  const {postDataWithCallback} = useRequest();
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState({});
  const {getDataWithCallback} = useRequest();

  useEffect(() => {
    getDataWithCallback('/api/user/earn', undefined, (data) => {
      setData(data);
    });
  }, [getDataWithCallback]);

  const valueOnChange = (value) => {
    setValue(
      value !== undefined ? value.replace(/^0+/, '').replace(/[^\d.]/g, '') : ''
    );
  };

  const currencyRegExp = new RegExp('^\\d+([.]\\d{1,2})?$');

  const onPayClick = async () => {
    postDataWithCallback(
      '/api/user/earn/wallet',
      {cents: parseFloat(value * 100), type, wallet: address},
      (data) => {
        EventEmitter.emit(
          MODAL_EVENTS.openModal,
          MODAL_TYPES.withdrawCongratulations
        );
      }
    );
  };

  const onAllClick = () => {
    if (data.availableCents !== undefined) {
      setValue((data.availableCents / 100).toFixed(2));
    }
  };

  return (
    <div className={commonStyles.defaultModal}>
      <div onClick={onClose} className={commonStyles.closeButtonContainer}>
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <h4 className={commonStyles.heading}>Withdrawal of Reward</h4>

      <Box display={'flex'} alignItems={'center'}>
        <div className={styles.inputContainer}>
          <Box pl={2} py={1.5} width="50%" boxSizing="border-box">
            <Input
              value={value}
              onChange={valueOnChange}
              prefix="$"
              style={{textAlign: 'left'}}
              placeholder={'$0'}
              type="tel"
              pattern="[0-9]*"
              noValidate
            />
          </Box>
          {isMobile ? (
            <Box width="50%">
              <CryptoSelect value={type} onChange={setType}/>
            </Box>
          ) : (
            <div onClick={onAllClick}>All</div>
          )}
        </div>
        {!isMobile && (
          <Box ml={3} width="138px">
            <CryptoSelect value={type} onChange={setType}/>
          </Box>
        )}
      </Box>

      <Box mb={2}>
        <InputWithFrame
          value={address}
          onChange={setAddress}
          placeholder={
            type === 'btc'
              ? isMobile
                ? 'Enter Bitcoin address'
                : 'Enter Bitcoin (BTC) address'
              : isMobile
                ? 'Enter USDC address'
                : 'Enter USDC (ERC-20) address'
          }
          style={{textAlign: 'left'}}
          textarea
        />
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <Checkbox
          icon={
            <UnCheckedIcon
              style={isMobile ? {} : {width: '24px', height: '24px'}}
            />
          }
          checkedIcon={
            <CheckedIcon
              style={isMobile ? {} : {width: '24px', height: '24px'}}
            />
          }
          checked={checked}
          onChange={(e) => setChecked((prev) => !prev)}
        />
        <span
          className={classNames(
            styles.smallText,
            checked && styles.smallTextActive
          )}
        >
          Wallet address is correct
        </span>
      </Box>

      <Button
        onClick={onPayClick}
        disabled={
          !currencyRegExp.test(value || '') ||
          value === '0' ||
          !address ||
          !checked ||
          parseFloat(value) < 50 ||
          parseFloat(value) > data.availableCents / 100
        }
        style={{width: '100%'}}
      >
        Send to Crypto
      </Button>
    </div>
  );
};

export default WithdrawToCrypto;
