import {useCallback, useEffect, useRef, useState} from 'react';
import styles from './DIDNumbersRowDescription.module.scss';
import useRequest from '../../../../../../../../../hooks/useRequest';

const DIDNumbersRowDescription = ({number, description, fetchNumbers, open, setOpen}) => {
  const {postDataWithCallback} = useRequest();
  const [value, setValue] = useState(description || '');
  const inputRef = useRef();

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    postDataWithCallback('/api/did/edit/alias', {'number': number.number, 'alias': value}, async () => {
      await fetchNumbers()
      setOpen(false);
    })

  }, [fetchNumbers, number.number, postDataWithCallback, setOpen, value]);

  useEffect(() => {
    if (open) {
      inputRef.current.focus();
    }
  }, [open]);

  const onChange = useCallback((event) => {
    if (event.target.value?.length <= 20) {
      setValue(event.target.value)
    }
  }, [])

  if (open) {
    return <form className={styles.form} onSubmit={onSubmit}>
      <input
        ref={inputRef}
        value={value}
        onChange={onChange}
        onBlur={(e) => onSubmit(e)}
      />
    </form>
  }

  if (!description) {
    return <div onClick={() => setOpen(true)} className={styles.addButton}>+ Add</div>;
  }

  return <div onClick={() => setOpen(true)} className={styles.description}>{description}</div>
}

export default DIDNumbersRowDescription;