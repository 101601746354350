import styles from './Rules.module.scss';
import {Box} from '@mui/material';
import {InviteesIcon} from '../../../../assets';
import classNames from 'classnames';
import useBreakpoints from '../../../../hooks/useBreakpoints';

const Rules = () => {
  const {isMobile} = useBreakpoints();

  return (
    <Box px={isMobile ? 2.5 : 5} className={styles.container}>
      <Box mb={2} textAlign="center">
        <span className={styles.title}>
          We value the contribution of each user{isMobile ? ' ' : <br/>}and are
          ready to reward him for active actions.
        </span>
      </Box>

      <Box mb={2} textAlign="center">
        <span className={styles.text}>
          The two-level system is built in such a way that rewards are accrued
          from the 1st level -{isMobile ? ' ' : <br/>}personal invitations and
          from the second level - invitations from those invited by you.
        </span>
      </Box>

      <Box mb={isMobile ? 4 : 5} textAlign="center">
        <span className={styles.smallText}>
          The ability to receive passive income from your referrals on an
          ongoing basis.
        </span>
      </Box>

      <Box mb={isMobile ? 2 : 3} textAlign="center">
        <span className={styles.title}>How much can you earn?</span>
      </Box>

      <Box mb={4} display="flex" gap="16px" justifyContent={'center'}>
        <Box
          width="100%"
          p={isMobile ? 1.5 : 3}
          display="flex"
          border="1px solid #313942"
          borderRadius={isMobile ? '10px' : '20px'}
          maxWidth={'286px'}
        >
          <InviteesIcon
            style={
              isMobile
                ? {}
                : {
                  width: '84px',
                  height: '84px',
                }
            }
          />
          <Box
            ml={isMobile ? 1.5 : 3}
            display="flex"
            flexDirection="column"
            justifyContent={'center'}
          >
            <span className={styles.title}>10%</span>
            <span className={classNames(styles.smallText, styles.yellow)}>
              of 1st
              {isMobile ? <br/> : ' '}
              level invitees
            </span>
          </Box>
        </Box>

        <Box
          width="100%"
          p={isMobile ? 1.5 : 3}
          display="flex"
          border="1px solid #313942"
          borderRadius={isMobile ? '10px' : '20px'}
          maxWidth={'286px'}
        >
          <InviteesIcon
            style={
              isMobile
                ? {}
                : {
                  width: '84px',
                  height: '84px',
                }
            }
          />
          <Box
            ml={isMobile ? 1.5 : 3}
            display="flex"
            flexDirection="column"
            justifyContent={'center'}
          >
            <span className={styles.title}>5%</span>
            <span className={classNames(styles.smallText, styles.yellow)}>
              of 2nd
              {isMobile ? <br/> : ' '}
              level invitees
            </span>
          </Box>
        </Box>
      </Box>

      <Box
        maxWidth={'588px'}
        mx={'auto'}
        display="flex"
        px={isMobile ? 3 : 5}
        py={isMobile ? 2 : 3}
        border="1px solid #313942"
        borderRadius="10px"
      >
        <span className={styles.text} style={{fontWeight: 600}}>
          Withdrawal
          <br/>
          conditions
        </span>

        <Box ml={isMobile ? 3 : 5} flex={1}>
          <Box mb={1}>
            <span className={classNames(styles.smallText)}>
              Min. withdrawal amount:
              {!isMobile && (
                <span
                  style={{display: 'inline-block'}}
                  className={classNames(styles.smallText, styles.white)}
                >
                  &nbsp;$2 (to Balance) / $50 (to Crypto)
                </span>
              )}
            </span>
            {isMobile && (
              <span className={classNames(styles.smallText, styles.white)}>
                $2 (to Balance) / $50 (to Crypto)
              </span>
            )}
          </Box>

          <Box whiteSpace="nowrap" mb={1}>
            <span className={classNames(styles.smallText)}>
              Withdrawal methods:
              {!isMobile && (
                <span
                  style={{display: 'inline-block'}}
                  className={classNames(styles.smallText, styles.white)}
                >
                  &nbsp;BTC, USDC, Balance
                </span>
              )}
            </span>
            {isMobile && (
              <span className={classNames(styles.smallText, styles.white)}>
                &nbsp;BTC, USDC, Balance
              </span>
            )}
          </Box>

          <Box>
            <span className={classNames(styles.smallText)}>
              Network fee for withdrawal
              {isMobile ? <br/> : ' '}
              is paid by the user
            </span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Rules;
