import {Route, Routes, useLocation} from 'react-router-dom';
import Header from './components/Navigation/Header/Header';
import styles from './App.module.scss';
import React, {useCallback, useEffect, useRef} from 'react';
import {appStore, silentSound} from './stores/AppStore';
import {loadCurrentUser} from './lib/auth';
import ModalsRoot from './components/Modals/ModalsRoot';
import ChangePlan from './pages/ChangePlan/ChangePlan';
import CallHistory from './pages/CallHistory/CallHistory';
import TransactionHistory from './pages/TransactionHistory/TransactionHistory';
import Earn from './pages/Earn/Earn';
import Disconnect from './pages/Disconnect/Disconnect';
import EventEmitter from './utils/events/EventEmitter';
import {getTokenValue} from './utils/LocalStorage';
import {observer} from 'mobx-react';
import {PhoneCallNavigation} from './pages/PhoneCall/PhoneCallNavigation';
import {TopUpBalance} from './pages/TopUpBalance/TopUpBalance';
import MainPage from './pages/MainPage/MainPage';
import DIDNavigation from './pages/DID/DIDNavigation';
import ESIMNavigation from './pages/ESIM/ESIMNavigation';
import IncomingSMS from 'components/IncomingSMS/IncomingSMS';
import {EthereumClient, w3mConnectors, w3mProvider} from '@web3modal/ethereum';
import {configureChains, createConfig, WagmiConfig} from 'wagmi';
import {mainnet} from 'wagmi/chains';
import {Web3Modal} from '@web3modal/react';
import {Auth} from './components/Auth/Auth';

const chains = [mainnet];
const projectId = '0589154c204884a6b2a4bfb1c5fbdb42';

const {publicClient} = configureChains(chains, [w3mProvider({projectId})]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({projectId, chains}),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

function App() {
  const {pathname} = useLocation();
  const intervalRef = useRef();

  const stopUserPolling = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, []);

  const startUserPolling = useCallback(() => {
    stopUserPolling();
    intervalRef.current = setInterval(
      () => loadCurrentUser(getTokenValue()),
      10000
    );
  }, [stopUserPolling]);

  useEffect(() => {
    const clickCallback = () => {
      appStore.soundEffect.play();
      if (appStore.soundEffect.src === silentSound) {
        setTimeout(appStore.soundEffect.pause(), 1);
      }
    };
    // this plays silent sound effect on any click, for incoming call src replaced with actual call sound
    document.addEventListener('click', clickCallback);

    // user polling stops when balance changes in app store
    EventEmitter.on('start_user_polling', startUserPolling);
    EventEmitter.on('stop_user_polling', stopUserPolling);

    return () => {
      document.removeEventListener('click', clickCallback);
      EventEmitter.off('start_user_polling', startUserPolling);
      EventEmitter.off('stop_user_polling', stopUserPolling);
    };
  }, [startUserPolling, stopUserPolling]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <Auth/>

        <div className={styles.App}>

          <Header/>

          <div className={styles.content}>
            <Routes>
              <Route
                path="/"
                exact
                element={<MainPage/>}
              />
              <Route
                path="/invite/"
                element={<MainPage/>}
              />
              <Route
                path="/invite/:inviteID"
                element={<MainPage/>}
              />

              <Route
                path={'/make-a-call'}
                element={<PhoneCallNavigation/>}
              />

              <Route
                path={'/change-plan'}
                element={<ChangePlan/>}
              />

              <Route
                path={'/call-history'}
                element={<CallHistory/>}
              />

              <Route
                path="/transactions"
                element={<TransactionHistory/>}
              />

              <Route
                path="/earn"
                element={<Earn/>}
              />

              <Route
                path="/disconnect"
                element={<Disconnect/>}
              />

              <Route
                path="/top-up-balance"
                element={<TopUpBalance/>}
              />

              <Route
                path="/did/*"
                element={<DIDNavigation/>}
              />

              <Route
                path="/esim"
                element={<ESIMNavigation/>}
              />
            </Routes>
          </div>

          <ModalsRoot/>

          <IncomingSMS/>
        </div>
      </WagmiConfig>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
      />
    </>
  );
}

export default observer(App);
