import styles from './ChangePlan.module.scss';
import useHeaderSettings from '../../hooks/useHeaderSettings';
import BasicPlan from './components/BasicPlan/BasicPlan';
import {appStore} from '../../stores/AppStore';
import {observer} from 'mobx-react';
import PremiumPlan from './components/PremiumPlan/PremiumPlan';
import {Box} from '@mui/material';
import EventEmitter from '../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from '../../components/Modals/const';
import useBreakpoints from '../../hooks/useBreakpoints';
import {Navigate} from 'react-router-dom';
import {useMemo} from 'react';
import BasicPlanMobile from './components/BasicPlanMobile/BasicPlanMobile';
import PremiumPlanMobile from './components/PremiumPlanMobile/PremiumPlanMobile';

const ChangePlan = () => {
  useHeaderSettings({title: 'Change plan'});
  const {isMobile} = useBreakpoints();

  const isPremium = appStore.currentUser?.vipType !== 'no';
  const isPremiumPromo = appStore.currentUser?.vipType === 'init';

  const text = useMemo(() => {
    if (isPremiumPromo) {
      if (isMobile) {
        return 'You have a Premium subscription for 8 hours, after the expiration of the subscription you will be automatically transferred to the Basic subscription.\n' +
          '\n' +
          'If you want to activate the Premium subscription, select the period and click the button, the funds will be debited from your account balance.';
      }

      return (
        'You have a Premium subscription for 8 hours, after the expiration of the subscription \n' +
        'you will be automatically transferred to the Basic subscription.\n' +
        '\n' +
        'If you want to activate the Premium subscription, select the period and click the button, \n' +
        'the funds will be debited from your account balance.'
      );
    }
    if (isPremium) {
      if (isMobile) {
        return 'If you want to switch to the Basic plan, then you need to turn off the auto-renewal of the Premium package and wait until it ends. The transition will be done automatically.';
      }
      return 'If you want to switch to the Basic plan, then you need to turn off the auto-renewal of the Premium package and wait until it ends.\n' +
        'The transition will be done automatically.';
    }
  }, [isMobile, isPremium, isPremiumPromo]);

  if (!appStore.currentUser) {
    return null;
  }

  const onBasicClick = () => {
    window.scrollTo(
      0,
      document.body.scrollHeight || document.documentElement.scrollHeight
    );
  };

  const onPremiumClick = (amount) => {
    EventEmitter.emit(
      MODAL_EVENTS.openModal,
      MODAL_TYPES.activationOfPremium,
      amount
    );
  };

  const plans = [
    isMobile ? <BasicPlanMobile
      onClick={isPremium && onBasicClick}
      active={!isPremium && !isPremiumPromo}
    /> : <BasicPlan
      onClick={isPremium && onBasicClick}
      active={!isPremium && !isPremiumPromo}
    />,
    isMobile ? <PremiumPlanMobile
      onClick={!isPremium && onPremiumClick}
      active={isPremium || isPremiumPromo}
      isPremiumPromo={isPremiumPromo}
    /> : <PremiumPlan
      onClick={!isPremium && onPremiumClick}
      active={isPremium || isPremiumPromo}
      isPremiumPromo={isPremiumPromo}
    />,
  ];

  if (appStore?.authorized === false) {
    return <Navigate to="/"/>;
  }

  return (
    <div className={styles.container}>
      {(isPremium || isPremiumPromo ? plans.reverse() : plans).map(
        (component, index) => {
          return (
            <Box
              key={index}
              mt={isMobile ? 2 : 5}
            >
              {component}
            </Box>
          );
        }
      )}

      {(isPremiumPromo || isPremium) && <span className={styles.text}>{text}</span>}
    </div>
  );
};

export default observer(ChangePlan);
