import styles from './Keypad.module.scss';
import {keypadButtons} from './keypadButtons';
import {useMemo} from 'react';
import KeypadButton from '../../../../components/Buttons/KeypadButton/KeypadButton';
import CallButton from '../../../../components/Buttons/CallButton/CallButton';
import MuteButton from '../../../../components/Buttons/MuteButton/MuteButton';
import BackspaceButton from '../../../../components/Buttons/BackspaceButton/BackspaceButton';
import PhoneInput from '../PhoneInput/PhoneInput';
import HangUpButton from '../../../../components/Buttons/HangUpButton/HangUpButton';
import {appStore} from '../../../../stores/AppStore';
import {CogIcon} from '../../../../assets';
import EventEmitter from '../../../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from '../../../../components/Modals/const';
import {observer} from 'mobx-react';
import VoiceSelect from '../../../../components/VoiceSelect/VoiceSelect';

const Keypad = (
  {
    disabled,
    phone,
    onPhoneChange,
    onCallClick,
    callDisabled,
    callStatus,
    rate,
    elapsed,
    handleDisconnect,
    toggleAudio,
    audioEnabled,
    onKeyboardButtonClick,
  }
) => {
  const buttonRows = useMemo(() => {
    const rows = [];
    const rowItemsCount = 3;
    keypadButtons.forEach((button, index) => {
      const i = Math.floor(index / rowItemsCount);
      if (!rows[i]) {
        rows[i] = [];
      }
      rows[i].push(button);
    });
    return rows;
  }, []);

  const onButtonClick = (symbol) => {
    onKeyboardButtonClick(symbol);
    if ((symbol === '#' || symbol === '*') && callStatus !== 'Confirmed') {
      return;
    }
    onPhoneChange(`${phone}${symbol}`);
  };

  const onCliClick = () => {
    EventEmitter.emit(MODAL_EVENTS.openModal, MODAL_TYPES.cliChange);
  };

  return (
    <div className={styles.container}>
      <div className={styles.cliContainer}>
        <span className={styles.cliHeading}>
          Caller ID:&nbsp;
          <span className={styles.cliNumber}>
            {appStore.currentUser?.cli ? appStore.currentUser?.cli : 'random'}
          </span>
        </span>
        <div
          onClick={onCliClick}
          className={styles.iconContainer}
        >
          <CogIcon/>
        </div>
      </div>

      <div className={styles.voiceContainer}>
        <span className={styles.cliHeading}>
          Voice:
          <span className={styles.cliNumber}>
            <VoiceSelect/>
          </span>
        </span>
      </div>

      <PhoneInput
        callStatus={callStatus}
        rate={rate}
        elapsed={elapsed}
        value={phone}
        disabled={disabled}
        onChange={onPhoneChange}
      />

      <div className={styles.buttonContainer}>
        {buttonRows.map((row, i) => {
          return (
            <div
              className={styles.buttonRow}
              key={i}
            >
              {row.map((button) => {
                return (
                  <KeypadButton
                    onClick={() => onButtonClick(button.title)}
                    key={button.title}
                    title={button.title}
                    subtitle={button.subtitle}
                    disabled={disabled}
                  />
                );
              })}
            </div>
          );
        })}

        <div className={styles.buttonRow}>
          <MuteButton
            muted={!audioEnabled}
            onClick={toggleAudio}
            disabled={disabled}
          />
          {callStatus ? (
            <HangUpButton
              onClick={handleDisconnect}
              disabled={callStatus === 'No answer'}
            />
          ) : (
            <CallButton
              onClick={onCallClick}
              disabled={disabled || callDisabled}
            />
          )}

          <BackspaceButton
            onClick={() => onPhoneChange(phone.slice(0, -1).trim())}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(Keypad);
