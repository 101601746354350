import {CloseIcon} from '../../../../assets';
import commonStyles from '../../ModalsRoot.module.scss';
import {Box} from '@mui/material';
import styles from './WithdrawToBalanceCongratulations.module.scss';
import classNames from 'classnames';

const WithdrawToBalanceCongratulations = ({onClose}) => {
  return (
    <div
      style={{paddingLeft: 24, paddingRight: 24}}
      className={commonStyles.defaultModal}
    >
      <div onClick={onClose} className={commonStyles.closeButtonContainer}>
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <h4 className={classNames(commonStyles.heading, styles.headingYellow)}>
        Congratulations!
      </h4>

      <Box maxWidth={'320px'} mt={1}>
        <span className={commonStyles.text}>
          Your request has been successfully processed. The balance has been
          replenished.
        </span>
      </Box>
    </div>
  );
};

export default WithdrawToBalanceCongratulations;
