import {useAccount, useDisconnect, useNetwork, useSignMessage} from 'wagmi';
import axios from 'axios';
import {web3Store} from '../../stores/Web3Store';
import {loadCurrentUser,} from '../../lib/auth';
import {getTokenValue} from '../../utils/LocalStorage';
import {appStore} from '../../stores/AppStore';
import {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import { SiweMessage } from "siwe";

export const Auth = () => {
  const navigate = useNavigate()
  const {disconnect} = useDisconnect();
  const message = useRef();
  const { chain } = useNetwork()


  const {signMessage} = useSignMessage({
    onSuccess: async (data) => {
      let req = {
        message: message.current,
        signature: data,
      }

      if (localStorage.getItem('inviteID')) {
        req.inviteID = localStorage.getItem('inviteID');
      }

      const response = await axios.post(`/api/user/verify`, req);
      appStore.setToken(response.data.token);
      await loadCurrentUser(response.data.token);
    },
    onError(error) {
      console.log('Error', error)
      disconnect()
    }
  });

  useAccount({
    async onConnect({address}) {
      web3Store.setAddress(address);

      const token = getTokenValue();
      if (token !== null) {
        appStore.setToken(token);
        await loadCurrentUser(token);
      } else {
        const { data: nonce } = await axios.get(`/api/user/nonce`);

        const siweMessage = new SiweMessage({
          domain: window.location.host,
          address: address,
          statement: "Sign in with Ethereum to the app.",
          uri: window.location.origin,
          version: "1",
          chainId: chain?.id,
          nonce,
        });
        message.current = siweMessage.prepareMessage();

        await signMessage({message: message.current});
      }
    },
    onDisconnect() {
      navigate('/');
      appStore.disconnect();
    }
  });

  useEffect(() => {
    const token = getTokenValue();

    if (!token) {
      appStore.setAuthorized(false);
    }
  }, []);

  return <></>;
};
