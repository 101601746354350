import styles from './EncryptedNumber.module.scss';

const EncryptedNumber = () => {
  return (
    <div className={styles.container}>
      <div
        className={styles.numberPlaceholder}
      />
    </div>
  );
};

export default EncryptedNumber;
