import styles from './ESIMCheckout.module.scss';
import {observer} from 'mobx-react';
import {HeaderBackIcon} from '../../../../../assets';
import {Box, ClickAwayListener, Fade, Popper, Typography} from '@mui/material';
import {theme} from '../../../../../theme';
import {Button} from '../../../../../components/Buttons';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {appStore} from '../../../../../stores/AppStore';
import {esimLocations} from '../ESIMBuy';
import useRequest from '../../../../../hooks/useRequest';
import EventEmitter from '../../../../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from '../../../../../components/Modals/const';
import useBreakpoints from '../../../../../hooks/useBreakpoints';
import ListOfCountriesModal from './ListOfCountriesModal/ListOfCountriesModal';
import BoxWithHeader from '../../../../../components/Misc/BoxWithHeader/BoxWithHeader';
import {loadCurrentUser} from '../../../../../lib/auth';
import {getTokenValue} from '../../../../../utils/LocalStorage';
import {getFlagEmoji} from '../../../../../utils/getFlagEmoji';

const ESIMCheckout = ({plan, location, selectedCountry, onBack, onCheckout}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [el, setEl] = useState();
  const [listOpen, setListOpen] = useState(false);
  const {postDataWithCallback} = useRequest();
  const {isMobile} = useBreakpoints();
  const [loading, setLoading] = useState(false);

  const buy = (event) => {
    if (appStore.currentUser.centsBalance < plan.price * 100 || loading) {
      setEl(event.target);
      setOpen(true);
      return
    }
    setLoading(true);
    const body = {
      slug: plan.slug,
    }

    if (location === 'country') {
      body.code = selectedCountry.countryCode;
    } else {
      body.region = location;
    }

    postDataWithCallback('/api/esim/buy', body, data => {
      EventEmitter.emit(
        MODAL_EVENTS.openModal,
        MODAL_TYPES.buyESIMCongratulations
      );
      onCheckout();
      void loadCurrentUser(getTokenValue());
      setLoading(false)
    })
  }

  const onTopUpClick = async () => {
    setOpen(false);
    navigate('/top-up-balance');
  };

  const infoLocation = selectedCountry ?
    <>{getFlagEmoji(selectedCountry.countryCode)}&nbsp;&nbsp;{selectedCountry.countryName}</> :
    esimLocations.find((loc) => (loc.code === location)).name;

  return <div className={styles.container}>
    <div onClick={onBack} className={styles.title}>
      <HeaderBackIcon/><Box>Your order:</Box>
    </div>

    {isMobile && (
      <div className={styles.infoContainer}>
        <Box display={'flex'} flexDirection={'column'}>
          <div className={styles.infoLocation}>{infoLocation}</div>
          <div className={styles.infoPlan}>Plan&nbsp;
            <div>{plan.capacity} GB for {plan.period} days</div>
          </div>
        </Box>

        <Box display={'flex'} flexDirection={'column'}>
          <div onClick={() => setListOpen(true)} className={styles.infoList}>List of countries</div>
          <div className={styles.infoPrice}>Price <div>{plan.price}$</div></div>
        </Box>
      </div>
    )}

    {!isMobile && (
      <BoxWithHeader headerText={
        <Box py={0.5} display={'flex'}>
          <Box className={styles.infoTitleDesktop} width={'400px'}>Location</Box>
          <Box mx={2} className={styles.infoTitleDesktop} width={'200px'}>Plan</Box>
          <Box className={styles.infoTitleDesktop} width={'fit-content'}>Price</Box>
        </Box>
      }>
        <Box display={'flex'}>
          <Box width={'400px'} display={'flex'} alignItems={'center'}>
            <div className={styles.infoLocation}>{infoLocation}</div>
            <div onClick={() => setListOpen(true)} className={styles.infoList}>List of countries</div>
          </Box>

          <Box mx={2} width={'200px'}>
            <div className={styles.infoPrice}>
              <div>{plan.capacity} GB</div>
              &nbsp;
              for {plan.period} days
            </div>
          </Box>

          <Box width={'fit-content'}>
            <div>{plan.price}$</div>
          </Box>
        </Box>
      </BoxWithHeader>
    )}


    <Button
      style={{width: 200, margin: isMobile ? '27px auto 24px auto' : '27px 0 24px 0', marginTop: '24px'}}
      onClick={buy}
      disabled={loading}
    >
      Pay
    </Button>

    <Typography variant={'subtitle2'} color={theme.palette.primary.dark} align={isMobile ? 'center' : 'left'}
                whiteSpace={'pre-wrap'}>
      {'By clicking on the "Pay" button, you agree that you' + (isMobile ? '\n' : ' ') + 'have read the Guide document.' + (!isMobile ? '\n' : ' ') + 'The eSIM is' + (isMobile ? '\n' : ' ') + 'non-refundable.'}
    </Typography>


    <Popper open={open} anchorEl={el} placement={'top-start'} transition>
      {({TransitionProps}) => (
        <Fade {...TransitionProps} timeout={350}>
          <div
            className={styles.notEnoughFundsContainer}
            onMouseLeave={() => {
              setOpen(false);
            }}
          >
            <ClickAwayListener
              onClickAway={() => (open ? setOpen(false) : undefined)}
            >
                <span>
                  {
                    'Oops, you don\'t have enough money on your balance to buy esim.'
                  }
                  <span onClick={onTopUpClick} className={styles.highlighted}>
                    Please, top-up balance.
                  </span>
                </span>
            </ClickAwayListener>
          </div>
        </Fade>
      )}
    </Popper>

    <ListOfCountriesModal setOpen={setListOpen} open={listOpen} plan={plan}/>
  </div>
}

export default observer(ESIMCheckout);