import styles from './BoxWithHeader.module.scss';
import classNames from 'classnames';

const BoxWithHeader = ({headerText, children, headerBackground = '', height}) => {
  return <div className={styles.container} style={{height: height || 'initial'}}>
    <div className={classNames(styles.header, styles[headerBackground])}>{headerText}</div>

    <div className={styles.content}>
      {children}
    </div>
  </div>
}

export default BoxWithHeader