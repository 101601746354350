import {useState} from 'react';
import {HeaderBackIcon} from '../../../assets';
import {Box} from '@mui/material';
import styles from './Accordeon.module.scss';

const Accordeon = ({title, defaultOpen = false, children, disabled}) => {
  const [open, setOpen] = useState(defaultOpen || disabled);
  return <div className={styles.container}>
    <div onClick={!disabled ? () => setOpen(prev => !prev) : undefined} className={styles.title}>
      {title}
      {!disabled && <Box
        style={{
          transform: 'rotate(' + (open ? 90 : 270) + 'deg)',
        }}
        className={styles.icon}
      >
        <HeaderBackIcon/>
      </Box>}
    </div>

    {open && (
      <div className={styles.content}>
        {children}
      </div>
    )}

  </div>
}

export default Accordeon