import styles from './VoiceSelect.module.scss';
import {Box, ClickAwayListener, Fade, Popper} from '@mui/material';
import {useRef, useState} from 'react';
import {ArrowDown} from '../../assets';
import {appStore} from '../../stores/AppStore';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import axios from 'axios';
import EventEmitter from '../../utils/events/EventEmitter';
import {MODAL_EVENTS, MODAL_TYPES} from '../Modals/const';
import {getTokenValue} from '../../utils/LocalStorage';

const VoiceSelect = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const {currentUser} = appStore;

  const onSelect = async (type) => {
    setOpen(false);
    const response = await axios.post(`/api/user/voice`, {voice: type}, {headers: {Authorization: `bearer ${getTokenValue()}`}});
    appStore.setCurrentUser({...appStore.currentUser, ...response.data});
  };

  const onOpen = (event) => {
    event?.stopPropagation();
    setOpen(true);
  };

  const voiceOptions = [

    {
      key: 'highest',
      value: 'Female 1',
    },
    {
      key: 'higher',
      value: 'Female 2',
    },
    {
      key: 'high',
      value: 'Female 3',
    },
    {
      key: 'low',
      value: 'Male 1',
    },
    {
      key: 'lower',
      value: 'Male 2',
    },
    {
      key: 'lowest',
      value: 'Male 3',
    },
    {
      key: '',
      value: 'Disabled',
    },
  ];

  const onClick = () => {
    if (currentUser?.vipType === 'no') {
      EventEmitter.emit(MODAL_EVENTS.openModal, MODAL_TYPES.voiceBasic);
    } else if (open) {
      setOpen(false);
    } else {
      onOpen();
    }
  };

  return (
    <>
      <div
        onClick={onClick}
        ref={ref}
        className={styles.container}
      >
        <div className={styles.select}>
          <span className={styles.currency}>{voiceOptions.find(opt => opt.key === currentUser?.voice)?.value}</span>
          <Box translate={`rotate(${open ? 180 : 0}deg)`}>
            <ArrowDown/>
          </Box>
        </div>
      </div>
      <Popper
        open={open}
        anchorEl={ref.current}
        placement={'bottom-start'}
        transition
        style={{zIndex: 9999}}
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className={styles.optionsContainer}>
              <ClickAwayListener
                onClickAway={() => (open ? setOpen(false) : undefined)}
              >
                <div>
                  {voiceOptions.map((option) => (
                    <div
                      onClick={() => onSelect(option.key)}
                      className={classNames(styles.currencyBadge, option.key === currentUser?.voice && styles.currencyBadge_selected)}
                    >
                      <span>{option.value}</span>
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default observer(VoiceSelect);
