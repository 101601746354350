import styles from './BasicPlanMobile.module.scss';
import classNames from 'classnames';
import {BasicPlanIcon} from '../../../../assets';

const BasicPlanMobile = ({active, onClick}) => {
  return (
    <div
      className={classNames(styles.container, active && styles.containerActive)}
      onClick={onClick || undefined}
    >
      <div className={styles.header}>
        <div className={styles.headerIcon}>
          <BasicPlanIcon/>
        </div>

        <div className={styles.priceContainerContent}>
          <span className={styles.headerTitle}>
            {active ? 'Current plan:' : 'Plan:'}
          </span>
          <span className={styles.headerPlan}>Basic</span>
        </div>

      </div>

      <div className={styles.priceContainer}>
        <div className={styles.priceContainerContent}>
          <span className={styles.priceContainerContentTitle}>Price</span>
          <span className={styles.priceContainerContentText}>$0/mo</span>
        </div>

        {active && (
          <div className={styles.priceContainerContent}>
              <span className={styles.priceContainerContentTitle}>
                Valid until:
              </span>
            <span className={styles.priceContainerContentText}>∞</span>
          </div>
        )}
      </div>

      <div className={classNames(styles.footer, active && styles.footerActive)}>
        <span>
          Billing:<strong>per second</strong>
        </span>
        <span>
          Discount on calls:<strong>0%</strong>
        </span>
        <span>
          Discount on rent DID:<strong>0%</strong>
        </span>
        <span>
          Changing the outgoing number:<strong>No</strong>
        </span>
        <span>
          Changing the voice:<strong>No</strong>
        </span>
      </div>
    </div>
  );
};

export default BasicPlanMobile;
