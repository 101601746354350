import {removeTokenValue, setTokenValue,} from '../utils/LocalStorage';
import axios from 'axios';
import {appStore} from '../stores/AppStore';

export const loadCurrentUser = async (token) => {
  if (!token) return;
  try {
    const response = await axios.get(`/api/user/current`, {
      headers: {Authorization: `bearer ${token}`},
    });
    appStore.setCurrentUser(response.data);
    appStore.setAuthorized(true);
    setTokenValue(token);
  } catch {
    removeTokenValue();
    appStore.setToken('');
  }
};
