import {Box} from '@mui/material';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import {DIDNumberMobileCard} from './components/DIDNumberMobileCard';
import styles from './DIDMyNumbers.module.scss'
import {useCallback, useEffect, useState} from 'react';
import DIDNumbersTable from './components/DIDNumbersTable/DIDNumbersTable';
import useRequest from '../../../../hooks/useRequest';
import {appStore} from '../../../../stores/AppStore';
import {observer} from 'mobx-react';

const DIDMyNumbers = () => {
  const {isMobile} = useBreakpoints();
  const [numbers, setNumbers] = useState();
  const {getDataWithCallback} = useRequest();
  const isPremium = appStore.currentUser?.vipType !== 'no';

  const fetchNumbers = useCallback(() => {
    return getDataWithCallback('/api/did/all', undefined, (data) => {
      setNumbers(data);
    });
  }, [getDataWithCallback]);

  useEffect(() => {
    void fetchNumbers();
  }, [fetchNumbers]);

  if (!numbers) return null;

  if (numbers?.length === 0) {
    return <Box
      pb={3}
      className={styles.text}
    >
      You have no virtual numbers
    </Box>
  }

  return (
    <Box
      pb={3}
      className={styles.container}
    >
      {isMobile ?
        (
          <>
            <div className={styles.mobileHeader}>
              DID number
            </div>
            {numbers.map(number => <DIDNumberMobileCard
              key={number.orderID}
              number={number}
              fetchNumbers={fetchNumbers}
              isPremium={isPremium}
            />)}
          </>
        ) : (
          <DIDNumbersTable
            isPremium={isPremium}
            numbers={numbers}
            fetchNumbers={fetchNumbers}
          />
        )
      }
    </Box>
  )
}

export default observer(DIDMyNumbers)