import {CloseIcon} from '../../../../assets';
import commonStyles from '../../ModalsRoot.module.scss';
import {Button} from '../../../Buttons';
import {Box} from '@mui/material';
import {useNavigate} from 'react-router-dom';

const TopUpBalance = ({onClose}) => {
  const {navigate} = useNavigate();

  const onTopUpClick = async () => {
    navigate('/top-up-balance');
  };

  return (
    <div
      style={{paddingLeft: 24, paddingRight: 24}}
      className={commonStyles.defaultModal}
    >
      <div onClick={onClose} className={commonStyles.closeButtonContainer}>
        <CloseIcon className={commonStyles.closeButton}/>
      </div>

      <h4 className={commonStyles.heading}>Attention</h4>

      <Box maxWidth={'320px'} mt={1} mb={2}>
        <span className={commonStyles.text}>
          There is not enough money
          <br/>
          for outgoing calls
        </span>
      </Box>

      <Button
        onClick={onTopUpClick}
        style={{paddingLeft: 27, paddingRight: 27, margin: '0 auto'}}
      >
        Top-up balance
      </Button>
    </div>
  );
};

export default TopUpBalance;
