import {DotsIcon} from '../../../../../../../assets';
import styles from './MenuButton.module.scss';
import {useEffect, useRef, useState} from 'react';
import {Fade, Popper} from '@mui/material';
import classNames from 'classnames';
import MenuItems from '../MenuItems/MenuItems';
import {useLocation} from 'react-router-dom';
import {observer} from 'mobx-react';
import {appStore} from '../../../../../../../stores/AppStore';

const MenuButton = ({items, size = 36, iconSize = 20, borderRadius = 10, width}) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef();
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <button
        ref={buttonRef}
        onClick={handleClick}
        className={classNames(styles.button, open ? styles.active : '')}
        style={{
          height: size + 'px',
          width: size + 'px',
          borderRadius: borderRadius + 'px'
        }}
      >
        {!items && (appStore.currentUser?.unreadEarn || appStore.currentUser?.unreadSms) && (
          <div className={styles.redDot}/>
        )}
        <DotsIcon style={{
          width: iconSize + 'px',
          height: iconSize + 'px',
        }}/>
      </button>

      <Popper
        open={open}
        anchorEl={buttonRef.current}
        placement={'bottom-end'}
        transition
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              <MenuItems width={width} items={items} onClose={() => setOpen(false)}/>
            </div>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default observer(MenuButton);
