import classNames from 'classnames';
import styles from './ESIMLocation.module.scss';

const ESIMLocation = ({location, active, onLocationSelect}) => {
  return (
    <div onClick={() => onLocationSelect(location.code)}
         className={classNames(styles.card, active ? styles.cardActive : '')}>
      {location.name}
    </div>
  )
}

export default ESIMLocation