import {TabNavigation} from '../../components/TabNavigation/TabNavigation';
import useHeaderSettings from '../../hooks/useHeaderSettings';
import React from 'react';
import {appStore} from '../../stores/AppStore';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import styles from './DIDNavigation.module.scss'
import {DIDDescription} from './Tabs/DIDDescription/DIDDescription';
import DIDMyNumbers from './Tabs/DIDMyNumbers/DIDMyNumbers';
import DIDAddNumber from './Tabs/DIDAddNumber/DIDAddNumber';
import DIDSMSLogs from './Tabs/DIDSMSLogs/DIDSMSLogs';
import DIDCallLogs from './Tabs/DIDCallLogs/DIDCallLogs';
import {observer} from 'mobx-react';

const DIDNavigation = () => {
  useHeaderSettings({title: 'DIDs'});
  const navigate = useNavigate();

  const tabs = [
    {
      name: 'Description',
      component: <DIDDescription/>,
      path: '',
    },
    {
      name: 'My numbers',
      component: <DIDMyNumbers/>,
      path: '/my-numbers',
    },
    {
      name: 'Add number',
      component: <DIDAddNumber/>,
      path: '/add-number',
    },
    {
      name: 'SMS logs',
      component: <DIDSMSLogs/>,
      path: '/sms-logs',
      notification: appStore.currentUser?.unreadSms
    },
    {
      name: 'Call logs',
      component: <DIDCallLogs/>,
      path: '/call-logs',
    },
  ]

  const currentTab = tabs.findIndex(tab => '/did' + tab.path === window.location.pathname)

  if (appStore?.authorized === false) {
    return <Navigate to="/"/>;
  }

  return (
    <div className={styles.container}>
      <TabNavigation
        tabIndex={currentTab}
        setTabIndex={(index) => {
          navigate('/did' + tabs[index].path, {replace: true});
        }}
        tabs={tabs}
      />

      <Routes>
        {tabs.map(tab => (
          <Route key={tab.path} path={tab.path} element={tab.component}/>
        ))}
      </Routes>
    </div>
  )
}

export default observer(DIDNavigation)